import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {decorateFromSettingsString, goBack, logActivity} from "../Trip/Utils";
import {serializeGroup} from "../Trip/Utils/expenseUtils";
import Alert from "@mui/material/Alert";
import CloseDialogButton from "./CloseDialogButton";

function DeleteExpenseDialog({trip, setGroup, user}) {

    const [onDeleting, setOnDeleting] = React.useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const expenseEntry = trip.expenseEntries.find(e => e.id === Number(params.entry));

    const closeDialog = ()=> {
        goBack(location, navigate, '/' + trip.id + '/expenses')
    }
    const renderImportant = (v)=> <span style={{fontWeight: "bold"}}>{v}</span>

    return (
        <Dialog
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Remove Entry?"}
                <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            <DialogContent>
                { expenseEntry && expenseEntry.type !== 4 ? <DialogContentText id="alert-dialog-description">
                    Following entry will be removed and split will be recalculated. However, this will not remove
                    any payments that are already done by participants. Those may need to be adjusted manually in
                    the ledger.
                </DialogContentText> : expenseEntry?.type === 4 ? <DialogContentText id="alert-dialog-description-removed">
                    Following recorded paid entry will be removed and split will be recalculated.
                    <br/><br/>
                    <span style={{color: "black"}}>{renderImportant("Note: ")}Please make sure to refund participants if they have already paid.</span>
                </DialogContentText>  : <DialogContentText id="alert-dialog-description-removed">
                    <Alert severity={"warning"}>Looks like someone else has already removed this entry.</Alert>
                </DialogContentText>

                }
            </DialogContent>
            <DialogActions>
                {!onDeleting && <Button color={"info"} onClick={closeDialog}>Cancel</Button>}
                <LoadingButton disabled={!expenseEntry} loading={onDeleting} color={"error"} onClick={async () => {
                    setOnDeleting(true);
                    try {
                        let clone = JSON.parse(JSON.stringify(trip));
                        clone.expenseEntries = clone.expenseEntries.filter(e => "" + e.id !== "" + params.entry);
                        let response = await API.graphql(graphqlOperation(updateTrip, {
                            input : serializeGroup(clone)
                        }));
                        let logPayload = {
                            description: expenseEntry.description , amount: expenseEntry.amount,  };
                        if(expenseEntry.type === 4){
                            logPayload.type = 4;
                            logPayload.payer = expenseEntry.payers.map(p => trip.participants[p]).join(" & ");
                            logPayload.payee = expenseEntry.items.map(item => trip.participants[item.assignees[0].name]).join(" & ");
                        }
                        logActivity("remove", {...trip, data : logPayload }, user);
                        decorateFromSettingsString(response.data.updateTrip);
                        setGroup(response.data.updateTrip);

                        setOnDeleting(false);
                        closeDialog();

                    } catch (e) {
                        console.log(e);
                        setOnDeleting(false);
                    }

                }} autoFocus>
                    Delete
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteExpenseDialog;