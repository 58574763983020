import * as React from "react";
import {FormControlLabel} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from '@mui/material/Divider';

export default function ActiveParticipantsGetter({ disableSelectAll, setActiveParticipants, participants, participantNames, initialParticipants, payers}) {
    const cp = {};
    participants.forEach(p =>cp[p] = false );
    const payerMap = {};
    if(payers){
        payers.map(p => payerMap[p] = true );
    }
    if(initialParticipants){
        initialParticipants.forEach(p =>cp[p] = true );
    }else {
        participants.forEach(p =>cp[p] = true );
    }
    const [checkedParticipants, setCheckedParticipants] = React.useState(cp);

    const getActiveParticipants = (cp)=> {
        let activeParticipants = [];
        for(let i = 0, l = participants.length; i < l; i++){
            let name = participants[i];
            if(cp[name]){
                activeParticipants.push(participants[i]);
            }
        }
        return activeParticipants;
    }
    return (
        <Container style={{padding: 0}}>
            { !disableSelectAll && <Box sx={{ marginLeft: 0, paddingLeft: 0, display: 'flex', flexDirection: 'column', ml: 3 }}>
             <FormControlLabel
            label="Participants"
            control={
                <Checkbox

                    checked={(()=>{
                        let allChecked = true;
                        for(let prop in checkedParticipants){
                            if(!checkedParticipants[prop]){
                                allChecked = false;
                            }
                        }
                        return allChecked
                    })()
                    }
                    indeterminate={

                        (()=>{
                            let hasChecked = false;
                            let hasUnchecked = false;
                            for(let prop in checkedParticipants){
                                if(!checkedParticipants[prop]){
                                    hasUnchecked = true;
                                }else {
                                    hasChecked = true;
                                }

                            }
                            return hasChecked && hasUnchecked
                        })()

                    }
                    onChange={

                        (event)=>{
                            let clone = Object.assign({}, checkedParticipants);
                            for(let prop in clone){
                                clone[prop] = event.target.checked
                            }
                            setCheckedParticipants(clone);
                            setActiveParticipants(getActiveParticipants(clone));
                        }

                    }
                />
            }
        />
        </Box> }
            { !disableSelectAll  && <Divider variant="middle" /> }
        <Box sx={{ marginLeft: 0, paddingLeft: 0, display: 'flex', flexDirection: 'column', ml: 3 }}>
            {participants.map((participant, index) =>
                <FormControlLabel  key={participant}
                                  label={payerMap[participant] ? participantNames[participant] + " (Payer)" : participantNames[participant]}
                                  value={checkedParticipants[participant]}
                                  control={<Checkbox  disabled={payerMap[participant]}  key={participant}
                                                      checked={checkedParticipants[participant]}
                                                      onChange={(event, checked)=>{
                                                          let clone = Object.assign({}, checkedParticipants);
                                                          clone[participant] = checked;
                                                          setCheckedParticipants(clone);
                                                          setActiveParticipants(getActiveParticipants(clone));
                                                      }} />}
                />)
            }

        </Box>
        </Container>
    )

}