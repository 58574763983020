import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {goBack} from "../Trip/Utils";
import Alert from "@mui/material/Alert";
import {renderCurrency} from "../Trip/Currencies";
import CloseDialogButton from "./CloseDialogButton";
import Divider from "@mui/material/Divider";
import {getParticipantPortionMap} from "../Trip/Utils/expenseUtils";


function ItemizedExpenseDetailsDialog({trip}) {
    const navigate = useNavigate();
    const location = useLocation();

    const params = useParams();
    let invalid = false;
    let id = Number(params.entry);
    const payer = Number(params.participant);
    const payee = Number(params.payee);

    const items = [];
    let amount = 0;
    let sharedAmount = 0;
    let payerOther = 0;
    let expenseEntry = null;
    let payeeIndex = -1;
    let payeesPortions = [];
    let payeesNeedBack = [];
    let payeeNeedsBack = 0;
    let allPayersNeedBack = 0;
    let alreadyPaid = 0;
    let payerOwes = 0;

    const closeDialog = () => {
        goBack(location, navigate, '/' + trip.id + '/expenses')
    }

    let ownageEntry = trip.ownage.entriesOwnageMap[id];

    if (ownageEntry && ownageEntry.expenseOwnageMatrix[payer][payee]) {
        expenseEntry = ownageEntry.expenseEntry;

        payeeIndex = expenseEntry.payers.indexOf(payee);
        let payerIndex = expenseEntry.payers.indexOf(payer);
        if(payerIndex >= 0){
            alreadyPaid += expenseEntry.payerAmounts[payerIndex];
        }


        let taxes = ((expenseEntry?.tax || 0) + (expenseEntry?.tip || 0) - (expenseEntry?.discount || 0));
        let itemsTotalCost = 0;
        for (const item of expenseEntry.items) {
            itemsTotalCost += item.price;
        }

        const participantPortionMap = getParticipantPortionMap(expenseEntry, trip);
        expenseEntry.items.forEach(item => {
            item.assignees.forEach(a => {
                    let itemAmount = (a.quantity / item.quantity) * item.price;
                    if (a.name === payer) {

                        items.push({
                            quantity: a.quantity,
                            name: item.name,
                            price: itemAmount
                        });
                        amount += itemAmount;
                        payerOther += (itemAmount / itemsTotalCost) * taxes;
                    } else if (a.name === trip.participants.length) {
                        sharedAmount += itemAmount / expenseEntry.participants.length;
                        payerOther += ((itemAmount / itemsTotalCost) * taxes) / expenseEntry.participants.length;
                    }
                }
            )
        })


        for(let i = 0, l = expenseEntry.payers.length; i<l; i++){
            payeesPortions[i] = ((participantPortionMap[expenseEntry.payers[i]] || 0) + (participantPortionMap[trip.participants.length] / expenseEntry.participants.length));
            payeesNeedBack[i] = expenseEntry.payerAmounts[i] - (payeesPortions[i] );
        }
        payeeNeedsBack = payeesNeedBack[payeeIndex];
        allPayersNeedBack = payeesNeedBack.reduce((a, b) => a + Math.max(b,0), 0);
        payerOwes = amount + sharedAmount + payerOther - alreadyPaid;
    } else {
        invalid = true;
    }
    const rv = (v) => {
        return renderCurrency(v, trip.currency, expenseEntry.rate, expenseEntry.currency)
    }

    const InfoRow = ({text, v}) => {
        return (<TableRow>
            <TableCell style={{paddingTop: 0, paddingBottom: 0}} align="left">
                <i><Typography variant={"caption"}>
                    {text}
                </Typography></i>
            </TableCell>
            <TableCell style={{paddingTop: 0, paddingBottom: 0}} align="right">
                <i><Typography
                    variant={"caption"}>{renderCurrency(v, trip.currency, expenseEntry.rate, expenseEntry.currency)}
                </Typography></i>
            </TableCell>
        </TableRow>)
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Expense Details"} <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            {!invalid ? <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <TableContainer>
                            <Table stickyHeader size="small" aria-label="a dense table">
                                { expenseEntry.type === 3 && <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Item Name</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                    </TableRow>
                                </TableHead> }
                                <TableBody>

                                    {
                                        items.map(item =>
                                            <TableRow
                                                style={{border: 0}}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >

                                                <TableCell align="left">
                                                    {item.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {renderCurrency(item.price, trip.currency, expenseEntry.rate, expenseEntry.currency)}
                                                </TableCell>


                                            </TableRow>
                                        )
                                    }
                                    {sharedAmount > 0 && <TableRow
                                        style={{border: 0}}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell align="left">
                                            <i>shared items</i>
                                        </TableCell>
                                        <TableCell align="right">
                                            {renderCurrency(sharedAmount, trip.currency, expenseEntry.rate, expenseEntry.currency)}
                                        </TableCell>
                                    </TableRow>}
                                    { expenseEntry.type == 3 && <TableRow
                                        style={{border: 0}}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell align="left">
                                            <i>tips/taxes/discounts</i>
                                        </TableCell>
                                        <TableCell align="right">
                                            {renderCurrency(payerOther, trip.currency, expenseEntry.rate, expenseEntry.currency)}
                                        </TableCell>
                                    </TableRow> }
                                    { alreadyPaid ? <InfoRow text={"Already Paid"} v={-alreadyPaid}/> : <></> }

                                    <TableRow
                                        style={{border: 0}}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell align="left">
                                            <Typography style={{color: "black"}}
                                                        variant={"body1"}>Total </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography style={{color: "black"}}
                                                        variant={"body1"}>
                                                {rv(payerOwes)}</Typography>
                                        </TableCell>
                                    </TableRow>

                                    {
                                        expenseEntry.payers.length > 1 && <>
                                            <Divider/>
                                            <InfoRow text={`${trip.participants[payee]} paid`}
                                                     v={expenseEntry.payerAmounts[payeeIndex]}/>
                                            <InfoRow text={`${trip.participants[payee]} portion was`} v={payeesPortions[payeeIndex] || 0}/>
                                            <InfoRow text={`${trip.participants[payee]} needs back`} v={payeeNeedsBack}/>
                                            <InfoRow text={`All payers need back`} v={allPayersNeedBack}/>
                                            <TableRow>
                                                <TableCell align={"left"} colSpan={2}>
                                                    <Typography
                                                        variant={"caption"}>{trip.participants[payer]} should
                                                        pay {trip.participants[payee]} ({payeeNeedsBack.toFixed(2)}÷{allPayersNeedBack.toFixed(2)})×{payerOwes.toFixed(2)} = {rv((payeeNeedsBack / (allPayersNeedBack)) * (payerOwes))}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                style={{border: 0}}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell align="left">
                                                    <Typography style={{color: "black"}}
                                                                variant={"body1"}>{trip.participants[payer]} owes {trip.participants[payee]} </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography style={{color: "black", fontWeight: "bold"}}
                                                                variant={"body1"}>
                                                        {rv((payeeNeedsBack / (allPayersNeedBack)) * (payerOwes))}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContentText>
                </DialogContent> :
                <DialogContent>
                    <Alert severity={"warning"}>Looks like someone has removed or updated this expense.</Alert>
                </DialogContent>
            }
        </Dialog>
    );
}

export default ItemizedExpenseDetailsDialog;