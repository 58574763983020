import React, {useState, useEffect} from 'react';
import SingleFieldUpdateDialog from "../SingleFieldUpdateDialog";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {decorateFromSettingsString, goBack, logActivity} from "../../Trip/Utils";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../../graphql/mutations";
import ConfirmationDialog from "../ConfirmationDialog";
import Alert from "@mui/material/Alert";
import {serializeGroup} from "../../Trip/Utils/expenseUtils";

function RemoveParticipantDialog({group, setGroup}) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const participant = Number(params.participant);

    const involvementMap = {};

    group.expenseEntries?.forEach(e => {
        e.participants.forEach(p => involvementMap[p] = true);
    });

    return (
        involvementMap[participant] ?
        <ConfirmationDialog title={"Remove Participant"}
                            content={<Alert severity={"warning"}>Cannot remove a participant that is involved in expenses.</Alert>}
                            confirmationText={"Okay"} open={true} setOpen={()=> {
            goBack(location, navigate, `/${group.id}/members`);
        } } />
        : !group.participants[participant] ?
                <ConfirmationDialog title={"Remove Participant"}
                                    content={<Alert severity={"success"}>This participant has been already removed.</Alert>}
                                    confirmationText={"Okay"} open={true} setOpen={()=> {
                    goBack(location, navigate, `/${group.id}/members`);
                } } /> :
        <SingleFieldUpdateDialog
            open={true}
            error={error}
            loading={loading}
            noField={true}
            onCancel={() => {
                goBack(location, navigate, `/${group.id}/members`);
            }}
            dialogTitle={"Remove Participant"}
            dialogDescription={"Are you sure you want to remove " + group.participants[participant] + " from the group?"}
            onUpdate={async ()=>{
                try {
                    setLoading(true);
                    let clone = JSON.parse(JSON.stringify(group));
                    let participantStr = group.participants[participant];

                    clone.expenseEntries.forEach(e => {
                        e.participants.forEach((p, i)=> {
                            if(p > participant) {
                                e.participants[i]--;
                            }
                        });
                        e.payers.forEach((p, i)=> {
                          if(p > participant) {
                              e.payers[i]--;
                          }
                        });
                        e.items.forEach( item => item.assignees.forEach((a, i) => {
                            if(a.name > participant) {
                                a.name--;
                            }
                        }));
                    })
                    clone.participants = clone.participants.filter((p, i) => i !== participant);


                    let response = await API.graphql(graphqlOperation(updateTrip, {
                        input : serializeGroup(clone)
                    }));
                    await logActivity("removeParticipant", { ...clone, data: { participant : participantStr } })
                    setLoading(false);
                    decorateFromSettingsString(response.data.updateTrip);
                    setGroup(response.data.updateTrip);
                    setError("");
                    goBack(location, navigate, `/${group.id}/members`);

                }catch(e){
                    setError("Unable to Remove Participant");
                } finally {

                }
            }}
            updateButtonText={"Remove"}

        />
    );
}

export default RemoveParticipantDialog;