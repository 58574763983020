import * as React from "react"
const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
        <path
            fill="#64dd17"
            d="M14 6h20a8 8 0 0 1 8 8v20a8 8 0 0 1-8 8H14a8 8 0 0 1-8-8V14a8 8 0 0 1 8-8z"
        />
        <path
            fill="#fafafa"
            d="M23.056 33.933c-.122 0-.245-.001-.37-.004-3.612-.088-5.98-2.312-6.781-3.198a.497.497 0 0 1 .011-.68l1.664-1.876a.495.495 0 0 1 .667-.05c.738.58 2.446 2.054 4.696 2.177 2.612.142 3.829-.601 3.986-1.736.149-1.075-.375-1.986-3.277-2.739-5.185-1.345-6.115-4.37-5.796-6.897.335-2.659 3.09-4.777 6.285-4.745 4.566.047 7.38 2.086 8.361 2.938.22.191.225.525.018.73l-1.581 1.786a.494.494 0 0 1-.617.068c-.799-.52-2.392-2.074-5.236-2.074-1.75 0-2.816.668-2.927 1.541-.154 1.22.661 2.274 3.155 2.837 5.527 1.247 6.457 4.467 5.87 7.068-.54 2.395-3.277 4.854-8.128 4.854z"
        />
        <path
            fill="#fafafa"
            d="m28.032 16.592.839-3.99a.5.5 0 0 0-.489-.602h-3.065a.5.5 0 0 0-.489.397l-.843 4.011 4.047.184zM20.916 31l-.925 4.397a.5.5 0 0 0 .49.603h3.065a.5.5 0 0 0 .489-.397L25.003 31h-4.087z"
        />
    </svg>
)
export default SvgComponent
