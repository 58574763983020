import * as React from "react"

const ItemizedExpenseSvg = ({darkMode}) =>{
    let color1 = darkMode ? '#fff' : '#B3B4B6';
    let color2 = darkMode ? '#fff' : '#327DD9';
    return (
        <svg
            width={42}
            height={41}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.182 6.027a.753.753 0 0 0 .15-.004 1.52 1.52 0 0 1 1.489 1.519.68.68 0 0 0 1.36 0 2.885 2.885 0 0 0-2.264-2.814v-.654a.68.68 0 0 0-1.36 0v.685a2.88 2.88 0 0 0 .744 5.664 1.52 1.52 0 1 1-1.52 1.52.68.68 0 1 0-1.361 0 2.886 2.886 0 0 0 2.137 2.783v.746a.68.68 0 0 0 1.36 0v-.715a2.88 2.88 0 0 0-.616-5.695 1.52 1.52 0 0 1-.12-3.035Z"
                fill={color2}
            />
            <path
                d="M32.34.066c-4.186 0-7.759 2.732-9.097 6.39H6.353c-.373 0-.677.337-.68.71l-.16 23.871H.697c-.375 0-.698.272-.698.649v3.029a5.345 5.345 0 0 0 5.103 5.323v.015h21.01v-.02c.17.007.24.02.348.02h.014a5.345 5.345 0 0 0 5.337-5.344V19.393c.17.01.35.014.526.014 5.327 0 9.662-4.343 9.662-9.67 0-5.328-4.333-9.67-9.66-9.67ZM5.362 38.692h-.004a4.003 4.003 0 0 1-3.997-3.984v-2.31h19.733v2.3a5.338 5.338 0 0 0 1.576 3.783c.071.071.16.126.235.211H5.362Zm25.089-3.984a3.984 3.984 0 0 1-3.976 3.984h-.013a4.013 4.013 0 0 1-4.007-3.993v-3.013a.628.628 0 0 0-.65-.649H6.874l.157-23.22h15.835c-.12.622-.183 1.255-.186 1.89A9.597 9.597 0 0 0 23.5 13.6h-6.427a.68.68 0 1 0 0 1.361h7.165a9.603 9.603 0 0 0 6.213 4.262v15.485Zm1.889-16.661a8.3 8.3 0 0 1-8.3-8.3 8.3 8.3 0 1 1 16.6 0 8.31 8.31 0 0 1-8.3 8.3Z"
                fill={color1}
            />
            <path
                d="M12.705 18.129c-1.201 0-2.178.946-2.178 2.109s.977 2.109 2.178 2.109 2.178-.946 2.178-2.11c0-1.162-.977-2.108-2.178-2.108Zm0 2.857c-.451 0-.817-.336-.817-.748 0-.413.366-.748.817-.748.45 0 .817.336.817.748s-.367.748-.817.748Z"
                fill={color2}
            />
            <path
                d="M16.392 20.235c0 .376.305.68.68.68h9.073a.68.68 0 0 0 0-1.36h-9.072a.68.68 0 0 0-.68.68Z"
                fill={color1}
            />
            <path
                d="M12.705 12.214c-1.201 0-2.178.946-2.178 2.109s.977 2.109 2.178 2.109 2.178-.946 2.178-2.109-.977-2.11-2.178-2.11Zm0 2.857c-.451 0-.817-.336-.817-.748s.366-.748.817-.748c.45 0 .817.335.817.748 0 .412-.367.748-.817.748ZM12.705 24.044c-1.201 0-2.178.945-2.178 2.108 0 1.163.977 2.11 2.178 2.11s2.178-.947 2.178-2.11c0-1.163-.977-2.108-2.178-2.108Zm0 2.856c-.451 0-.817-.335-.817-.748 0-.412.366-.748.817-.748.45 0 .817.336.817.748 0 .413-.367.748-.817.748Z"
                fill={color2}
            />
            <path
                d="M26.145 25.508h-9.072a.68.68 0 1 0 0 1.361h9.072a.68.68 0 0 0 0-1.36Z"
                fill={color1}
            />
        </svg>
    )
}

export default ItemizedExpenseSvg
