import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useLocation, useNavigate} from "react-router-dom";
import {decorateFromSettingsString, getBitmapFromArray, goBack, logActivity, updateSettings} from "../Trip/Utils";
import ActiveParticipantsGetter from "../CreateEntry/ActiveParticipantsGetter";
import Divider from "@mui/material/Divider";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Typography from "@mui/material/Typography";
import {serializeGroup} from "../Trip/Utils/expenseUtils";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import CloseDialogButton from "./CloseDialogButton";
import LockIcon from '@mui/icons-material/Lock';
import Alert from "@mui/material/Alert";

function LinkParticipantsDialog({ group , setGroup, user }) {
    const [activeParticipants, setActiveParticipants] = React.useState([]);
    const [onLoading, setOnLoading] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const closeDialog = ()=> {
        goBack(location, navigate, '/' + group.id + '/expenses')
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}

            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Link Participants <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            <DialogContent>
                {
                    group.linkedParticipants.filter(l => l.length > 1).map(lp =><>
                        <Grid container>
                            <Grid item xs={8}>
                                {
                                    lp.map(p=> <Typography key={"lp_el_" + p} variant={"subtitle2"}>{group.participants[p]}</Typography>)
                                }
                            </Grid>
                            { group.lpUsedMap[getBitmapFromArray(lp)] ?<Grid item xs={4}><LockIcon color={"disabled"}/></Grid>  : <Grid item xs={4}>
                                <Button onClick={async ()=> {
                                    setOnLoading(true);
                                    try {
                                            let index = group.linkedParticipants.map(p=> getBitmapFromArray(p)).indexOf(getBitmapFromArray(lp));
                                            let clone = JSON.parse(JSON.stringify(group));
                                            clone.linkedParticipants.splice(index,1);
                                            let response = await API.graphql(graphqlOperation(updateTrip, {
                                                input : serializeGroup(clone)
                                            }));
                                            logActivity("unlink", {...group, data : {
                                                participantString : lp.map(p=>group.participants[p]).join(" & ")  } }, user);
                                            decorateFromSettingsString(response.data.updateTrip);
                                            setGroup(response.data.updateTrip);

                                    } catch (e) {
                                        console.log(e);
                                    } finally {
                                        setOnLoading(false);
                                        closeDialog();
                                    }
                                } } startIcon={<LinkOffIcon/>} size={"medium"}>Unlink</Button>
                            </Grid> }

                        </Grid>

                        <Divider/></>
                    )
                }
                { Object.keys(group.lpUsedMap).length > 0 &&
                    <Alert severity={"warning"}>
                        Cannot unlink some of the participants while they have "paid" entries on the settlement screen.</Alert>
                }
                { group.linkedParticipants.filter(l=> l.length === 1 ).length > 0 &&
                    <Typography style={{marginTop: 10}} variant={"body2"}>Select Participants</Typography> }
                <ActiveParticipantsGetter
                    participants={group.linkedParticipants.filter(l=> l.length === 1 ).map(a => a[0]) }
                    participantNames={group.participants}
                    initialParticipants={[]}
                    setActiveParticipants={setActiveParticipants}
                    disableSelectAll={true} />
            </DialogContent>
            <DialogActions>
                <LoadingButton disabled={ activeParticipants?.length < 2 || activeParticipants?.length === group.participants.length } loading={onLoading} color={"success"} onClick={async () => {
                    setOnLoading(true);
                    try {
                        let clone = JSON.parse(JSON.stringify(group));

                        clone.linkedParticipants.push(activeParticipants);
                        let response = await API.graphql(graphqlOperation(updateTrip, {
                            input : serializeGroup(clone)
                        }));
                        logActivity("link", {...group, data : {

                            participantString : activeParticipants.map(p=>group.participants[p]).join(" & ")  } }, user);
                        decorateFromSettingsString(response.data.updateTrip);
                        setGroup(response.data.updateTrip);
                        closeDialog();

                    } catch (e) {
                        console.log(e);

                    } finally {
                        setOnLoading(false);
                    }

                }} autoFocus>
                    Link Participants
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default LinkParticipantsDialog;