import React from 'react';
import {Currencies} from "./Utils";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {getCurrencySymbol} from "./Currencies";
import {Countries} from "./Countries";

function CurrencySelector({short, country, setCountry, fullWidth}) {
    return (
        <TextField
            id="standard-select-country"
            InputProps={{
                disableUnderline: true
            }}
            variant={fullWidth ? "outlined" : "standard"}
            select
            fullWidth={fullWidth}
            value={country}
            onChange={(event)=>{
                setCountry(event.target.value);} }
            helperText={ short ? "" : "Please select your country"}

        >
            {Countries.map((option) => (
                <MenuItem key={'country_code_' + option.code} value={option.code}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default CurrencySelector;