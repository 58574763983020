import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {goBack, logActivity} from "../Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import CloseDialogButton from "./CloseDialogButton";
import Alert from "@mui/material/Alert";

function FeedbackDialog({group, user}) {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [feedback, setFeedback] = React.useState("");
    const [feedbackSent, setFeedbackSent] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const closeDialog = () => {
        goBack(location, navigate, group ? '/' + group.id + '/home' : '/');
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                Feedback<CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    { !feedbackSent && <FormControl margin="normal" variant="standard" fullWidth={true}>
                        <TextField
                            fullWidth={true}
                            variant="outlined"
                            type={"email"}
                            label="Email (optional)"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </FormControl> }
                    <FormControl margin="normal" variant="standard" fullWidth={true}>
                        {
                            feedbackSent  && <Alert severity={"success"}>Your feedback has been sent, thanks!</Alert>
                        }
                        { !feedbackSent && <TextField
                            value={feedback}
                            onChange={(event)=>{
                                setFeedback(event.target.value);} }
                            placeholder="Thank you for your feedback!"
                            multiline
                            rows={8}
                            maxRows={8}
                        /> }
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                { !feedbackSent && <Button onClick={closeDialog} color={"info"}> Cancel</Button> }
                <LoadingButton loading={loading} onClick={async () => {
                    setLoading(true);
                    if(feedbackSent) {
                        closeDialog();
                    } else {
                        try {
                            await logActivity("feedback", Object.assign({ data : feedback.substring(0, 1000) + (email ? ` email: ${email}` : '') }, group), user);
                            setFeedbackSent(true);
                        } catch (e) {
                            console.error(e);
                            setError(e);
                        } finally {
                            setLoading(false);
                        }
                    }

                }} color={"primary"}>{ feedbackSent ? "Close"  : "Send Feedback"  }</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default FeedbackDialog;