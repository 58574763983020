import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

function ConfirmationDialog({open, setOpen, content, title, confirmationText, noPadding}) {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            { !noPadding && <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle> }
            {
                noPadding ? content : <DialogContent>
                    {content}
                </DialogContent>
            }

            <DialogActions>
                <Button variant={"text"} color={"info"} onClick={()=> { setOpen(false)}}> { confirmationText || "Okay" }</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;