import React, {useEffect} from 'react';
import {Grid, Stack} from "@mui/material";
import Container from "@mui/material/Container";
import ExpenseCard from "./ExpenseCard";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {isMobile} from "../Trip/Utils";
import AddExpensePanel from "../AddExpensePanel";

function EntriesList({ trip, setReceiptImageData }) {
    const navigate = useNavigate();
    const params = useParams();

    let expenseEntries = trip?.expenseEntries || [];
    expenseEntries = expenseEntries.sort((a, b) => Number(b.id) - Number(a.id))

    return (

        <Container sx={{ paddingRight: 0, paddingLeft: 0, width: '100%' }}>

            <Box sx={{ paddingRight: 0, paddingLeft: 0, width: '100%' }}>
                { !isMobile() && <Grid container>
                    <Grid textAlign={"center"} item xs={12}>
                        <Typography variant={"overline"}>Expenses</Typography>
                    </Grid>

                </Grid> }
                <Stack
                    style={{ marginTop : '10px'}}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    {
                        trip && expenseEntries.length === 0 && <Typography variant={"body2"}>
                            Begin by adding your first expense.
                        </Typography>
                    }
                    {

                        trip && expenseEntries.map( (e, index) => <ExpenseCard ownage={trip.ownage.entriesOwnageMap[e.id]} trip={trip} key={"expense_" + e.id} expenseEntry={e} />
                        )
                    }
                </Stack>
            </Box>
            <Grid style={{ paddingTop: 10 }} textAlign={"center"}>

                {isMobile() && trip &&
                <AddExpensePanel addNewExpenseText={true} group={trip} setReceiptImageData={setReceiptImageData}/>

                }

                {isMobile() && trip && expenseEntries.length > 0 &&  <><Typography style={{paddingTop: 4, marginTop: 10, color: "#5A7184"}}>
                    Done with putting in all expenses? Go to </Typography>
                    <Typography style={{ marginTop: -3, paddingBottom: 50}} textAlign={"center"}>
                        <Button style={{ paddingBottom: 50 }} onClick={()=> {
                            navigate('/' + trip.id + '/results');
                        }}>Settlement Screen</Button>
                    </Typography>
               </> }



            </Grid>

        </Container>
    );
}

export default EntriesList;