/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPreSignedUrl = /* GraphQL */ `
  query GetPreSignedUrl($id: ID!) {
    getPreSignedUrl(id: $id)
  }
`;
export const getReceipt = /* GraphQL */ `
  query GetReceipt($id: ID!) {
    getReceipt(id: $id)
  }
`;
export const addSplitToArchive = /* GraphQL */ `
  query AddSplitToArchive($id: ID!, $name: String!, $body: String!) {
    addSplitToArchive(id: $id, name: $name, body: $body)
  }
`;
export const getArchivedSplits = /* GraphQL */ `
  query GetArchivedSplits($id: ID!) {
    getArchivedSplits(id: $id)
  }
`;
export const getCurrencyConversion = /* GraphQL */ `
  query GetCurrencyConversion($base: String!, $date: String) {
    getCurrencyConversion(base: $base, date: $date)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrip = /* GraphQL */ `
  query GetTrip($id: ID!) {
    getTrip(id: $id) {
      id
      currency
      name
      settings
      participants
      numberOfPastSplits
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTrips = /* GraphQL */ `
  query ListTrips(
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        currency
        name
        settings
        participants
        expenseEntries {
          id
          itemized
          description
          payer
          participants
          otherCharges
          amount
          currency
          date
          rate
          tax
          discount
          tip
          items {
            id
            quantity
            name
            price
            assignees {
              name
              quantity
            }
          }
        }
        paidEntries {
          id
          description
          payer
          payee
          amount
          comment
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
