import React from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {logActivity} from "./Trip/Utils";
import {StarOutlined} from "@mui/icons-material";
import {Grid} from "@mui/material";

function AppRater({user}) {
    return (
        <Grid item xs={12} textAlign={"center"}>
            <Typography style={{ marginBottom: 10}} variant={"subtitle2"}> We need your feedback! </Typography>
            <Button
                onClick={async () => {

                    if(window.cordova.platformId === 'android') {
                        window.cordova.InAppBrowser.open("market://details?id=io.cordova.splitpal", "_system");
                        logActivity("RateAndroidAppAttempted", null, user);
                    } else {
                        window.cordova.InAppBrowser.open("https://apps.apple.com/us/app/splitpal/id6443640890", "_system");
                        logActivity("RateIOSAppAttempted", null, user);
                    }
                }
                }

                startIcon={<StarOutlined/>} variant={"outlined"}>Rate SplitPal</Button>
        </Grid>
    );
}

export default AppRater;