import React, {useEffect, useState} from 'react';
import {
    alpha,
    Backdrop,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Slide,
    Snackbar,
    Toolbar,
    Zoom
} from "@mui/material";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from '@mui/material/Checkbox';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from "prop-types";
import ListItemIcon from "@mui/material/ListItemIcon";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ExpandMore, goBack, logActivity, shareSplitGroup} from "../Trip/Utils";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import Alert from "@mui/material/Alert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SphereSvg from "../SvgImages/SphereSvg";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {getCurrencyFraction, renderCurrency} from "../Trip/Currencies";
import {serializeGroup} from "../Trip/Utils/expenseUtils";
import {Share} from "@mui/icons-material";
import ExpenseStepper from "./AddExpenseDialog/ExpenseStepper";
import CloseDialogButton from "./CloseDialogButton";
import {useTheme} from "@mui/material/styles";

function Row({ item, onClick, selectedQuantity,itemizedEntry}) {
    let showArrow = !localStorage.getItem('completedAssignmentTutorial')
        && (itemizedEntry.items[0].id === item.id);

    return (
            <TableRow
                selected={selectedQuantity > 0}
                onClick={(e)=> {
                    if(showArrow){
                        showArrow = false;
                        localStorage.setItem('completedAssignmentTutorial', true);
                    }
                    onClick(e);
                }}
                style={{border: 0}}
                key={"top_itemrow" + item.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell align={"left"} style={{ borderBottom: 0, minWidth: 40, maxWidth: 40}} >
                    {selectedQuantity > 0 &&  ( selectedQuantity + "/") }
                    { item.quantity}
                </TableCell>
                <TableCell align={"left"} style={{borderBottom: 0}}>
                    { item.name}  {showArrow && selectedQuantity === 0 &&                     <Slide style={{marginRight: 10 }}  direction="left" in={true} mountOnEnter unmountOnExit>
                    <ArrowCircleLeftRoundedIcon color={"primary"} style={{marginBottom: -6}}/>

                </Slide>}

                </TableCell>
                <TableCell align={"right"}>
                    {renderCurrency(item.price, itemizedEntry.currency)}
                </TableCell>

            </TableRow>
    );
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } =
        props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>

                    <TableCell
                        key={'qty_header_cell'}
                        align={'right'}
                        padding={'none'}
                    >
                        Qty.
                    </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {


    const { trip, numSelected,itemizedEntry, setSelectedItems, selectedItems, closeDialog } = props;
    const [loading, setLoading] = useState(false);
    const [confirmUpdated, setConfirmUpdated] = useState(false);
    useEffect(() => {

        let quantityCapacityMap = {};
        itemizedEntry?.items.forEach(item=> {
            let totalAssignees = 0;
            item.assignees && item.assignees.forEach(a => {
                quantityCapacityMap[item.id + '/' + a.name] = a.quantity
                totalAssignees += a.quantity;
            })
            quantityCapacityMap[item.id] = item.quantity - totalAssignees;
        })
        let clone = Object.assign({}, selectedItems);
        for(let prop in clone){
            clone[prop] = Math.min(clone[prop],quantityCapacityMap[prop] || 0 );
            if(!clone[prop]){
                delete clone[prop];
            }
        }
        setSelectedItems(clone);

    }, [itemizedEntry]);

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {numSelected > 0 ? (
                <><Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="overline"
                    component="div"
                >
                    {numSelected} selected
                </Typography>

                    <Slide style={{marginRight: 10 }}  direction="right" in={numSelected > 0} mountOnEnter unmountOnExit>
                        <ArrowCircleRightRoundedIcon color={"primary"}/>

                    </Slide>
                </>
            ) : (
                <><Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Tap To Assign To Participant
                </Typography>
                    <CloseDialogButton onClick={closeDialog}/>
                </>
            )}

            {itemizedEntry && numSelected > 0 ? (
                        <Select
                            placeholder={"Assign To"}
                            sx={{width: '25ch'}}
                            variant={"standard"}
                            id={"itemAssignmentDialog-participant"}
                            displayEmpty={true}
                            value={null}
                            renderValue={value => value ? value : 'Assign To'}
                            onChange={async (e) => {

                                setLoading(true);

                                try {
                                        let assigneeMap = {};
                                        let itemMap = {};
                                        itemizedEntry.items.forEach( item => {
                                            itemMap[item.id] = item;
                                            item.assignees && item.assignees.forEach(a => {
                                                assigneeMap[item.id + '/' + a.name] = a
                                            })

                                        })
                                        let assignee = e.target.value;
                                        if(assignee === "unassign"){
                                            assignee = null;
                                        }

                                        for(let prop in selectedItems) {
                                            assigneeMap[prop] && (assigneeMap[prop].quantity -= selectedItems[prop]);
                                            if(assignee || assignee === 0){
                                                let id = prop.split('/')[0];
                                                let targetAssigneeId = id + '/' + assignee
                                                if (assigneeMap[targetAssigneeId]){
                                                    assigneeMap[targetAssigneeId].quantity += selectedItems[prop];
                                                }else {

                                                    itemMap[id].assignees = itemMap[id].assignees || [];
                                                    let newAssignee = { name: assignee, quantity: selectedItems[prop] };
                                                    assigneeMap[targetAssigneeId] = newAssignee;
                                                    itemMap[id].assignees.push(newAssignee);
                                                }
                                            }
                                        }
                                        itemizedEntry.items.forEach( item => {
                                            for(let i = item.assignees.length-1; i >= 0; i--){
                                                if(item.assignees[i].quantity === 0){
                                                    item.assignees.splice(i, 1);
                                                }
                                            }
                                        });

                                        let expenseEntries = trip.expenseEntries;
                                        let index = expenseEntries.findIndex(e => e.id === itemizedEntry.id);
                                        expenseEntries[index] = itemizedEntry;
                                        trip.expenseEntries = expenseEntries;
                                        await API.graphql(graphqlOperation(updateTrip, {
                                            input: serializeGroup(trip)
                                        }));

                                        setConfirmUpdated(true);

                                } catch (e) {
                                    console.log(e);
                                }
                                setLoading(false);
                                setSelectedItems({});
                            }}
                        >
                            <MenuItem key={"itemAssignmentDialogParticipant_shared"}
                                      value={trip.participants.length}>
                                <ListItemIcon>
                                    <GroupsIcon color={"primary"} fontSize={"small"}/>
                                </ListItemIcon>
                                {"Split Between All"}</MenuItem>
                            <Divider />
                            {
                                itemizedEntry.participants.map((p, index) => (
                                    <MenuItem key={"itemAssignmentDialogParticipant" + p}
                                              value={p}>
                                        {trip.participants[p]}</MenuItem>))
                            }
                            <Divider />
                            <MenuItem key={"itemAssignmentDialogParticipant_none"}
                                      value={"unassign"}>
                                <ListItemIcon>
                                    <PersonOffIcon/>
                                </ListItemIcon>
                                Unassign</MenuItem>
                        </Select>
            ) : (
                <></>
            )}
            <Snackbar
                open={confirmUpdated}
                autoHideDuration={1000}
                onClose={()=> {setConfirmUpdated(false)} }
                message="Assigned & Saved!"
            />
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    itemizedEntry: PropTypes.any,
    setSelectedItems: PropTypes.any,
    trip: PropTypes.any,
    closeDialog: PropTypes.any,
    selectedItems: PropTypes.any
};

function ParticipantItemsTable({ participant, itemizedEntry, selectedItems, setSelectedItems, trip}) {
    const [expanded, setExpanded] = useState(participant  === "Tap Item to Assign");
    const theme = useTheme();
    function renderItem(item, participant) {
        let unassignedQuantity = item.quantity;
        if(item.assignees){
            item.assignees.forEach(a => {
                unassignedQuantity -= a.quantity;
            })
        }
        const addToSelection = (id, quantity)=>{
            if(item.id === id && item.assignees){
                item.assignees.forEach(a=> quantity-= a.quantity);
            }
            let clone = Object.assign({}, selectedItems);
            if(clone[id]){
                clone[id]++;
                if(clone[id] > quantity){
                    delete clone[id];
                }
            } else {
                clone[id] = 1
            }

            setSelectedItems(clone);

        }
        return (<>
                { participant === "Tap Item to Assign" && unassignedQuantity > 0 && <Row


                    onClick={()=> { addToSelection(item.id, item.quantity)  }}
                                                                                       selectedQuantity={selectedItems[item.id] || 0}
                                                                                       item={{ id: item.id, price: (unassignedQuantity/item.quantity) * item.price, name: item.name, assignee : null, quantity: unassignedQuantity } } trip={trip} itemizedEntry={itemizedEntry}  ></Row> }
                { participant !== "Tap Item to Assign" && item.assignees && item.assignees.map(a => a.name === participant && <Row onClick={()=> { addToSelection(item.id + "/" + a.name, a.quantity)  }}
                                                                                                                                 selectedQuantity={selectedItems[item.id + "/" + a.name] || 0}
                                                                                                                                 item={{ id: item.id + "/" + a.name, price: (a.quantity/item.quantity)* item.price, name : item.name, assignee: a.name, quantity: a.quantity } }
                                                                                                                                 trip={trip}
                                                                                                                                 itemizedEntry={itemizedEntry}  ></Row>) }
            </>
        )
    }

    return (
        <Card  elevation={0} style={{ marginTop: 10, border: "1px solid #EAECEE"}}>
            <CardHeader
                onClick={()=>{setExpanded(!expanded)} }
                style={{padding: 8}}
                action={<IconButton>
                    <ExpandMore
                        expand={expanded}

                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </IconButton>}
                title={<><Button startIcon={participant === trip.participants.length ? <GroupsIcon/> :
                     participant === "Tap Item to Assign" ?
                         <SphereSvg fill="#D14343"/> :
                    <PersonIcon/>} color={"info"}>
                    <Typography color={theme.palette.text.primary} variant={"inherit"}>{ participant === trip.participants.length ? 'Shared' : (trip.participants[participant] || participant) }</Typography>
                     </Button> </> }

            >

            </CardHeader>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
            <TableContainer style={{marginTop: 5, marginBottom: 5}}>
                <Table size="small" aria-label="a dense table">

                    { participant === "Tap Item to Assign" && <TableHead>
                    </TableHead> }
                    <TableBody>

                        {itemizedEntry.items.map((item, index) => renderItem(item, participant))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            </Collapse>
        </Card>
    );
}


function ItemAssignmentDialog({trip, user}) {
    const params = useParams();
    const itemizedEntry = trip.expenseEntries.find( e => e.id === Number(params.entry));

    const [selectedItems, setSelectedItems] = useState({});
    const [payers, setPayers] = useState(itemizedEntry && itemizedEntry.payers);
    const [currency, setCurrency] = React.useState(itemizedEntry?.currency);
    const [payerAmounts, setPayerAmounts] = React.useState(itemizedEntry?.payerAmounts);
    const [description, setDescription] = useState(itemizedEntry?.description);
    const [activeParticipants, setActiveParticipants] = useState(itemizedEntry?.participants);
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const closeDialog = ()=> {
        if(searchParams.get('share')){
            goBack(location, navigate, '/');
        } else {
            goBack(location, navigate, '/' + trip.id + '/expenses')
        }

    }
    const getNumSelected = ()=> {
        let numSelected = 0
        for(let prop in selectedItems){
            numSelected+= selectedItems[prop];
        }
        return numSelected;
    }
    let participantsWithOneOrMoreItemsMap = {};
    let hasUnassigned = false;

    if(itemizedEntry){
        itemizedEntry.items.forEach(item => {
            let totalAssigned = item.quantity;
            item.assignees?.forEach(a=> {
                totalAssigned -= a.quantity;
                participantsWithOneOrMoreItemsMap[a.name] = true; } );

            if(totalAssigned > 0){
                hasUnassigned = true;
            }
        })
    }







    const isMobile = window.innerWidth <= 768;

    const disableCondition = ()=> {
        let disabled = false;
        let set = new Set(activeParticipants);
        if(payers.length === 0 || activeParticipants.length < 2){
            disabled = true;
        }
        payers.map(p=> {
            if(!set.has(p)){
                disabled  = true;
            }
        })
        let left = Number((payerAmounts.reduce((a, b) => a + b, 0).toFixed(getCurrencyFraction(currency))));
        let right = Number(Number(itemizedEntry.amount).toFixed(getCurrencyFraction(currency)));
        if( left !== right ){
            disabled = true;
        }
        return disabled;
    }


    return (
        <Dialog
            fullScreen={isMobile}
            fullWidth={true}
            open={true}
            onClose={closeDialog}
        >
            <DialogTitle style={{padding: 0}}  id="alert-dialog-title">

                { !itemizedEntry ?

                    <div
                        style={{  padding: 17}}
                    >
                        Expense Entry Not Found
                        <CloseDialogButton onClick={closeDialog}/></div> : itemizedEntry?.payers.length === 0 ? <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Update Payer & Participants <CloseDialogButton onClick={closeDialog}/>
                    </Typography>
                    </Toolbar> : <EnhancedTableToolbar
                        trip={trip}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        closeDialog={closeDialog}
                        numSelected={getNumSelected()} itemizedEntry={itemizedEntry}/> }

            </DialogTitle>
            {itemizedEntry ? itemizedEntry.payers.length > 0 ? <DialogContent>
                    { !hasUnassigned && <Zoom in={true}><Alert style={{marginTop: 10}} variant={"standard"} severity={"success"}>All Items Are Assigned!</Alert></Zoom> }
                {
                    ["Tap Item to Assign", ...itemizedEntry.participants, trip.participants.length].map((participant) =>
                        (participantsWithOneOrMoreItemsMap[participant] || (participant === "Tap Item to Assign" && hasUnassigned)  ) &&
                        <ParticipantItemsTable setSelectedItems={setSelectedItems} trip={trip} itemizedEntry={itemizedEntry} participant={participant} selectedItems={selectedItems}/>
                    )
                }
                { trip.version > 1 && <Grid container style={{paddingTop: 10}}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button variant={"contained"}
                                onClick={async () => {
                                    await shareSplitGroup(trip.id, trip.participants, itemizedEntry.id);
                                    logActivity('shareExpenseLink', trip, user);
                                }}
                                startIcon={<Share/>}>Share Expense Link</Button>
                    </Grid>
                </Grid> }
                </DialogContent> : <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <ExpenseStepper payerAmounts={payerAmounts}
                                        setPayerAmounts={setPayerAmounts}
                                        participants={trip.participants}
                                        currency={itemizedEntry.currency}
                                        setDescription={setDescription}
                                        description={description}
                                        setPayers={setPayers}
                                        payers={payers}
                                        amount={itemizedEntry.amount}
                                        addExpenseType={3}
                                        activeParticipants={activeParticipants}
                                        setActiveParticipants={setActiveParticipants}
                                        noMultiplePayers={trip.version < 2}
                         />
                    </Grid>


                </Grid>

                </DialogContent> :
                <DialogContent>
                    <Alert severity={"warning"}>Looks like someone has removed this expense from the split.</Alert>
                </DialogContent>
            }
            {
                !itemizedEntry ? <DialogActions></DialogActions> :
                itemizedEntry?.payers.length > 0 ?
                    <DialogActions >
                        <Button style={{float: "left", marginRight: 5}} variant={"outlined"} color={"info"}
                                onClick={closeDialog}>Close</Button>
                    </DialogActions>:
                <DialogActions >
                    <Button style={{float: "left", marginRight: 5}} variant={"outlined"} color={"info"}
                            onClick={closeDialog}>{payers.length === 0 ? 'Do It Later' : 'Cancel'}</Button>
                    <LoadingButton

                    loading={loading}
                    disabled={ disableCondition() }
                    type="submit"
                    variant="contained"
                    onClick={async ()=>{
                        setLoading(true);
                        let expenseEntries = JSON.parse(JSON.stringify(trip.expenseEntries));
                        let index = expenseEntries.findIndex(e => e.id === itemizedEntry.id);
                        let clone = Object.assign({}, itemizedEntry);
                        clone.payers = payers;
                        clone.payerAmounts = payerAmounts;
                        clone.participants = activeParticipants;
                        clone.description = description;
                        expenseEntries[index] = clone;
                        trip.expenseEntries = expenseEntries;
                        await API.graphql(graphqlOperation(updateTrip,{
                            input : serializeGroup(trip)
                        }));
                        setLoading(false);

                    }
                }
                    startIcon={<CheckCircleOutlineOutlinedIcon/>}

                >Update</LoadingButton></DialogActions>
            }

        </Dialog>
    );
}

export default ItemAssignmentDialog;