/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const createTrip = /* GraphQL */ `
  mutation CreateTrip(
    $input: CreateTripInput!
    $condition: ModelTripConditionInput
  ) {
    createTrip(input: $input, condition: $condition) {
      id
      currency
      name
      settings
      participants
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTrip = /* GraphQL */ `
  mutation UpdateTrip(
    $input: UpdateTripInput!
    $condition: ModelTripConditionInput
  ) {
    updateTrip(input: $input, condition: $condition) {
      id
      currency
      name
      settings
      participants
      numberOfPastSplits
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrip = /* GraphQL */ `
  mutation DeleteTrip(
    $input: DeleteTripInput!
    $condition: ModelTripConditionInput
  ) {
    deleteTrip(input: $input, condition: $condition) {
      id
      currency
      name
      settings
      participants
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
