import React, {useState} from 'react';
import {Alert, Card, CardContent, FormControlLabel, Grid, List, Stack, Switch} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PendingPaymentRow from "./PendingPaymentRow/PendingPaymentRow";
import PaidPaymentRow from "./PaidPaymentRow/PaidPaymentRow";
import Button from "@mui/material/Button";
import {BEGIN_ID_TIMESTAMP, goForwardTo, isMobile} from "../Trip/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import AddLinkIcon from '@mui/icons-material/AddLink';
import AppRater from "../AppRater";
import PastSplitPanel from "../PastSplitPanel";
import PastSplitsSvg from "../SvgImages/Home/PastSplitsSvg";

function Results({ trip, user}) {
    const ownageMap = {};

    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [onArchiving, setOnArchiving] = useState(false);
    const [archivingError, setArchivingError] = useState(null);
    const [splitArchived, setSplitArchived] = useState('');

    const checkIfNeedsAssignment = ()=> {
        if(trip && trip.itemizedEntries){
            for(let i = 0, l = trip.itemizedEntries.length; i < l; i++){
                if(trip.itemizedEntries[i].items.length > 0){
                    return true;
                }
            }
        }
        return false;
    }
    let paidEntryGroups = trip.expenseEntries.filter(e=> e.type === 4);

    let expanded = true;

    let initialyExpanded = ()=> {
        if(expanded){
            expanded = false;
            return true;
        } else {
            return false;
        }
    }

    return (


        <Box sx={{ width: '100%' }}>
            { !isMobile() && <Grid container>
                <Grid item xs={12} textAlign={"center"}>
                    <Typography variant="overline" display="block" gutterBottom>
                        PENDING SETTLEMENT
                    </Typography>
                </Grid>

            </Grid> }
            <Grid container>

                <Grid item xs={12} textAlign={"center"}>
                    <FormControlLabel
                        label="Minimize Transactions"
                        value={trip.simplify}
                        control={
                            <Switch
                                onClick={()=>{
                                    goForwardTo(location, navigate,"/" + trip.id + "/results/simplify");
                                } }
                                checked={trip.simplify} color={"primary"}  />
                        }/>

                </Grid>
            </Grid>



            <Stack
                style={{ marginTop : '10px'}}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >

                { trip.ownage.totalOwnage === 0  &&
                    <Grid container style={{ paddingTop: 10, paddingBottom: 10 }} textAlign={"center"}>
                        <Grid item xs={12} textAlign={"center"}>
                        <Card>



                            <CardContent style={{padding: 10}}>

                                <Alert style={{ margin: 12}} variant={"outlined"} severity={"success"}>{  trip && trip.expenseEntries.length > 0 ? "All Squared Away." : "No Expenses to Settle." }</Alert>
                                { trip && trip.expenseEntries.length > 0 && <PastSplitPanel noClose={true} isNew={true} group={trip} split={{
                                    id: (trip.paidEntries?.length > 0 && Number(trip.paidEntries[trip.paidEntries.length - 1].id)) || (Date.now() - BEGIN_ID_TIMESTAMP),
                                    body: trip
                                }} /> }
                                { trip && trip.expenseEntries.length > 0 && <Button startIcon={<PastSplitsSvg/>} onClick={()=>{ goForwardTo(location, navigate,'/' + trip.id + '/results/doarchive'); } } >Archive</Button> }
                            </CardContent>
                        </Card>

                        </Grid>


                    </Grid>
                }
                <Grid container>

                        { trip.ownage.totalOwnageMatrix.map((item, index)=> (item.reduce((a, b) => a + b, 0)) === 0 ? <></> :

                            <Grid item xs={12} style={{marginBottom: 16}}><PendingPaymentRow expandedIntially={initialyExpanded()} rowIndex={index} trip={trip} ownageRow={item} /></Grid>   ) }

                    {/*<Grid item xs={12}>*/}
                    {/*    <Button fullWidth={true}  startIcon={<><PersonOutlineIcon style={{ display: "none"}}/><PersonOutlineIcon/><ArrowRightAltIcon/><PersonOutlineIcon/></>}>Record Payement</Button>*/}
                    {/*</Grid>*/}
                </Grid>

                { trip.ownage.totalOwnage > 0  && <>
                    <Typography textAlign={"center"} style={{paddingTop: 4, color: "#5A7184"}}>
                        Some people are paying together?
                    </Typography>
                    <Typography style={{ marginTop: -3, paddingTop: 10}} textAlign={"center"}>
                        <Button variant={"contained"} size={"small"} startIcon={<AddLinkIcon/>} onClick={()=> {
                            goForwardTo(location, navigate,'/' + trip.id + '/results/link');
                        }}>{trip.linkedParticipants?.length > 0 ? "Link/Unlink Them" : "Link Them"}</Button>
                    </Typography>

                </>

                }



                {
                    window.cordova && trip.expenseEntries?.length > 3 && <Grid container style={{paddingTop: 10}}><AppRater user={user}/></Grid>
                }

            </Stack>



            {paidEntryGroups.length > 0 && <Divider > <Typography variant="overline" display="block" gutterBottom>
                PAID
            </Typography></Divider>}
            <Stack
                style={{ marginTop : '10px', marginBottom: 60}}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <List disablePadding={true}  sx={{ width: '100%'  }}>
                    { paidEntryGroups.map(paidEntry => <PaidPaymentRow trip={trip} paidEntry={paidEntry}/> ) }

                </List>

            </Stack>

        </Box>
    );
}

export default Results;