import React from 'react';
import {goBack, isMobile} from "./Trip/Utils";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import Container from "@mui/material/Container";
import {useLocation, useNavigate} from "react-router-dom";
import GroupMembers from "./GroupMembers/GroupMembers";
import Button from "@mui/material/Button";

function GroupMembersDialog({group, user}) {
    const navigate = useNavigate();
    const location = useLocation();

    const closeDialog = ()=> {

        goBack(location, navigate, '/');
    }

    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile()}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent>
                <Container>
                    <GroupMembers group={group} user={user} />
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={closeDialog}> Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default GroupMembersDialog;