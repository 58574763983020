import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {decorateFromSettingsString, goBack, logActivity} from "../Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import CurrencySelector from "../Trip/CurrencySelector";
import Alert from "@mui/material/Alert";
import CloseDialogButton from "./CloseDialogButton";
import {API, graphqlOperation} from "aws-amplify";
import {getCurrencyConversion} from "../../graphql/queries";
import {updateTrip} from "../../graphql/mutations";
import {serializeGroup} from "../Trip/Utils/expenseUtils";

function ChangeDefaultCurrencyDialog({group, setGroup, user}) {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [currency, setCurrency] = React.useState(group.currency);

    const closeDialog = () => {
        goBack(location, navigate, group ? '/' + group.id + '/results' : '/');
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Default Currency<CloseDialogButton onClick={closeDialog}/></DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert variant={"standard"} severity={"info"}>This will change default group currency and update all expenses</Alert>
                    </Grid>
                    <Grid style={{marginTop:20}}  item xs={12}>
                        <CurrencySelector fullWidth currency={currency} setCurrency={setCurrency}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color={"info"}> Cancel</Button>
                <LoadingButton loading={loading} disabled={currency === group.currency} onClick={async ()=> {
                    setLoading(true);
                    try {
                        let oldCurrency = group.currency;
                        let clone = JSON.parse(JSON.stringify(group));
                        let datesToProcessSet = new Set();
                        let processedDatesMap = {};
                        for (const expenseEntry of clone.expenseEntries) {
                            if(expenseEntry.currency !== clone.currency && expenseEntry.currency !== currency) {
                                datesToProcessSet.add(expenseEntry.date);
                            }
                        }
                        let responses = await Promise.all(Array.from(datesToProcessSet).map(d=>
                            API.graphql(graphqlOperation(getCurrencyConversion, {
                                base: currency,
                                date: d
                            } ))
                        ))
                        for (const resp of responses) {
                            let o = JSON.parse(resp.data.getCurrencyConversion);
                            processedDatesMap[`${o.year}-${o.month.toString().padStart(2, '0')}-${o.day.toString().padStart(2, '0')}`] = o.conversion_rates;
                        }
                        for (const expenseEntry of clone.expenseEntries) {
                            if(expenseEntry.currency === currency) {
                                expenseEntry.rate = 1;
                            } else if (expenseEntry.currency === clone.currency) {
                                expenseEntry.currency = currency;
                            } else {
                                expenseEntry.rate = processedDatesMap[expenseEntry.date][expenseEntry.currency];
                            }
                            if(expenseEntry.currency !== clone.currency && expenseEntry.currency !== currency) {
                                datesToProcessSet.add(expenseEntry.date);
                            }
                        }
                        clone.currency = currency;

                        let response = await API.graphql(graphqlOperation(updateTrip, {
                            input : serializeGroup(clone)
                        }));
                        logActivity("changeCurrency", {...group, data : { oldCurrency, newCurrency : currency } }, user);
                        decorateFromSettingsString(response.data.updateTrip);
                        setGroup(response.data.updateTrip);


                        closeDialog();
                    }catch(e) {
                        console.error(e);
                        setError(e);
                    } finally {
                        setLoading(false);
                    }
                } }  color={"primary"}>Update</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ChangeDefaultCurrencyDialog;