import {styled} from "@mui/material/styles";
import {IconButton} from "@mui/material";
import React from "react";
import CurrencySelector from "./CurrencySelector";

import {Amplify, API, Auth, graphqlOperation} from 'aws-amplify';
import awsExports from './../../aws-exports';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {getPreSignedUrl, getUser} from "../../graphql/queries";
import {createUser, updateUser} from "../../graphql/mutations";
import {getCurrencyFraction, getCurrencySymbol} from "./Currencies";
import {deserializeGroup, explodeTypeOneWithItems, getOwnage} from "./Utils/expenseUtils";
import jwt_decode from "jwt-decode";

const isCordova = !!window.cordova;
console.log(' your isCordova flag:', isCordova);

const isLocalhost = !isCordova && Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
    productionRedirectSignIn,
    localRedirectSignIn
] = awsExports.oauth.redirectSignIn.split(",");

const [
    productionRedirectSignOut,
    localRedirectSignOut,
] = awsExports.oauth.redirectSignOut.split(",");

const getUrl = (url) => {
    if (isCordova) {
        let newUrl = url.replace('https://app.splitpal.io', 'splitpal://');
        console.log('new url -->> ', newUrl);
        return newUrl;
    } else {
        return url;
    }
}
const updatedAwsConfig = {
    ...awsExports,
    oauth: {
        ...awsExports.oauth,
        redirectSignIn: isLocalhost ? localRedirectSignIn : getUrl(productionRedirectSignIn),
        redirectSignOut: isLocalhost ? localRedirectSignOut : getUrl(productionRedirectSignOut),
    }
}

updatedAwsConfig.oauth.domain = 'auth.splitpal.io'; //https://github.com/aws-amplify/amplify-cli/issues/1880

export const amplifyConfigure = () => {
    Amplify.configure(updatedAwsConfig);
}


export const LIMIT_REACHED = 500;

export const BEGIN_ID_TIMESTAMP = 1676520632794; // magic number start all ids from sometime in the evening of 2023-02-15


export const getUserProfileData = async(email)=> {

    let resp = await API.graphql({
        query: getPreSignedUrl,
        variables: {
            id: "splitpal-user~|~" + email
        }
    });
    return resp;
}



export const getEmailFromUser = (user)=>{
    if(user){
        return (user.attributes && user.attributes['custom:realemail']) || user.email  ||  user.attributes?.email;
    } else {
        return null;
    }
}

export const updateUserProfileData = async(data)=> {
    let currentUser = await Auth.currentAuthenticatedUser();
    let token = currentUser.signInUserSession.accessToken.getJwtToken();
    let resp = await API.graphql({
        query: getPreSignedUrl,
        variables: {
            id: "splitpal-update-user~|~" + JSON.stringify(data)
        },

        authToken: token
    });
    return resp;
}


export const syncUserData = async (username) => {
    console.log('syncing user', username);
    let response = await API.graphql(graphqlOperation(getUser, {
        id: username
    }));

    if (response.data.getUser?.groups) {
        let userGroupsMap = JSON.parse(response.data.getUser.groups);
        let localGroupsMap = JSON.parse(localStorage.getItem("splitpal_groups") || "{}");
        localStorage.setItem("splitpal_groups", JSON.stringify(Object.assign(localGroupsMap, userGroupsMap)));
        console.log('syncing user: synced', localStorage.getItem("splitpal_groups"));
    } else {
        console.log('syncing user: no groups');
    }

}

export function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const goBack = (location, navigate, url)=>{
    if(url === location.pathname ){
        return;
    }
    if (location && location.key !== 'default') {
        navigate(-1);
    } else {
        navigate(url, {replace: true});
    }
}

export const goForwardTo = (location, navigate, url, replace) => {
    if (url !== location.pathname) {
        navigate(url, {replace});
    }
}

export const addTrackingToUser = async (user, newGroup) => {
    let email = getEmailFromUser(user);
    if (email) {
        let localUserGroups = JSON.parse(localStorage.getItem("splitpal_user_" + email) || "{}");
        if (!localUserGroups[newGroup.id]) {
            console.log(`adding tracking of ${newGroup.id} to ${email}`);
            let response = await API.graphql(graphqlOperation(getUser, {
                id: email
            }));
            let groups = JSON.parse(response?.data?.getUser?.groups || "{}");
            groups[newGroup.id] = newGroup;
            await API.graphql(graphqlOperation(response?.data?.getUser ? updateUser : createUser, {
                input: {
                    id: email,
                    groups: JSON.stringify(groups)
                }
            }));
        }
        localUserGroups[newGroup.id] = newGroup;
        localStorage.setItem("splitpal_user_" + email, JSON.stringify(localUserGroups));
    }
}

export const shareSplitGroup = async (id, participants, expenseId) => {
    try {
        let text = participants ? 'Split Expenses between ' + participants.join(", ") : 'SplitPal Group link: '
        let url = `https://app.splitpal.io/#/${id}`;
        if(expenseId){
            url += '/expenses/assign/' + expenseId  + '?share=true';
        }
        await navigator.share({
            title: 'SplitPal',
            text,
            url
        })
        console.log('success')
    } catch (err) {
        console.log(err)
    }
}
export const getGroupVersion = () => 3;
export const SPLITPAL_ERRORS = {
    GROUP_IS_CORRUPT: 'Data for this group appears to be corrupt. Appologies for the inconvenience, please reach out to mysplitpal@gmail.com for further assistence.',
    HIGHER_GROUP_VERSION_REQUIRED: 'This group requires higher SplitPal version. Please update the app',
    GROUP_NOT_FOUND: 'Group not found.',
    GROUP_IS_TOO_OLD: 'This group has been created by earlier version of SplitPal and cannot be used in this version.',
    NOT_ABLE_TO_REESTABLISH_CONNECTION: "Not able to re-establish connection. Please reload the app."
}
export const getSettingsString = (group = {}) => {
    let linkedParticipants = group.linkedParticipants.filter(lp => lp.length > 1);
    let trueLp = [];
    for(let i = 0, l = linkedParticipants.length; i <l; i++){
        trueLp.push(linkedParticipants[i].map(p=>group.participants[p]));
    }
    return `0~sp|~${JSON.stringify(trueLp)}~sp|~${(group.pastSplits || 0)}~sp|~${(group.simplify ? 1 : 0)}~sp|~` + group.version || getGroupVersion();
}


export const decorateFromSettingsString = (group) => {
    if(group.alreadyDecoded){
        console.log('attempting to decode already decoded group', group);
        return 0;
    } else {
    }

    group.linkedParticipants = [];
    group.pastSplits = 0;
    group.simplify = 0;
    group.version = 0;

    try {
         if (group.settings) {
            let tokens = group.settings.split("~sp|~");
            if (tokens[4]) {
                group.version = Number(tokens[4]);
            }
            if(group.version > 1){
                let g = deserializeGroup(group.name, group.id);
                Object.assign(group, g);
                group.alreadyDecoded = true;
            } else {
                let participantMap = {};
                for(let i = 0, l = group.participants.length; i < l; i++){
                    participantMap[group.participants[i]] = i;
                }
                participantMap['shared'] = group.participants.length;

                group.linkedParticipants = [];
                group.linkedParticipantMap = {};


                let linkedParticipantsArrays = [];
                if(tokens[1]){
                    let lpArrays = JSON.parse(tokens[1]);
                    for(let i = 0, l = lpArrays.length; i < l; i++){
                        linkedParticipantsArrays.push(lpArrays[i].map(p => participantMap[p]));
                    }
                }
                let used = new Set();
                let i = 0;
                let lpUsedMap = {};
                for(i; i < linkedParticipantsArrays.length; i++){
                    let array = linkedParticipantsArrays[i];
                    array.forEach(p => used.add(p));
                    array.forEach(p=> group.linkedParticipantMap[p] = i);
                    group.linkedParticipants.push(array);
                }

                for(let j = 0, l = group.participants.length; j < l; j++){
                    if(!used.has(j)){
                        let array = [j];
                        group.linkedParticipantMap[j] = i++;
                        group.linkedParticipants.push(array);
                    }
                }

                let expenseEntries = group.expenseEntries;
                let convertedExpenseEntries = [];
                for (let i = 0; i < expenseEntries.length; i++) {
                    let expenseEntry = expenseEntries[i];
                    let convertedExpenseEntry = {};
                    convertedExpenseEntry.participants = expenseEntry.participants.map(p => participantMap[p]);
                    convertedExpenseEntry.payers =expenseEntry.payer ? [participantMap[expenseEntry.payer]] : [];

                    convertedExpenseEntry.rate = expenseEntry.rate;
                    convertedExpenseEntry.tax = expenseEntry.tax;
                    convertedExpenseEntry.discount = expenseEntry.discount;
                    convertedExpenseEntry.tip = expenseEntry.tip;
                    convertedExpenseEntry.currency = expenseEntry.currency;
                    convertedExpenseEntry.date = expenseEntry.date;
                    convertedExpenseEntry.description = expenseEntry.description;
                    convertedExpenseEntry.id = Number(expenseEntry.id) - BEGIN_ID_TIMESTAMP;
                    convertedExpenseEntry.items = [];
                    if(expenseEntry.itemized === false && expenseEntry.items.length === 0){
                        convertedExpenseEntry.type = 1;
                        convertedExpenseEntry.payerAmounts = [expenseEntry.amount];
                        explodeTypeOneWithItems(convertedExpenseEntry);
                    } else if( (expenseEntry.itemized === false && expenseEntry.items.length > 0) || expenseEntry.itemized === true) {
                        convertedExpenseEntry.type = expenseEntry.itemized ? 3 : 2;
                        let amount = 0;
                        for (let j = 0; j < expenseEntry.items.length; j++) {
                            let item = expenseEntry.items[j];
                            amount+= item.price;
                            if(item.assignees){
                                for (let k = 0; k < item.assignees.length; k++) {
                                    item.assignees[k].name = participantMap[item.assignees[k].name];
                                }
                            } else {
                                item.assignees = [];
                            }
                        }
                        amount+= ( Number(convertedExpenseEntry.tax) + Number(convertedExpenseEntry.tip) - Math.abs(Number(convertedExpenseEntry.discount || 0)) );
                        convertedExpenseEntry.payerAmounts = [amount];
                        convertedExpenseEntry.amount = amount;
                        convertedExpenseEntry.items = expenseEntry.items;

                    }


                    convertedExpenseEntries.push(convertedExpenseEntry);
                }
                if(group.paidEntries){
                    for(let i = 0, l = group.paidEntries.length; i < l; i++){
                        let paidEntry = group.paidEntries[i];
                        let payers = group.linkedParticipants[group.linkedParticipantMap[participantMap[paidEntry.payer]]];
                        let payees = group.linkedParticipants[group.linkedParticipantMap[participantMap[paidEntry.payee]]];

                        lpUsedMap[getBitmapFromArray(payers)] = true;
                        lpUsedMap[getBitmapFromArray(payees)] = true;

                        let items = payees.map((p, index)=>{ return {

                            name : 'portion',
                            price : index === 0 ? paidEntry.amount : 0,
                            quantity: 1,
                            id : (Number(paidEntry.id) - BEGIN_ID_TIMESTAMP),
                            assignees : [{ name: p, quantity: 1 }]
                        } });
                        let convertedExpenseEntry = {
                            type : 4,
                            payers: payers,
                            participants: [
                                ...payers,
                                ...payees
                            ],
                            payerAmounts: [paidEntry.amount],
                            amount : paidEntry.amount,
                            currency : group.currency,
                            description: paidEntry.comment,
                            id : Number(paidEntry.id) - BEGIN_ID_TIMESTAMP,
                            rate : 1,
                            tax : 0,
                            tip : 0,
                            discount : 0,
                            items : items
                        }
                        convertedExpenseEntries.push(convertedExpenseEntry);

                    }
                }


                group.expenseEntries = convertedExpenseEntries;
                group.lpUsedMap = lpUsedMap;
                group.pastSplits =  tokens[2] ? Number(tokens[2]) : 0;
                group.simplify = tokens[3] ? Number(tokens[3]) : 0;
                group.ownage = getOwnage(group);
                group.alreadyDecoded = true;
                return 0;
            }


        }
        return 0;

    } catch (e) {
        console.error(e);
        return -1;
    }
}
export  const monetaryValue = (value, currency)=> {
    let fraction = getCurrencyFraction(currency);
    return Number((value || 0).toFixed(fraction));
}
export const compareMonetaryValues = (left, right, currency) => {
    const cf = getCurrencyFraction(currency);
    return Number((right || 0).toFixed(cf)) - Number((left || 0).toFixed(cf))
}

export const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const getTotalExpenseEntryAmount = (expenseEntry) => {
    return expenseEntry.payerAmounts.reduce((n, a) => n + a, 0);
}

export const getBitmapFromArray = (array) => {
    let bitmap = 0;
    for(let i = 0, l = array.length; i < l ; i++){
        bitmap |= 1 << array[i];
    }
    return bitmap;
}

export const getArrayFromBitmap = (bitmap) => {
    let array = [];
    for(let i = 0; i < 32; i++){
        let v = bitmap & (1 << i);
        if(v){
            array.push(i);
        }
    }
    return array
}

export const expenseSavingDisableCondition = (type, items, currency, amount, payers, payerAmounts, description, activeParticipants, unevenItems, date )=> {
    if(activeParticipants.length < 2){
        return true;
    }
    if(!date){
        return true;
    }
    if(type === 3){
        let total = 0;
        for(let i =0, l = items.length; i <l;i++){
            total += items[i].price;
        }
        return  items.length === 0 || total === 0;
    } else {
        if(!amount){
            return true;
        }
        if(payers.length === 0){
            return true;
        }
        if(payerAmounts.length !== payers.length){
            return true;
        }
        if(!description){
            return true;
        }

        const payersTotal = payerAmounts.reduce((a, b) => a + b, 0);
        if(compareMonetaryValues(payersTotal, amount) !== 0){
            return true;
        }
        if(type === 2){
            let total = 0;
            for(let i = 0, l = unevenItems.length; i < l; i++){
                total += unevenItems[i].price;
            }
            if(compareMonetaryValues(total, amount, currency) !== 0){
                return true;
            }

        }
    }
    return false;
}

export const Currencies = [
    {
        "currency": "UAE Dirham",
        "abbreviation": "AED",
        "symbol": "&#x62f;&#x2e;&#x625;"
    },
    {
        "currency": "Albania Lek",
        "abbreviation": "ALL",
        "symbol": "&#76;&#101;&#107;"
    },
    {
        "currency": "Afghanistan Afghani",
        "abbreviation": "AFN",
        "symbol": "&#1547;"
    },
    {
        "currency": "Argentina Peso",
        "abbreviation": "ARS",
        "symbol": "&#36;"
    },
    {
        "currency": "Aruba Guilder",
        "abbreviation": "AWG",
        "symbol": "&#402;"
    },
    {
        "currency": "Australia Dollar",
        "abbreviation": "AUD",
        "symbol": "&#36;"
    },
    {
        "currency": "Azerbaijan New Manat",
        "abbreviation": "AZN",
        "symbol": "&#1084;&#1072;&#1085;"
    },
    {
        "currency": "Bahamas Dollar",
        "abbreviation": "BSD",
        "symbol": "&#36;"
    },
    {
        "currency": "Barbados Dollar",
        "abbreviation": "BBD",
        "symbol": "&#36;"
    },
    {
        "currency": "Belize Dollar",
        "abbreviation": "BZD",
        "symbol": "&#66;&#90;&#36;"
    },
    {
        "currency": "Bermuda Dollar",
        "abbreviation": "BMD",
        "symbol": "&#36;"
    },
    {
        "currency": "Bolivia Boliviano",
        "abbreviation": "BOB",
        "symbol": "&#36;&#98;"
    },
    {
        "currency": "Marka",
        "abbreviation": "BAM",
        "symbol": "&#75;&#77;"
    },
    {
        "currency": "Botswana Pula",
        "abbreviation": "BWP",
        "symbol": "&#80;"
    },
    {
        "currency": "Bulgaria Lev",
        "abbreviation": "BGN",
        "symbol": "&#1083;&#1074;"
    },
    {
        "currency": "Brazil Real",
        "abbreviation": "BRL",
        "symbol": "&#82;&#36;"
    },
    {
        "currency": "Brunei Darussalam Dollar",
        "abbreviation": "BND",
        "symbol": "&#36;"
    },
    {
        "currency": "Cambodia Riel",
        "abbreviation": "KHR",
        "symbol": "&#6107;"
    },
    {
        "currency": "Canada Dollar",
        "abbreviation": "CAD",
        "symbol": "&#36;"
    },
    {
        "currency": "Cayman Islands Dollar",
        "abbreviation": "KYD",
        "symbol": "&#36;"
    },
    {
        "currency": "Chile Peso",
        "abbreviation": "CLP",
        "symbol": "&#36;"
    },
    {
        "currency": "China Yuan Renminbi",
        "abbreviation": "CNY",
        "symbol": "&#165;"
    },
    {
        "currency": "Colombia Peso",
        "abbreviation": "COP",
        "symbol": "&#36;"
    },
    {
        "currency": "Costa Rica Colon",
        "abbreviation": "CRC",
        "symbol": "&#8353;"
    },
    {
        "currency": "Croatia Kuna",
        "abbreviation": "HRK",
        "symbol": "&#107;&#110;"
    },
    {
        "currency": "Cuba Peso",
        "abbreviation": "CUP",
        "symbol": "&#8369;"
    },
    {
        "currency": "Czech Republic Koruna",
        "abbreviation": "CZK",
        "symbol": "&#75;&#269;"
    },
    {
        "currency": "Denmark Krone",
        "abbreviation": "DKK",
        "symbol": "&#107;&#114;"
    },
    {
        "currency": "Dominican Republic Peso",
        "abbreviation": "DOP",
        "symbol": "&#82;&#68;&#36;"
    },
    {
        "currency": "East Caribbean Dollar",
        "abbreviation": "XCD",
        "symbol": "&#36;"
    },
    {
        "currency": "Egypt Pound",
        "abbreviation": "EGP",
        "symbol": "&#163;"
    },
    {
        "currency": "Euro Member Countries",
        "abbreviation": "EUR",
        "symbol": "&#8364;"
    },
    {
        "currency": "Falkland Islands (Malvinas) Pound",
        "abbreviation": "FKP",
        "symbol": "&#163;"
    },
    {
        "currency": "Fiji Dollar",
        "abbreviation": "FJD",
        "symbol": "&#36;"
    },
    {
        "currency": "Gibraltar Pound",
        "abbreviation": "GIP",
        "symbol": "&#163;"
    },
    {
        "currency": "Guatemala Quetzal",
        "abbreviation": "GTQ",
        "symbol": "&#81;"
    },
    {
        "currency": "Guernsey Pound",
        "abbreviation": "GGP",
        "symbol": "&#163;"
    },
    {
        "currency": "Guyana Dollar",
        "abbreviation": "GYD",
        "symbol": "&#36;"
    },
    {
        "currency": "Honduras Lempira",
        "abbreviation": "HNL",
        "symbol": "&#76;"
    },
    {
        "currency": "Hong Kong Dollar",
        "abbreviation": "HKD",
        "symbol": "&#36;"
    },
    {
        "currency": "Hungary Forint",
        "abbreviation": "HUF",
        "symbol": "&#70;&#116;"
    },
    {
        "currency": "Iceland Krona",
        "abbreviation": "ISK",
        "symbol": "&#107;&#114;"
    },
    {
        "currency": "India Rupee",
        "abbreviation": "INR",
        "symbol": null
    },
    {
        "currency": "Indonesia Rupiah",
        "abbreviation": "IDR",
        "symbol": "&#82;&#112;"
    },
    {
        "currency": "Iran Rial",
        "abbreviation": "IRR",
        "symbol": "&#65020;"
    },
    {
        "currency": "Isle of Man Pound",
        "abbreviation": "IMP",
        "symbol": "&#163;"
    },
    {
        "currency": "Israel Shekel",
        "abbreviation": "ILS",
        "symbol": "&#8362;"
    },
    {
        "currency": "Jamaica Dollar",
        "abbreviation": "JMD",
        "symbol": "&#74;&#36;"
    },
    {
        "currency": "Japan Yen",
        "abbreviation": "JPY",
        "symbol": "&#165;"
    },
    {
        "currency": "Jersey Pound",
        "abbreviation": "JEP",
        "symbol": "&#163;"
    },
    {
        "currency": "Kazakhstan Tenge",
        "abbreviation": "KZT",
        "symbol": "&#1083;&#1074;"
    },
    {
        "currency": "Kyrgyzstan Som",
        "abbreviation": "KGS",
        "symbol": "&#1083;&#1074;"
    },
    {
        "currency": "Laos Kip",
        "abbreviation": "LAK",
        "symbol": "&#8365;"
    },
    {
        "currency": "Lebanon Pound",
        "abbreviation": "LBP",
        "symbol": "&#163;"
    },
    {
        "currency": "Liberia Dollar",
        "abbreviation": "LRD",
        "symbol": "&#36;"
    },
    {
        "currency": "Macedonia Denar",
        "abbreviation": "MKD",
        "symbol": "&#1076;&#1077;&#1085;"
    },
    {
        "currency": "Malaysia Ringgit",
        "abbreviation": "MYR",
        "symbol": "&#82;&#77;"
    },
    {
        "currency": "Mauritius Rupee",
        "abbreviation": "MUR",
        "symbol": "&#8360;"
    },
    {
        "currency": "Mexico Peso",
        "abbreviation": "MXN",
        "symbol": "&#36;"
    },
    {
        "currency": "Mongolia Tughrik",
        "abbreviation": "MNT",
        "symbol": "&#8366;"
    },
    {
        "currency": "Mozambique Metical",
        "abbreviation": "MZN",
        "symbol": "&#77;&#84;"
    },
    {
        "currency": "Namibia Dollar",
        "abbreviation": "NAD",
        "symbol": "&#36;"
    },
    {
        "currency": "Nepal Rupee",
        "abbreviation": "NPR",
        "symbol": "&#8360;"
    },
    {
        "currency": "Netherlands Antilles Guilder",
        "abbreviation": "ANG",
        "symbol": "&#402;"
    },
    {
        "currency": "New Zealand Dollar",
        "abbreviation": "NZD",
        "symbol": "&#36;"
    },
    {
        "currency": "Nicaragua Cordoba",
        "abbreviation": "NIO",
        "symbol": "&#67;&#36;"
    },
    {
        "currency": "Nigeria Naira",
        "abbreviation": "NGN",
        "symbol": "&#8358;"
    },
    {
        "currency": "Korea (North) Won",
        "abbreviation": "KPW",
        "symbol": "&#8361;"
    },
    {
        "currency": "Norway Krone",
        "abbreviation": "NOK",
        "symbol": "&#107;&#114;"
    },
    {
        "currency": "Oman Rial",
        "abbreviation": "OMR",
        "symbol": "&#65020;"
    },
    {
        "currency": "Pakistan Rupee",
        "abbreviation": "PKR",
        "symbol": "&#8360;"
    },
    {
        "currency": "Panama Balboa",
        "abbreviation": "PAB",
        "symbol": "&#66;&#47;&#46;"
    },
    {
        "currency": "Paraguay Guarani",
        "abbreviation": "PYG",
        "symbol": "&#71;&#115;"
    },
    {
        "currency": "Peru Nuevo Sol",
        "abbreviation": "PEN",
        "symbol": "&#83;&#47;&#46;"
    },
    {
        "currency": "Philippines Peso",
        "abbreviation": "PHP",
        "symbol": "&#8369;"
    },
    {
        "currency": "Poland Zloty",
        "abbreviation": "PLN",
        "symbol": "&#122;&#322;"
    },
    {
        "currency": "Qatar Riyal",
        "abbreviation": "QAR",
        "symbol": "&#65020;"
    },
    {
        "currency": "Romania New Leu",
        "abbreviation": "RON",
        "symbol": "&#108;&#101;&#105;"
    },
    {
        "currency": "Russia Ruble",
        "abbreviation": "RUB",
        "symbol": "&#1088;&#1091;&#1073;"
    },
    {
        "currency": "Saint Helena Pound",
        "abbreviation": "SHP",
        "symbol": "&#163;"
    },
    {
        "currency": "Saudi Arabia Riyal",
        "abbreviation": "SAR",
        "symbol": "&#65020;"
    },
    {
        "currency": "Serbia Dinar",
        "abbreviation": "RSD",
        "symbol": "&#1044;&#1080;&#1085;&#46;"
    },
    {
        "currency": "Seychelles Rupee",
        "abbreviation": "SCR",
        "symbol": "&#8360;"
    },
    {
        "currency": "Singapore Dollar",
        "abbreviation": "SGD",
        "symbol": "&#36;"
    },
    {
        "currency": "Solomon Islands Dollar",
        "abbreviation": "SBD",
        "symbol": "&#36;"
    },
    {
        "currency": "Somalia Shilling",
        "abbreviation": "SOS",
        "symbol": "&#83;"
    },
    {
        "currency": "South Africa Rand",
        "abbreviation": "ZAR",
        "symbol": "&#82;"
    },
    {
        "currency": "Korea (South) Won",
        "abbreviation": "KRW",
        "symbol": "&#8361;"
    },
    {
        "currency": "Sri Lanka Rupee",
        "abbreviation": "LKR",
        "symbol": "&#8360;"
    },
    {
        "currency": "Sweden Krona",
        "abbreviation": "SEK",
        "symbol": "&#107;&#114;"
    },
    {
        "currency": "Switzerland Franc",
        "abbreviation": "CHF",
        "symbol": "&#67;&#72;&#70;"
    },
    {
        "currency": "Suriname Dollar",
        "abbreviation": "SRD",
        "symbol": "&#36;"
    },
    {
        "currency": "Syria Pound",
        "abbreviation": "SYP",
        "symbol": "&#163;"
    },
    {
        "currency": "Taiwan New Dollar",
        "abbreviation": "TWD",
        "symbol": "&#78;&#84;&#36;"
    },
    {
        "currency": "Thailand Baht",
        "abbreviation": "THB",
        "symbol": "&#3647;"
    },
    {
        "currency": "Trinidad and Tobago Dollar",
        "abbreviation": "TTD",
        "symbol": "&#84;&#84;&#36;"
    },
    {
        "currency": "Turkey Lira",
        "abbreviation": "TRY",
        "symbol": "&#x20BA;"
    },
    {
        "currency": "Tuvalu Dollar",
        "abbreviation": "TVD",
        "symbol": "&#36;"
    },
    {
        "currency": "Ukraine Hryvna",
        "abbreviation": "UAH",
        "symbol": "&#8372;"
    },
    {
        "currency": "United Kingdom Pound",
        "abbreviation": "GBP",
        "symbol": "&#163;"
    },
    {
        "currency": "United States Dollar",
        "abbreviation": "USD",
        "symbol": "&#36;"
    },
    {
        "currency": "Uruguay Peso",
        "abbreviation": "UYU",
        "symbol": "&#36;&#85;"
    },
    {
        "currency": "Uzbekistan Som",
        "abbreviation": "UZS",
        "symbol": "&#1083;&#1074;"
    },
    {
        "currency": "Viet Nam Dong",
        "abbreviation": "VND",
        "symbol": "&#8363;"
    },
    {
        "currency": "Yemen Rial",
        "abbreviation": "YER",
        "symbol": "&#65020;"
    }
]

let m = {};
Currencies.forEach(c => m[c.abbreviation] = c.symbol);
export const CurrencySymbol = ({currency, setCurrency}) => <span style={{cursor: "pointer"}} onClick={() => {

}}>

    {setCurrency ? <CurrencySelector short setCurrency={setCurrency} currency={currency}/> :
        <span dangerouslySetInnerHTML={{__html: getCurrencySymbol(currency)}}></span>}
</span>

export const isMobile = () => {
    return window.innerWidth <= 768;
}
export const isShortMobile = () => {
    return false; //window.innerHeight <= 666;
}
const materialColors = [
    '#512da8',
    '#4fc3f7',
    '#0277bd',
    '#00b0ff',
    '#00bcd4',
];

export const logActivity = async (action, group, user) => {
    group = group || {id: "n/a", name: "n/a", participants: []};
    let version = window.cordova ? await window.cordova.getAppVersion.getVersionNumber() : 'n/a';
    let deviceId = window.device && window.device.uuid;
    console.log('logActivity', JSON.stringify(window.device || {}, null, 2));
    API.graphql(graphqlOperation(getPreSignedUrl, {
        id: "splitpal~|~" + (window.cordova ? window.cordova.platformId : "website") + "~|~" + action + "~|~" + JSON.stringify({
            id: group.id || "n/a",
            name: group.name || "n/a",
            version,
            deviceId,
            data: group.data,
            participants: group.participants?.join(","),
            currency: group.currency,
            totalExpenses: (group.expenseEntries || []).length,
            user: getEmailFromUser(user)
        })
    }));
}

export const renderDateFromString = (date) => {
    if (!date) {
        return "";
    }
    let tokens = date.split("-");

    let d = new Date(`${tokens[1]}/${tokens[2]}/${tokens[0]}`) ;
    let locale =  window.navigator?.language || 'en-US';

    return d.toLocaleDateString(locale)
}

export const showInterstititalAd = (trip, user)=> {
    try {
        if (window.cordova && !localStorage.SPLITPAL_REMOVE_ADS) {
            if (window.interstitialLoaded) {
                window.interstitial.show();
                window.interstitialLoaded = false;
                window.interstitial.load();
                logActivity("InterstitalAdShown", trip, user);
            } else {
                logActivity("AdNotLoaded", trip, user);
                window.interstitial.load();
            }
        }
    } catch (e) {
        logActivity("ShowAdFailed", trip, user);
    }
}

export const getMaterialDesignColorFromString = function (str) {
    let hash = 0, i, chr;
    if (str.length === 0) return materialColors[materialColors.length % Math.abs(hash)];
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return materialColors[Math.abs(hash) % materialColors.length];
};


export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


export const getPaidEntryGroups = (trip) => {
    let paidEntryGroups = [];

    if (trip && trip.paidEntries) {
        let paidEntryGroupsMap = {};
        for (const entry of trip.paidEntries) {
            if (entry.description) {
                let id = entry.description.split("~sp|~")[0];
                paidEntryGroupsMap[id] = paidEntryGroupsMap[id] || [];
                paidEntryGroupsMap[id].push(entry);
            } else {
                paidEntryGroupsMap[entry.id] = [entry];
            }
        }
        for (const prop in paidEntryGroupsMap) {
            paidEntryGroups.push(paidEntryGroupsMap[prop])
        }
    }
    return paidEntryGroups;
}