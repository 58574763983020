import React from 'react';
import {ButtonBase, Card, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

function CardButton({onClick, icon, label}) {
    return (<Card onClick={onClick}  >
        <ButtonBase style={{width: "100%", height: "100%"}} component={"div"}>
            <Grid container style={{paddingBottom: 9}}>
                <Grid xs={12} item textAlign={"center"}>
                    <div style={{paddingLeft: "25%", paddingRight: "25%", paddingTop: "15%"}}>
                        {icon}
                    </div>
                </Grid>
                <Grid xs={12} item textAlign={"center"}>
                    <div style={{paddingLeft: "10%", paddingRight: "10%", paddingTop: 0}}>
                        <Typography variant={"caption"}>{label}</Typography>
                    </div>
                </Grid>
            </Grid>
        </ButtonBase>

    </Card>)
}

export default CardButton;