import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Menu
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from "@mui/material/Grid";
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useNavigate} from "react-router-dom";
import {ExpandMore, getTotalExpenseEntryAmount, renderDateFromString} from "../Trip/Utils";
import {renderCurrency} from "../Trip/Currencies";
import TableRow from "@mui/material/TableRow";
import {TableRows} from "@mui/icons-material";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";


function OwnageRow({trip, expenseEntry, row, col , amount }){
    const navigate = useNavigate();
    let text = trip.participants[row];
    if(expenseEntry.payers.length > 1){
        text = <span>{trip.participants[row]} &rarr; {trip.participants[col]}</span>
    }
    return (<ListItem
            style={{paddingTop: 0, paddingBottom: 0,
                background: "rgba(179, 180, 182, 0.12)", paddingLeft: 10, paddingRight: 10, marginRight: 10, marginLeft: 10, marginBottom: 10,
                width: "calc(100% - 20px)",
                borderRadius: 12
            }}
        divider={<Divider/>}
        key={"ownageRow_" + expenseEntry.id + "_" + row + '_' + col}
    >
        <ListItemText
            primary={text}
            secondary={<span>Owes {renderCurrency(amount, trip.currency)} </span> }
        />
        {(expenseEntry.type === 3 || expenseEntry.payers.length > 1) && <Chip
            color="primary" variant="outlined"
            size={'small'}
            label="details"
            onClick={()=>{
                navigate("/" + trip.id + "/expenses/itemizedDetails/" + expenseEntry.id + "/" + row + "/" + col);
            }}
            style={{marginRight: 5}}
        />}
    </ListItem>
    )

}

function ExpenseCard({ownage, expenseEntry, trip, renderPaid}) {

    const navigate = useNavigate();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const isFullyAssigned = () => {
        let fullyAssigned = true;
        expenseEntry.items.forEach(item=> {
          let assignedQuantity = 0;
            item.assignees && item.assignees.forEach(a => assignedQuantity+= a.quantity );
            if(item.quantity > assignedQuantity){
                fullyAssigned = false;
            }
        })
        return fullyAssigned;
    }

    let payerStr = "Unclaimed";
    let paidStr = <span>Paid  {renderCurrency(expenseEntry.amount, trip.currency, expenseEntry.rate, expenseEntry.currency)} </span>;
    if(expenseEntry.payers.length === 1){
        payerStr = trip.participants[expenseEntry.payers[0]];
    } else {
        payerStr = expenseEntry.payers.map((p , i)=> `${trip.participants[p]}`).join(', ');
    }



    return ( expenseEntry.type === 4 ? ( renderPaid && <></>) :
        <Card sx={{maxWidth: 450, width: '100%'}}>
            <CardHeader
                style={{paddingBottom: 0, paddingTop: 5, paddingRight: 16}}
                action={
                 <IconButton>
                     <MoreVertIcon onClick={(event) => {
                         setAnchorEl(event.currentTarget);
                     }}/>
                     <Menu
                         anchorEl={anchorEl}
                         id="account-menu"
                         open={menuOpen}
                         onClose={() => {

                             setAnchorEl(null);
                         }}
                         onClick={() => {

                             setAnchorEl(null);
                         }}
                         PaperProps={{
                             elevation: 3,
                             sx: {
                                 overflow: 'visible',
                                 filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                 mt: 1.5,
                                 '& .MuiAvatar-root': {
                                     width: 32,
                                     height: 32,
                                     ml: -0.5,
                                     mr: 1,
                                 },
                                 '&:before': {
                                     content: '""',
                                     display: 'block',
                                     position: 'absolute',
                                     top: 0,
                                     right: 14,
                                     width: 10,
                                     height: 10,
                                     bgcolor: 'background.paper',
                                     transform: 'translateY(-50%) rotate(45deg)',
                                     zIndex: 0,
                                 },
                             },
                         }}
                         transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                     >
                         <MenuItem key={"expense_card_delete"}  onClick={()=> {
                             navigate("/" + trip.id + "/expenses/delete/" + expenseEntry.id);
                         }}>
                             <ListItemIcon>
                                 <DeleteIcon fontSize="small" />
                             </ListItemIcon>
                             Delete
                         </MenuItem>
                         <MenuItem key={"expense_card_edit"} onClick={()=> {
                             navigate("/" + trip.id + "/expenses/edit/" + expenseEntry.id);
                         }}>
                             <ListItemIcon>
                                 <EditIcon fontSize="small" />
                             </ListItemIcon>
                             Edit
                         </MenuItem>
                     </Menu>
                 </IconButton>
                }

                title={ <span> <Typography style={{ lineHeight :2, fontWeight: "bold", display:"inherit" }}>
                    {expenseEntry.description}
                </Typography>
            <Typography style={{float: "right", color: "#65748B", paddingTop: 6}} variant={"caption"}> {renderDateFromString(expenseEntry.date)}</Typography>
            </span>  }
            />
            <CardContent style={{padding: 0}}><Grid container  justifyContent={"center"}>
                {
                    expenseEntry.type === 3 ?

                        isFullyAssigned() ?

                            <Button color={"success"}
                                    onClick={()=>{
                                        navigate("/" + trip.id + "/expenses/assign/" + expenseEntry.id);
                                    }}
                                                    startIcon={<CheckCircleOutlineIcon/>}
                                                    variant={"outlined"} size={"small"}>Items Assigned
                        </Button>

                            : <>
                                <Grid container>
                                    <Grid xs={12} textAlign={"center"}>
                                        <Button color={"error"}
                                                startIcon={<PersonSearchOutlinedIcon/>}
                                                onClick={()=>{
                                                    navigate("/" + trip.id + "/expenses/assign/" + expenseEntry.id);
                                                }
                                                }
                                                variant={"contained"} size={"small"}>Tap To Assign
                                        </Button>
                                    </Grid>
                                    <Grid xs={12} textAlign={"center"}>
                                        <Typography style={{ color: "#D14343"}} variant={"caption"}>Unclaimed Items Will Be Covered By Payer(s)</Typography>
                                    </Grid>
                                </Grid>
                            </> :

                        expenseEntry.type === 2 ?
                            <Button startIcon={<CheckCircleOutlineIcon/>} color={"success"} size={"small"}>Split Unevenly</Button> :
                        <Button startIcon={<CheckCircleOutlineIcon/>} color={"info"} size={"small"}>Split Evenly</Button>
                }


            </Grid></CardContent>
            <CardActions onClick={handleExpandClick} style={{paddingBottom: 0, paddingTop: 0}} disableSpacing>
                <ListItemText style={{paddingLeft: 8}}
                    primary={payerStr}
                    secondary={paidStr}
                />

                { expenseEntry.payers.length > 0 && <ExpandMore
                    expand={expanded}

                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon/>
                </ExpandMore> }
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{padding: 0}}>
                    <List dense={true} disablePadding={true}>

                        { expenseEntry.payers.length > 1 &&
                            <TableContainer style={{marginTop: 5, marginBottom: 5}}>
                                <Table size="small" aria-label="a dense table">
                                    <TableBody>
                                            { expenseEntry.payers.map( (p, i) => (<TableRow key={"expense_paid_tablerow_" + p + "_" + i}>
                                                <TableCell style={{ color: '#65748B' , paddingTop: 0, paddingBottom: 0}}>
                                                    {trip.participants[p]}
                                                </TableCell>
                                                <TableCell style={{ color: '#65748B' , paddingTop: 0, paddingBottom: 0}} align={"right"}> {
                                                    renderCurrency(expenseEntry.payerAmounts[i],
                                                        trip.currency,
                                                        expenseEntry.rate,
                                                        expenseEntry.currency)}</TableCell>

                                            </TableRow>)) }
                                    </TableBody>
                                </Table>
                            </TableContainer>}

                        {
                            ownage.expenseOwnageMatrix.map( (row, i) => row.map(( col, j) => ownage.expenseOwnageMatrix[i][j] ?
                                <OwnageRow trip={trip} expenseEntry={expenseEntry} row={i} col={j} amount={ownage.expenseOwnageMatrix[i][j]} /> : <></>))
                        }

                    </List>
                </CardContent>
            </Collapse>
        </Card>

    );
}

export default ExpenseCard;