import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    InputAdornment,
    InputLabel,
    Stack, TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import {BEGIN_ID_TIMESTAMP, CurrencySymbol} from "../Trip/Utils";
import {renderCurrency} from "../Trip/Currencies";


function ReceiptItemDialog({openReceiptItemDialog, setOpenReceiptItemDialog, item, receipt, setReceipt, currency}) {
    const [name, setName] = React.useState(item && item.name);
    const [quantity, setQuantity] = React.useState(item && item.quantity);
    const [price, setPrice] = React.useState(item && item.price);
    const updateItem = ()=> {
        let clone = Object.assign({}, receipt);
        if(item){
            let toChange = clone.items.find(i => { return i.id === item.id })
            toChange.name = name;
            toChange.quantity = Number(quantity || 1);
            toChange.price = Number(price);
            toChange.assignees = toChange.assignees || [];
        } else {
            clone.items.push({ assignees: [], name, price: Number(price), quantity: Number(quantity || 1), id : (Date.now() - BEGIN_ID_TIMESTAMP) });
        }

        setReceipt(clone);
        setName(null);
        setQuantity(null);
        setPrice(null);
        setOpenReceiptItemDialog(false);
    }
    const onKeyPress = (e)=>{
        if(e.key === 'Enter' && price && name){
            updateItem();
        }   
    }

    return (
        <Dialog
            maxWidth={'sm'}
            open={openReceiptItemDialog}
            onClose={() => {
                setOpenReceiptItemDialog(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { item ? "Edit Item" : "New Item"}
            </DialogTitle>
            <DialogContent>
                <Stack>

                    <FormControl key={"row_name_item"} sx={{ m: 1, width: '25ch' }} variant="standard" >

                        <TextField
                            margin={"dense"}
                            label={"Item Name"}
                            onKeyPress={onKeyPress}
                            onFocus={event => {
                                event.target.select();
                            }}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard" >

                        <TextField
                            margin={"dense"}
                            label={"Item Total Price"}
                            type="number"
                            value={price}
                            onFocus={event => {
                                event.target.select();
                            }}

                            onKeyPress={onKeyPress}
                            onChange={(e) => {
                                setPrice(e.target.value)
                            }}

                            InputProps={{
                                startAdornment: <InputAdornment position="start"><CurrencySymbol
                                    currency={currency}/></InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="standard" >

                        <TextField
                            margin={"dense"}
                            label={"Item Quantity"}
                            type="number"
                            onKeyPress={onKeyPress}
                            value={quantity}
                            onFocus={event => {
                                event.target.select();
                            }}
                            onChange={(e) => {
                                setQuantity(e.target.value)
                            }}
                        />
                    </FormControl>

                    { price && quantity > 1 && <Alert severity={"info"} variant={"outlined"}>
                        {renderCurrency(price / quantity, currency)} * {quantity} = {renderCurrency(price, currency)}  </Alert>}

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="info" onClick={()=> {setOpenReceiptItemDialog(false) } }> Cancel</Button>
                <Button disabled={!price || !name} color={"success"} onClick={updateItem}> { item ? "Update" : "Create"}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReceiptItemDialog;