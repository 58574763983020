/* src/App.js */
import React, {useEffect, useRef, useState} from 'react'
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {
    addTrackingToUser,
    decorateFromSettingsString, getGroupVersion,
    goBack,
    goForwardTo,
    isMobile, logActivity, SPLITPAL_ERRORS,
    syncUserData
} from "./components/Trip/Utils";
import MobileApp from "./components/MobileApp";
import {onUpdateTripById} from "./graphql/subscriptions";
import {getTrip} from "./graphql/queries";
import TripLoading from "./components/Trip/TripLoading";
import DesktopApp from "./components/DesktopApp";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import {Grid, Snackbar} from "@mui/material";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";
import GroupActionPanel from "./components/GroupActionPanel";


const MainComponent = ({id, user, setUser, group, setGroup, darkMode, setDarkMode}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const prevGroupRef = useRef();

    const [latestSubscriber, setLatestSubscriber] = React.useState(undefined);
    const [error, setError] = React.useState("");
    const [globalMessage, setGlobalMessage] = useState("");

    const tryToResubscribe = async ()=> {
        try {
            const tripData = await API.graphql(graphqlOperation(getTrip, {id}))
            if (!tripData.data.getTrip) {
                delete localStorage.splitpal_most_recent;
                throw {errors: [{message: `trip ${id} not found.`}]}
            }
            console.log('fetched some emergency data...');
            decorateFromSettingsString(tripData.data.getTrip);
            setGroup(tripData.data.getTrip);
            if(latestSubscriber){
                console.log('unsubscribing', latestSubscriber);
                latestSubscriber.unsubscribe();
            }
            subcribeToGraphQl();
        } catch (e) {
            console.log(e)
            setError(e.error?.errors[0]?.message || "Not able to re-establish connection. Please reload the app.");
        }
    }

    const subcribeToGraphQl = (clearErrorOnSuccess) => {
        let newSubscriber = API.graphql(graphqlOperation(onUpdateTripById, {id})).subscribe({
            next: data => {
                decorateFromSettingsString(data.value.data.onUpdateTripById);
                setGroup(data.value.data.onUpdateTripById);
                setError(null);
            },
            error: async (e) => {
                console.error("CRAP ERROR OCCURED", e);
                //setError(e.error?.errors[0]?.message || "Connection interrupted.");
                await tryToResubscribe();
            }
        });
        setError(undefined);
        setLatestSubscriber(newSubscriber);
        return newSubscriber;
    }

    useEffect(()=> {
        if(prevGroupRef.current && group){
            if(prevGroupRef.current.expenseEntries.length < group.expenseEntries.length){
                setGlobalMessage("Expense Entry Added.");
            }

            let oldPaidMap = {};
            prevGroupRef.current.paidEntries?.forEach(e=>oldPaidMap[e.id] = e);
            let newPaidMap = {};
            group.paidEntries?.forEach(e=>newPaidMap[e.id] = e);
            for(let prop in newPaidMap){
                if(!oldPaidMap[prop]){
                    let paidEntry = newPaidMap[prop];
                    setGlobalMessage(`${paidEntry.payer} paid ${paidEntry.payee}!`);
                }
            }
            for(let prop in oldPaidMap){
                if(!newPaidMap[prop]){
                    let paidEntry = oldPaidMap[prop];
                    setGlobalMessage(`${paidEntry.payer} payment to ${paidEntry.payee} undone.`);
                }
            }
        }
        prevGroupRef.current = group;
    }, [group])

    useEffect(() => {

        (async () => {
            try {
                if(group){
                    await addTrackingToUser(user,{ id : group.id, name: group.name, lastAccessed: Date.now()});
                }
                if(id){

                    let tripData = null;
                    if(group && group.id === id){
                        tripData = group;
                    }else {
                        tripData = (await API.graphql(graphqlOperation(getTrip, {id}))).data?.getTrip;
                    }
                    if (!tripData) {
                        throw {errors: [{message: SPLITPAL_ERRORS.GROUP_NOT_FOUND}]}
                    }
                    localStorage.setItem("splitpal_most_recent", tripData.id);
                    let result = decorateFromSettingsString(tripData);
                    if(result !== 0){
                        if(result === -2){
                            throw {
                                errors : [{message: SPLITPAL_ERRORS.GROUP_IS_TOO_OLD}]
                            }
                        } else {
                            throw {
                                errors : [{message: SPLITPAL_ERRORS.GROUP_IS_CORRUPT}]
                            }
                        }

                    }
                    if(tripData.version > getGroupVersion()){
                        throw {
                            errors : [{ message: SPLITPAL_ERRORS.HIGHER_GROUP_VERSION_REQUIRED}]
                        }
                    }
                    setGroup(tripData);
                    let localGroupsMap = JSON.parse(localStorage.getItem("splitpal_groups") || "{}");
                    localGroupsMap[tripData.id] = {
                        id: tripData.id,
                        lastAccessed: Date.now(),
                        name: tripData.name
                    };
                    localStorage.setItem("splitpal_groups", JSON.stringify(localGroupsMap));

                }

            } catch (e) {
                console.log(e)
                setError(e.errors[0].message);
            }

        })();

    }, [id])
    useEffect(() => {
        if(id){
            let subscriber = subcribeToGraphQl();
            return () => subscriber.unsubscribe()
        }

    }, [id]);


    const renderError = (error)=> {

        let showGroupActionPanel = [
            SPLITPAL_ERRORS.GROUP_NOT_FOUND,
            SPLITPAL_ERRORS.GROUP_IS_CORRUPT,
            SPLITPAL_ERRORS.GROUP_IS_TOO_OLD, SPLITPAL_ERRORS.HIGHER_GROUP_VERSION_REQUIRED].includes(error);
        let showHigherVersionRequired = [SPLITPAL_ERRORS.HIGHER_GROUP_VERSION_REQUIRED].includes(error);
        let showReload = [SPLITPAL_ERRORS.NOT_ABLE_TO_REESTABLISH_CONNECTION].includes(error);
        return (
            <Container style={{marginTop: 85}}>
                <Grid container>
                    <Grid item xs={12} style={{paddingTop: 10}} textAlign={"center"}>
                        <Alert severity={"warning"}>{error}</Alert>
                    </Grid>
                    { showHigherVersionRequired && <Grid item xs={12} style={{paddingTop: 10}} textAlign={"center"}>
                        <Button variant={"contained"} onClick={async ()=>{
                            if(window.cordova.platformId === 'android') {
                                window.cordova.InAppBrowser.open("market://details?id=io.cordova.splitpal", "_system");
                            } else {
                                window.cordova.InAppBrowser.open("https://apps.apple.com/us/app/splitpal/id6443640890", "_system");
                            }
                        }}>Update SplitPal</Button>
                    </Grid> }
                    { showGroupActionPanel && <GroupActionPanel user={user} /> }
                    {showReload && <Grid item xs={12} style={{paddingTop: 20}} textAlign={"center"}>
                        <Button variant={"contained"} onClick={async ()=>{
                            await tryToResubscribe();
                        }} startIcon={<RefreshIcon/>}>Refresh</Button>
                    </Grid> }
                </Grid>
            </Container>
        )
    }

    const loading = id && (!error && !group);
    return (
        loading ? <TripLoading/>  : error ? renderError(error)  : isMobile() ?

<>            <Snackbar
    anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
    }}
    open={!!globalMessage}
    autoHideDuration={1500}
    onClose={()=> {setGlobalMessage("")} }
    message={globalMessage}
>
</Snackbar>

                    <MobileApp
                        darkMode={darkMode} setDarkMode={setDarkMode}
                        setUser={setUser}
                        user={user}
                        setGroup={setGroup}
                        group={group}/>
</>
            :                     <DesktopApp
                darkMode={darkMode} setDarkMode={setDarkMode}
                setUser={setUser}
                user={user}
                setGroup={setGroup}
                group={group}/>


    )
}


const App = ({user, setUser, group, setGroup, darkMode, setDarkMode}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [deeplinkLoading, setDeeplinkLoading] = useState(false);
    let groupId = params.group;
    const checkGroupId = ()=> {
        if(!groupId){
            if(localStorage.splitpal_most_recent) {
                groupId = localStorage.splitpal_most_recent;
            } else {
                let localGroups = Object.keys(JSON.parse(localStorage.getItem("splitpal_groups") || "{}"));
                if(localGroups.length > 0){
                    groupId = localGroups[0];
                }
            }
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            let deeplinkGroupId = localStorage.getItem("splitpal_deeplink_path");
            let deeplinkExpenseId = localStorage.getItem("splitpal_deeplink_expense");
            if(deeplinkGroupId){
                localStorage.removeItem("splitpal_deeplink_path");
                localStorage.removeItem("splitpal_deeplink_expense");
                (async()=> {
                    try {

                        console.log('group id from universal link detected.', deeplinkGroupId, deeplinkExpenseId);
                        setDeeplinkLoading(true);
                        console.log('deeplink: getting group id', deeplinkGroupId);
                        let tripData = await API.graphql(graphqlOperation(getTrip, {id: deeplinkGroupId}));

                        decorateFromSettingsString(tripData.data.getTrip);
                        setGroup(tripData.data.getTrip);
                        console.log('deeplink: group is set ', deeplinkGroupId);

                        let newGroup = {
                            id: deeplinkGroupId,
                            name: tripData.data.getTrip.name,
                            lastAccessed: Date.now()
                        }

                        try{
                            let currentUser = await Auth.currentAuthenticatedUser();
                            console.log('currentUser:' , currentUser);
                            await addTrackingToUser(currentUser, newGroup);
                        }catch(e){
                            console.log('unable to update user', e);
                        }


                        let localGroupsMap = JSON.parse(localStorage.getItem("splitpal_groups") || "{}");
                        localGroupsMap[deeplinkGroupId] = newGroup;
                        localStorage.setItem("splitpal_groups", JSON.stringify(localGroupsMap));
                        localStorage.setItem("splitpal_most_recent", deeplinkGroupId);
                        checkGroupId();
                        if(deeplinkExpenseId){
                            goForwardTo(location, navigate, '/' + deeplinkGroupId + '/expenses/assign/'+ deeplinkExpenseId);
                        } else {
                            goForwardTo(location, navigate, '/' + deeplinkGroupId, true);
                        }

                    } catch(e){
                        console.error(e)
                    } finally {
                        setDeeplinkLoading(false);
                    }
                })();
            }

        }, 100);

        return () => clearInterval(interval);
    }, []);

    useEffect(()=> {
        checkGroupId();
    }, [user])

    checkGroupId();

    return deeplinkLoading ? <TripLoading/> : <MainComponent  darkMode={darkMode} setDarkMode={setDarkMode} user={user} group={group} setGroup={setGroup} setUser={setUser} id={groupId}/>
}

export default App