import * as React from "react"

const SvgComponent = ({darkMode}) =>{
    let color1 = darkMode ? '#fff' : '#B3B4B6';
    let color2 = darkMode ? '#fff' : '#327DD9';

    return(<svg
        width={43}
        height={44}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#newGroupClip)">
            <path
                d="M15.37 24.696a3.362 3.362 0 0 0 3.358-3.358 3.362 3.362 0 0 0-3.359-3.359 3.362 3.362 0 0 0-3.358 3.359 3.362 3.362 0 0 0 3.358 3.358Zm0-5.877a2.522 2.522 0 0 1 2.518 2.519 2.522 2.522 0 0 1-2.519 2.519 2.522 2.522 0 0 1-2.519-2.52 2.522 2.522 0 0 1 2.52-2.518ZM15.79 31.836a.42.42 0 1 1-.84 0 .42.42 0 0 1 .84 0Z"
                fill={color2}
            />
            <path
                d="M11.17 32.256h2.31a.42.42 0 0 0 0-.84h-1.89v-1.26a3.784 3.784 0 0 1 3.78-3.779 3.777 3.777 0 0 1 3.778 3.78v1.259h-1.89a.42.42 0 1 0 0 .84h9.03a.42.42 0 0 0 .419-.42v-1.26c0-2.315-1.884-4.199-4.2-4.199a4.164 4.164 0 0 0-3.158 1.433 4.611 4.611 0 0 0-3.98-2.273 4.624 4.624 0 0 0-4.619 4.62v1.679c0 .232.188.42.42.42Zm11.338-5.04a3.363 3.363 0 0 1 3.36 3.36v.84h-5.88v-1.26c0-.51-.082-1.009-.244-1.486a3.335 3.335 0 0 1 2.764-1.453Z"
                fill={color2}
            />
            <path
                d="M25 12c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7Zm0 12.6a5.606 5.606 0 0 1-5.6-5.6c0-3.088 2.512-5.6 5.6-5.6 3.088 0 5.6 2.512 5.6 5.6 0 3.088-2.512 5.6-5.6 5.6Z"
                fill={color2}
            />
            <path
                d="M27.25 18.25h-1.5v-1.5a.75.75 0 1 0-1.5 0v1.5h-1.5a.75.75 0 1 0 0 1.5h1.5v1.5a.75.75 0 1 0 1.5 0v-1.5h1.5a.75.75 0 1 0 0-1.5Z"
                fill={color2}
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.68 9.26v26.338A6.654 6.654 0 0 0 8.33 42.25H34.67a6.654 6.654 0 0 0 6.651-6.652V9.261a6.654 6.654 0 0 0-6.651-6.652H8.33A6.654 6.654 0 0 0 1.68 9.261Zm32.989-4.635a4.639 4.639 0 0 1 4.636 4.636v26.337a4.64 4.64 0 0 1-4.636 4.636H8.33a4.639 4.639 0 0 1-4.636-4.636V9.261a4.639 4.639 0 0 1 4.636-4.636H34.67Z"
            fill={color1}
            stroke="#fff"
        />
        <defs>
            <clipPath id="newGroupClip">
                <path
                    fill="#fff"
                    transform="translate(10.75 11.68)"
                    d="M0 0h21.5v21.5H0z"
                />
            </clipPath>
        </defs>
    </svg>
) }

export default SvgComponent
