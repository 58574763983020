import * as React from "react"

const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={64}
        height={64}
        viewBox="0 0 256 256"
        {...props}
    >
        <path
            d="M256 28C163.782 28 80.645 83.55 45.355 168.748c-35.29 85.198-15.784 183.265 49.424 248.473 89.04 89.04 233.402 89.04 322.442 0 89.04-89.04 89.04-233.402 0-322.442A226.51 226.51 0 0 0 256 28zm0 20c114.691 0 208 93.309 208 208a207.435 207.435 0 0 1-64.748 150.67v-2.957c0-78.988-64.262-143.25-143.25-143.25s-143.25 64.262-143.25 143.25v2.96A207.435 207.435 0 0 1 48 256c0-114.691 93.309-208 208-208zm.455 47.146c-42.696 0-77.307 34.613-77.307 77.309s34.612 77.307 77.307 77.307c42.696 0 77.308-34.611 77.309-77.307-.05-42.675-34.633-77.258-77.309-77.309zm-2.383 20.051a57.308 57.308 0 0 1 59.692 57.258c-.036 31.636-25.673 57.273-57.309 57.309-31.185 0-56.646-24.938-57.295-56.117-.648-31.178 23.754-57.153 54.912-58.45zm5.528 165.317c66.64 1.946 119.652 56.529 119.652 123.197v19.73c-73.269 54.079-173.231 54.079-246.5 0v-19.73A123.25 123.25 0 0 1 259.6 280.514z"
            transform="scale(.5)"
            fill="#327dd9"
            strokeMiterlimit={10}
            fontFamily="none"
            fontWeight="none"
            fontSize="none"
            textAnchor="none"
            style={{
                mixBlendMode: "normal",
            }}
        />
    </svg>
)

export default SvgComponent
