import React from 'react';
import {Grid, InputAdornment, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Login} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import {Auth} from "aws-amplify";
import {logActivity} from "../Trip/Utils";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

function SignInWithEmail({setCognitoSignInForm}) {

    const [cognitoLoginUsername, setCognitoLoginUsername] = React.useState('');
    const [cognitoLoginPassword, setCognitoLoginPassword] = React.useState('');
    const [cognitoSignupUsername, setCognitoSignupUsername] = React.useState('');
    const [cognitoSignupPassword, setCognitoSignupPassword] = React.useState('');
    const [cognitoSignupRepeatPassword, setCognitoSignupRepeatPassword] = React.useState('');
    const [signupLoading, setSignupLoading] = React.useState(false);
    const [signupError, setSignupError] = React.useState('');
    const [loginLoading, setLoginLoading] = React.useState(false);
    const [loginError, setLoginError] = React.useState('');
    const [forgotPassword, setForgotPassword] = React.useState(false);
    const [forgotPasswordLoading, setForgotPasswordLoading] = React.useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = React.useState('');
    const [resetPassword, setResetPassword] = React.useState('');
    const [resetConfirmPassword, setResetConfirmPassword] = React.useState('');
    const [resetPasswordCode, setResetPasswordCode] = React.useState('');
    const [forgotPasswordError, setForgotPasswordError] = React.useState('');
    const [resetPasswordError, setResetPasswordError] = React.useState('');
    const [resetPasswordLoading, setResetPasswordLoading] = React.useState(false);
    const [showResetForm, setShowResetForm] = React.useState(false);
    const [forgotPasswordMessage, setForgotPasswordMessage] = React.useState('Enter your email to receive password reset code.');
    const [resetSuccessful, setResetSuccessful] = React.useState(false);

    const forgotPasswordValidate = () => {
        if (!forgotPasswordEmail) {
            return false;
        } else {
            return true;
        }
    }
    const validate = () => {

        if (!cognitoSignupRepeatPassword || !cognitoSignupPassword || !cognitoSignupUsername) {
            return false;
        } else if (cognitoSignupRepeatPassword !== cognitoSignupPassword) {
            return false;
        } else if (cognitoSignupPassword.length < 8) {
            return false
        } else if (cognitoSignupUsername.indexOf("@") <= 0) {
            return false;
        } else {
            return true;
        }

    }

    return (
        <> {!forgotPassword ? <>
            <Grid item xs={12} textAlign={"center"} style={{paddingTop: 0}}>
                Log In With Email
            </Grid>
            {
                resetSuccessful &&
                <Grid item xs={12} textAlign={"center"} style={{paddingTop: 0}}>
                    <Alert severity={"success"}>Your password has been reset.</Alert>
                </Grid>
            }
            <Grid item xs={12} textAlign={"center"}>
                <TextField fullWidth={true}
                           error={!!loginError}
                           helperText={loginError}
                           type={"email"}
                           value={cognitoLoginUsername}
                           onChange={(e) => {

                               setCognitoLoginUsername(e.target.value)
                           }}
                           InputProps={{
                               startAdornment: <InputAdornment position="start"></InputAdornment>,
                           }}
                           variant={"outlined"} label={"Email"} size={"small"} margin="dense"/>
                <TextField fullWidth={true}
                           type={"password"}
                           value={cognitoLoginPassword}
                           onChange={(e) => {
                               setCognitoLoginPassword(e.target.value)
                           }}

                           InputProps={{
                               startAdornment: <InputAdornment position="start"></InputAdornment>,
                           }}
                           variant={"outlined"} label={"Password"} size={"small"} margin="dense"/>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 0}} textAlign={"center"}>
                <Link onClick={() => {
                    setForgotPassword(true);
                }
                }><Typography variant={"caption"}>Forgot Your Password?</Typography></Link>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 10}} textAlign={"center"}><LoadingButton
                loading={loginLoading}
                onClick={async () => {
                    setLoginError('');
                    setLoginLoading(true);
                    try {
                        let resp = await Auth.signIn({
                            username: cognitoLoginUsername,
                            password: cognitoLoginPassword,
                            attributes: {
                                email: cognitoLoginUsername
                            },
                        });

                        logActivity("SignInWithEmailSuccess", null, cognitoLoginUsername );
                        if(setCognitoSignInForm) {
                            setCognitoSignInForm(false);
                        }
                    } catch (e) {
                        logActivity("SignInWithEmailFail", null, cognitoLoginUsername );
                        console.log(e);
                        setLoginError(e.message);
                    } finally {
                        setLoginLoading(false);
                    }


                }}
                disabled={!cognitoLoginUsername || !cognitoLoginPassword} startIcon={<Login/>}>Log In</LoadingButton>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
                <Divider>Or Sign Up Below</Divider>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
                <TextField fullWidth={true}
                           error={!!signupError}
                           helperText={signupError}

                           autoComplete={"off"}
                           value={cognitoSignupUsername}
                           onChange={(e) => {
                               setCognitoSignupUsername(e.target.value)
                           }}

                           InputProps={{

                               startAdornment: <InputAdornment position="start"></InputAdornment>,
                           }}
                           variant={"outlined"} label={"Email"} size={"small"} margin="dense"/>
                <TextField fullWidth={true}
                           autoComplete={"off"}
                           type={"password"}
                           value={cognitoSignupPassword}
                           onChange={(e) => {
                               setCognitoSignupPassword(e.target.value)
                           }}

                           InputProps={{
                               startAdornment: <InputAdornment position="start"></InputAdornment>,
                           }}
                           variant={"outlined"} label={"Password"} size={"small"} margin="dense"/>
                <TextField fullWidth={true}
                           autoComplete={"off"}
                           type={"password"}
                           value={cognitoSignupRepeatPassword}
                           onChange={(e) => {
                               setCognitoSignupRepeatPassword(e.target.value)
                           }}

                           InputProps={{
                               startAdornment: <InputAdornment position="start"></InputAdornment>,
                           }}
                           variant={"outlined"} label={"Repeat Password"} size={"small"} margin="dense"/>
            </Grid>

            <Grid item xs={12} style={{paddingTop: 0}} textAlign={"center"}>
                <LoadingButton loading={signupLoading} onClick={async () => {
                    setSignupError('');
                    setSignupLoading(true);
                    logActivity("SignUpWithEmailAttempt", null, null);
                    try {
                        let resp = await Auth.signUp({
                            username: cognitoSignupUsername,
                            password: cognitoSignupPassword,
                            attributes: {
                                email: cognitoSignupUsername
                            },
                            autoSignIn: {
                                enabled: true
                            }
                        });
                        console.log('signed up!!!', resp);
                        logActivity("SignUpWithEmailSuccess", null, null);
                        if(setCognitoSignInForm) {
                            setCognitoSignInForm(false);
                        }
                    } catch (e) {
                        logActivity("SignUpWithEmailError", null, null);
                        console.log(e);
                        setSignupError(e.message);
                    } finally {
                        setSignupLoading(false);
                    }


                }} disabled={!validate()}>Sign Up</LoadingButton>
            </Grid> </> : <>
            <Grid item xs={12} textAlign={"center"}>
                <Typography style={{maxWidth: 200}} variant={"caption"}>{forgotPasswordMessage}</Typography>

            </Grid>

            {!showResetForm && <Grid item xs={12} textAlign={"center"}>
                <TextField fullWidth={true}
                           error={!!forgotPasswordError}
                           helperText={forgotPasswordError}

                           autoComplete={"off"}
                           value={forgotPasswordEmail}
                           onChange={(e) => {
                               setForgotPasswordEmail(e.target.value)
                           }}

                           InputProps={{
                               startAdornment: <InputAdornment position="start"></InputAdornment>,
                           }}
                           variant={"outlined"} label={"Email"} size={"small"} margin="dense"/>
            </Grid>}
            {!showResetForm && <Grid item xs={12} textAlign={"center"}>
                <LoadingButton disabled={!forgotPasswordEmail || forgotPasswordEmail.indexOf("@") <= 0}
                               loading={forgotPasswordLoading} variant={"contained"} onClick={async () => {
                    setForgotPasswordLoading(true);
                    try {
                        setForgotPasswordError('');
                        let token = await Auth.forgotPassword(forgotPasswordEmail);

                        setForgotPasswordMessage(`Email containing reset code has been sent to "${forgotPasswordEmail}."`);
                        setShowResetForm(true);
                    } catch (e) {
                        console.log('error occured', e);
                        setForgotPasswordError(e.message);
                    } finally {
                        setForgotPasswordLoading(false);
                    }
                }}>Get Reset Code</LoadingButton>
            </Grid>}
            {
                showResetForm && <>
                <Grid item textAlign={"center"} xs={12}>
                    <TextField fullWidth={true}
                               error={!!resetPasswordError}
                               helperText={resetPasswordError}

                               autoComplete={"off"}
                               value={resetPasswordCode}
                               onChange={(e) => {
                                   setResetPasswordCode(e.target.value)
                               }}

                               InputProps={{
                                   startAdornment: <InputAdornment position="start"></InputAdornment>,
                               }}
                               variant={"outlined"} label={"6-digit reset code"} size={"small"} margin="dense"/>
                    <Divider> New Password</Divider>
                    <TextField fullWidth={true}
                               type={"password"}
                               autoComplete={"off"}
                               value={resetPassword}
                               onChange={(e) => {
                                   setResetPassword(e.target.value)
                               }}

                               InputProps={{
                                   startAdornment: <InputAdornment position="start"></InputAdornment>,
                               }}
                               variant={"outlined"} label={"New Password"} size={"small"} margin="dense"/>

                    <TextField fullWidth={true}
                               type={"password"}
                               autoComplete={"off"}
                               value={resetConfirmPassword}
                               onChange={(e) => {
                                   setResetConfirmPassword(e.target.value)
                               }}
                               InputProps={{
                                   startAdornment: <InputAdornment position="start"></InputAdornment>,
                               }}
                               variant={"outlined"} label={"Confirm New Password"} size={"small"} margin="dense"/>
                </Grid>
                    <Grid item textAlign={"center"} xs={12}>
                        <LoadingButton disabled={(!resetPassword || !resetConfirmPassword || !resetPasswordCode || resetPasswordCode.length !== 6) || (resetPassword !== resetConfirmPassword) }
                                       loading={resetPasswordLoading} variant={"contained"} onClick={async ()=>{
                            setResetPasswordLoading(true);
                            try {
                                let resp = await Auth.forgotPasswordSubmit(forgotPasswordEmail, resetPasswordCode, resetPassword);
                                setShowResetForm(false);
                                setForgotPasswordMessage('Enter your email to receive password reset code.');
                                setForgotPassword(false);
                                setResetSuccessful(true);
                                setCognitoLoginPassword('');
                                setResetPasswordCode('');
                                setResetPassword('');
                                setResetConfirmPassword('');
                                setLoginError('');
                                setSignupError('');
                            }catch(e){
                                console.log('error while resetting password', e);
                                setResetPasswordError(e.message);
                            } finally {
                                setResetPasswordLoading(false);
                            }
                        } }>Reset Password</LoadingButton>
                    </Grid>
                </>
            }

        </>

        }

        </>
    );
}

export default SignInWithEmail;