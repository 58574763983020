import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {
    addTrackingToUser, getEmailFromUser,
    getMaterialDesignColorFromString,
    goBack,
    goForwardTo,
    isMobile,
    syncUserData
} from "../Trip/Utils";
import {
    Card,
    CardActions,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, ListItem, Slide
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import GroupsIcon from "@mui/icons-material/Groups";
import LoadingButton from "@mui/lab/LoadingButton";
import {API, graphqlOperation} from "aws-amplify";
import {getUser} from "../../graphql/queries";
import {updateUser} from "../../graphql/mutations";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SingleFieldUpdateDialog from "./SingleFieldUpdateDialog";
import SwitchGroupSvg from "../SvgImages/Home/SwitchGroupSvg";

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CloseDialogButton from "./CloseDialogButton";
function timeSince(date) {

    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}


function GroupCard({user, group, groups, setGroups, setGroup}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [untracking, setUntracking] = useState(false);
    const [untrackOpen, setUntrackOpen] = useState(false);

    const navigateToGroup = ()=>{
        localStorage.splitpal_most_recent = group.id;
        addTrackingToUser(user, group);
        //goBack(location, navigate, '/' + group.id);
        goForwardTo(location, navigate, '/' + group.id, true);
    }


    return (
        <Card elevation={0} style={{marginBottom: 10}}>
            <CardHeader onClick={navigateToGroup}

                style={{paddingBottom: 0, paddingTop: 5}}
                title={ <Grid container><Grid item xs={12}>
                    <Typography variant={"overline"}> {group.name}</Typography></Grid></Grid> }
            />
            <CardActions>
                <ListItem style={{paddingTop: 0, paddingBottom: 0,
                    background: "rgba(179, 180, 182, 0.12)", paddingLeft: 10, paddingRight: 10, marginRight: 10, marginLeft: 10, marginBottom: 10,
                    width: "calc(100% - 20px)",
                    borderRadius: 12
                }}
                          key={"SelectGrop" + "_" + group.name}
                >
                    <Button style={{ fontSize: 13}} color={"inherit"} onClick={navigateToGroup} startIcon={<SwitchGroupSvg/>} >
                        Go to group</Button>
                    <LoadingButton style={{ fontSize: 13}} color={"error"} loading={untracking} onClick={async (e)=>{

                        setUntrackOpen(true);

                    } } startIcon={<ClearOutlinedIcon/>} >Leave Group</LoadingButton>
                </ListItem>

            </CardActions>
            <SingleFieldUpdateDialog
                noField={true}
                fullScreen={false}
                onUpdate={async ()=>{
                    setUntracking(true);
                    let localData = JSON.parse(localStorage.getItem("splitpal_groups") || "{}");
                    delete localData[group.id];
                    localStorage.setItem("splitpal_groups", JSON.stringify(localData));

                    if(user){

                        let response = await API.graphql(graphqlOperation(getUser, {
                            id: getEmailFromUser(user)
                        }));
                        if(response.data.getUser?.groups){
                            let userGroups = JSON.parse(response.data.getUser.groups);
                            delete userGroups[group.id];
                            await API.graphql(graphqlOperation(updateUser, {
                                input : {
                                    id: getEmailFromUser(user),
                                    groups: JSON.stringify(userGroups)
                                }
                            }));
                        }

                    }
                    let mostRecent = localStorage['splitpal_most_recent'];

                    setGroups(groups.filter(g=> g.id !== group.id ));
                    setUntrackOpen(false);
                    setUntracking(false);
                    if(mostRecent === group.id){
                        let otherGroups = Object.keys(localData || {});
                        if(otherGroups.length === 0){
                            delete localStorage['splitpal_most_recent'];
                            delete localStorage['splitpal_groups'];
                            setGroup(null);
                        } else {
                            localStorage['splitpal_most_recent'] = otherGroups[0];
                        }
                        goBack(location, navigate, '/');
                    }
                } }
                loading={untracking}
                updateButtonText={"Yes, I'm sure"}
                dialogDescription={<Grid container>
                    <Grid textAlign={"center"} item xs={12}>Do you want to untrack {group.name}?
                    </Grid>{
                        group.id === localStorage['splitpal_most_recent'] ? <Grid item xs={12} textAlign={"center"} style={{color: 'red'}} >This is your current group</Grid> :<></>
                }
                </Grid>}
                open={untrackOpen}
                onCancel={()=>{ setUntrackOpen(false)} }
                dialogTitle={"Are you sure?"}
                />

        </Card>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SwitchGroupsDialog({user, setGroup}) {

    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=> {

        (async ()=> {
            if(user){
                setLoading(true);
                await syncUserData(getEmailFromUser(user));
                setLoading(false);
            }
            let localGroupsMap = JSON.parse(localStorage.getItem("splitpal_groups") || "{}");
            let localGroups = [];
            for(let prop in localGroupsMap){
                localGroups.push(localGroupsMap[prop]);
            }
            setGroups(localGroups.sort( (a,b)=> { return b.lastAccessed - a.lastAccessed } ));
        })();

    }, [user])

    const navigate = useNavigate();
    const location = useLocation();

    const onClose = () => {
        goBack(location, navigate, '/');
    }

    return (


        <Dialog
            fullScreen={isMobile()}
            fullWidth={false}
            open={true}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Your Groups"}<CloseDialogButton onClick={onClose}/>
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                { !loading ? <Container >
                        {
                            groups && groups.map(g => g && <GroupCard groups={groups} user={user} setGroup={setGroup} setGroups={setGroups} group={g} />
                            )

                        }
                </Container> : <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <CircularProgress/>
                    </Grid>
                </Grid>}
            </DialogContent>
        </Dialog>

    );
}

export default SwitchGroupsDialog;