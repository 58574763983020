import * as React from "react"

const SvgComponent = ({ fill}) => (
    <svg
        width={20}
        height={19}
        fill="none"
    >
        <circle cx={10} cy={9.5} r={9.5} fill={fill} />
    </svg>
)

export default SvgComponent
