import React, {useEffect} from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    getEmailFromUser,
    getUserProfileData,
    goBack,
    isMobile,
    logActivity,
    updateUserProfileData
} from "../Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import CountrySelector from "../Trip/CountrySelector";
import FormControl from "@mui/material/FormControl";
import CashAppSvg from "../SvgImages/CashAppSvg";
import VenmoLogoSvg from "../SvgImages/VenmoLogoSvg";
import {useTheme} from "@mui/material/styles";
import CloseDialogButton from "./CloseDialogButton";

function PreferredPaymentDialog({group, user}) {


    const navigate = useNavigate();
    const location = useLocation();
    const [country, setCountry] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [loadingData, setLoadingData] = React.useState(true);
    const params = useParams();
    const [simpleText, setSimpleText] = React.useState('');
    const [useSimpleText, setUseSimpleText] = React.useState(false);
    const [cashAppUsername, setCashAppUsername] = React.useState('');
    const [useCashApp, setUseCashApp] = React.useState(false);
    const [venmoUsername, setVenmoUsername] = React.useState('');
    const [useVenmo, setUseVenmo] = React.useState(false);

    const theme = useTheme();



    useEffect(() => {
        (async () => {

            if (user) {
                setLoadingData(true);

                try {


                    let resp = await getUserProfileData(getEmailFromUser(user));

                    let data = JSON.parse(resp.data.getPreSignedUrl);
                    if (!data) {

                    } else {
                        setCountry(data.country);

                        setUseCashApp(data.useCashApp);
                        setUseSimpleText(data.useSimpleText);
                        setUseVenmo(data.useVenmo);

                        setSimpleText(data.simpleText);
                        setVenmoUsername(data.venmoUsername);
                        setCashAppUsername(data.cashAppUsername);
                    }

                } catch (e) {

                } finally {
                    setLoadingData(false);
                }
            }

        })();

    }, [user]);

    let goBackUrl = '/';
    if (params.group) {
        goBackUrl += params.group + '/';
    }
    if (params.view) {
        goBackUrl += params.view;
    }


    const closeDialog = () => {
        goBack(location, navigate, goBackUrl);
    }


    return (
        <Dialog
            fullScreen={isMobile()}
            fullWidth={true}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                Pay Back Methods <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            {loadingData ? <DialogContent><Grid container>
                <Grid item xs={12} textAlign={"center"}>
                    <CircularProgress/>
                </Grid>
            </Grid></DialogContent> : <DialogContent>
                {!user ? <Grid container>Must Be Logged In</Grid> : <Grid container spacing={2}>
                    <Grid item xs={12}><CountrySelector country={country} setCountry={setCountry}
                                                        fullWidth={true}/></Grid>

                    <Grid item textAlign={"center"} xs={12}>
                        Available Methods:
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent style={{paddingTop: 2, padding: 16}}>
                                <FormControlLabel
                                    control={<Switch onChange={(event)=>{ setUseSimpleText(event.target.checked) } } checked={useSimpleText}
                                                    value="simpleText"/>} label="Simple Note"/>

                                <FormControl margin="normal" variant="standard" fullWidth={true}>
                                    <TextField disabled={!useSimpleText}
                                        value={simpleText}
                                        onChange={(event) => {
                                            setSimpleText(event.target.value);
                                        }}
                                        placeholder="Describe how you would like to be paid back here, so people in the group know, i.e: Pay me on Zelle via 571-266-1687"
                                        multiline
                                        rows={4}
                                    />
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>

                    {country === 'US' && <Grid item xs={12}>
                        <Card>
                            <CardContent style={{paddingTop: 6, padding: 16}}>
                                <FormControlLabel
                                    control={<Switch onChange={(event)=>{ setUseCashApp(event.target.checked) } } checked={useCashApp}
                                                    value="cashApp"/>} label={<Button style={{ color: theme.palette.text.primary, fontWeight: 'bold', padding: 0}} startIcon={<CashAppSvg/>}>Cash App</Button>}/>
                                <FormControl margin="normal" variant="standard" fullWidth={true}>
                                    <TextField
                                        disabled={!useCashApp}

                                        value={cashAppUsername}
                                        onChange={(event) => {
                                            setCashAppUsername(event.target.value);
                                        }}
                                        placeholder="Your CashApp username"

                                    />
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>}
                    {country === 'US' && <Grid item xs={12}>
                        <Card>
                            <CardContent style={{paddingTop: 6, padding: 16}}>
                                <FormControlLabel
                                    control={<Switch onChange={(event)=>{ setUseVenmo(event.target.checked) } } checked={useVenmo}
                                                    value="venmo"/>} label={ <Button style={{ color: theme.palette.text.primary, fontWeight: 'bold', padding: 0}} startIcon={<VenmoLogoSvg/>}>Venmo</Button>}/>
                                <FormControl margin="normal" variant="standard" fullWidth={true}>
                                    <TextField
                                        disabled={!useVenmo}
                                        value={venmoUsername}
                                        onChange={(event) => {
                                            setVenmoUsername(event.target.value);
                                        }}
                                        placeholder="Your Venmo username"

                                    />
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>}

                </Grid>}
            </DialogContent>}
            <DialogActions>
                <Button onClick={closeDialog} color={"info"}> Cancel</Button>
                <LoadingButton disabled={loadingData} loading={loading} onClick={async () => {
                    setLoading(true);
                    try {
                        await updateUserProfileData({
                            country,
                            venmoUsername,
                            cashAppUsername,
                            useVenmo,
                            useCashApp,
                            useSimpleText,
                            simpleText

                        });
                        logActivity('updatedUserProfileData', { ...group, data : {country,
                                venmoUsername,
                                cashAppUsername,
                                useVenmo,
                                useCashApp,
                                useSimpleText,
                                simpleText} }, user);
                        closeDialog();
                    } catch (e) {
                        console.error(e);
                        setError(e);
                    } finally {
                        setLoading(false);
                    }
                }} color={"primary"}>Update</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default PreferredPaymentDialog;