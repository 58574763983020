import React, {useState} from 'react';
import {formatDate} from "./Utils";
import {TextField} from "@mui/material";

function ExpenseDateInput({setDate, defaultValue}) {
    const [error, setError] = useState(false);
    return (
        <TextField
            id="date"
            label="Date"
            type="date"
            error={error}
            helperText={error && "Should be between 01/01/1990 and today."}
            defaultValue={defaultValue || formatDate(Date.now())}
            onChange={(e)=>{

                let d = (new Date(e.target.value)).getTime();
                let lowerBound = (new Date("1990-01-01")).getTime();
                let err = d > Date.now() || d < lowerBound;
                setError(err);
                setDate(err ?  null : e.target.value);
            } }
            sx={{ width: 220 }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}

export default ExpenseDateInput;