/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTripById = /* GraphQL */ `
  subscription OnUpdateTripById($id: ID!) {
    onUpdateTripById(id: $id) {
      id
      currency
      name
      settings
      participants
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrip = /* GraphQL */ `
  subscription OnCreateTrip {
    onCreateTrip {
      id
      currency
      name
      settings
      participants
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrip = /* GraphQL */ `
  subscription OnUpdateTrip {
    onUpdateTrip {
      id
      currency
      name
      settings
      participants
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrip = /* GraphQL */ `
  subscription OnDeleteTrip {
    onDeleteTrip {
      id
      currency
      name
      settings
      participants
      expenseEntries {
        id
        itemized
        description
        payer
        participants
        otherCharges
        amount
        currency
        date
        rate
        tax
        discount
        tip
        items {
          id
          quantity
          name
          price
          assignees {
            name
            quantity
          }
        }
      }
      paidEntries {
        id
        description
        payer
        payee
        amount
        comment
      }
      createdAt
      updatedAt
    }
  }
`;
