//External imports

import Button from "@mui/material/Button";
import GoogleIconSvg from "./SvgImages/GoogleIconSvg";
import {API, Auth, graphqlOperation} from "aws-amplify";
import React, {useState} from 'react';
import jwt_decode from "jwt-decode";
import {logActivity} from "./Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTheme} from "@mui/material/styles";
import {getPreSignedUrl} from "../graphql/queries";
import {Backdrop, CircularProgress, Grid} from "@mui/material";
import {GoogleLogin} from "@react-oauth/google";


const SignInWithGoogle = ({outlined, setUser}) => {

    const [error, setError] = useState("");
    const [fingerPrint, setFingerPrint] = useState("");
    const [loading, setLoading] = React.useState(false);
    const theme = useTheme();

    const login = async (token) => {
        try {

            const decoded = jwt_decode(token);

            let response = await API.graphql(graphqlOperation(getPreSignedUrl, {
                    id: "splitpal-signin-google~|~" + token
                })
            );
            let tokens = response.data.getPreSignedUrl.split("~|~");

            await Auth.signIn({
                username: tokens[0],
                password: tokens[1],
                attributes: {
                    email: tokens[0]
                },
            });
            logActivity("SignInWithGoogleSuccess", {data: tokens[0]}, null);

        } catch (e) {
            logActivity("SignInWithGmailError", null, null);
            console.log(
                'Auth.federatedSignIn error', e
            )
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            {!window.cordova && <GoogleLogin
                width={"100%"}
                onSuccess={credentialResponse => {
                    login(credentialResponse.credential);
                }}
                onError={() => {
                    logActivity("SignInWithGmailError", null, null);
                    setError(error);
                    console.log('oh no error', error);
                }}
            />}
            {window.cordova && <LoadingButton style={{color: theme.palette.text.primary}} loading={loading}
                                              startIcon={<GoogleIconSvg/>}
                                              onClick={() => {
                                                  setLoading(true);
                                                  logActivity("SignInWithGmailAttempt", null, null);
                                                  let options = {
                                                      webClientId: "984890731200-61qvgl3d30opu293bgaqa60ir1jahmj0.apps.googleusercontent.com"
                                                  }
                                                  if (window.cordova.platformId === 'ios') {
                                                      options['offline'] = true;
                                                  }
                                                  window.plugins.googleplus.login(options, async (success) => {
                                                      console.log('oh yeah! success', success);
                                                      login(success.idToken);
                                                  }, (error) => {
                                                      setLoading(false);
                                                      logActivity("SignInWithGmailError", null, null);
                                                      setError(error);
                                                      console.log('oh no error', error);
                                                  })

                                              }}>Sign In With Google</LoadingButton>}
        </>
    )
}

export default SignInWithGoogle