import * as React from 'react';
import {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Lottie from 'react-lottie';
import * as shareLinkData from './shareLink.json'
import * as createGroupData from './createGroup.json'
import * as addExpensesData from './addExpenses.json'
import * as settleData from './settle.json'
import * as loggedInData from './loggedIn.json'
import {getEmailFromUser} from "../Trip/Utils";
const images = [
    {
        label: <Button color={"info"}>1.  Create Your Group</Button>,
        content: createGroupData
    },
    {
        label: <Button color={"info"}>2.  Add Everyone's Expenses</Button>,
        content: addExpensesData
    },
    {
        label: <Button color={"info"}>3.  Share Link With Friends</Button>,
        content: shareLinkData
    },
    {
        label: <Button color={"info"}>4.  Settle Up & Done!</Button>,
        content: settleData
    },
];
function useInterval(callback) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, 4000);
        return () => clearInterval(id);
    }, []);
}
function LandingPageStepper({user}) {
    const [disableAutoScroll, setDisableAutoScroll] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [currentStep, setCurrentStep] = React.useState(0);

    useInterval(()=> {
        if(!disableAutoScroll){
            let newStep = activeStep >=  images.length-1 ? 0 : activeStep+1;
            setActiveStep(newStep);
        }
    })

    return (
        <Box sx={{  flexGrow: 1 }}>
            <div style={{height: 200}} onClick={()=> { setDisableAutoScroll(true); setActiveStep(activeStep >=  images.length-1 ? 0 : activeStep+1)} }>
                <Lottie options={{
                    loop: true,
                    autoplay: true,
                    animationData: user ? loggedInData : images[activeStep].content,


                }} height={200} isPaused={false} isStopped={false} />
            </div>
            {user ? <div><div>Welcome Back,</div><div style={{ fontWeight: "bold"}}>{getEmailFromUser(user)}!</div></div> : images[activeStep].label}
        </Box>
    );
}

export default LandingPageStepper;