import React from 'react';
import Typography from "@mui/material/Typography";
import {Card, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {goForwardTo, isShortMobile} from "./Utils";
import AddExpensePanel from "../AddExpensePanel";
import GetTheAppPanel from "../GetTheAppPanel";
import GroupActionPanel from "../GroupActionPanel";
import GroupTitleCard from "../GroupTitleCard";
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SplitPalLoginSvg from "../SvgImages/SplitPalLoginSvg";
import SignInWithEmail from "../SignIns/SignInWithEmail";
import SignInWithGoogle from "../SignInWithGoogle";
import SignInWithApple from "../SignIns/SignInWithApple";
import {LoginOutlined} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import LandingPageStepper from "../LandingPage/LandingPageStepper";
import GroupIcon from '@mui/icons-material/Group';
import {useTheme} from "@mui/material/styles";
function Home({group, user, setUser, setReceiptImageData}) {

    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [cognitoSignInForm, setCognitoSignInForm] = React.useState(false);

    return (

        group ? <Grid container>
                {/*{*/}
                {/*    !user && <Grid style={{marginBottom: 20}} container><Grid item xs={12}>*/}
                {/*        <Card><Button fullWidth={true} style={{background: 'white'}}*/}

                {/*                      onClick={() => {*/}
                {/*                          goForwardTo(location, navigate,  "/" + group.id + "/home/user");*/}

                {/*                      }}*/}

                {/*                      startIcon={*/}
                {/*                          <SplitPalLoginSvg/>*/}
                {/*                      } >Not Logged In</Button>*/}
                {/*        </Card>*/}
                {/*    </Grid></Grid>*/}
                {/*}*/}
                {group && !isShortMobile() && <GroupTitleCard group={group} />
                }
                {
                    group && isShortMobile() && <Grid container>
                        <Grid item xs={12} textAlign={"center"}>
                            <Typography style={{color: "#327DD9"}} variant={"overline"}>{group.name}</Typography>
                        </Grid>
                    </Grid>
                }



                <GroupActionPanel group={group} user={user} hideShare={true}/>
                <Grid style={{paddingBottom: 12}} item xs={12}>

                </Grid>
                <AddExpensePanel setReceiptImageData={setReceiptImageData} group={group}/>
                <Grid style={{paddingTop: 24, color: theme.custom.feedbackColor}} item xs={12}>
                    <Button color={'inherit'} onClick={() => {
                        goForwardTo(location, navigate, "/" + group.id + "/home/feedback");

                    }} startIcon={<FeedbackOutlinedIcon/>} fullWidth={true} variant={"outlined"}>Feedback</Button>
                </Grid>
                <Grid style={{paddingBottom: 64}} item xs={12}>

                </Grid>


            </Grid>
            :
            <Grid textAlign={"center"} container spacing={2}>


                <Grid item xs={12} textAlign={"center"}>
                    <LandingPageStepper user={user}/>
                </Grid>

                {
                    cognitoSignInForm && <SignInWithEmail setCognitoSignInForm={setCognitoSignInForm}/>
                }

                {
                    cognitoSignInForm && <Button fullWidth={true} onClick={()=> { setCognitoSignInForm(false)}}>Back</Button>
                }

                { !cognitoSignInForm && !user && <>

                    <Grid item xs={12} style={{paddingTop: 12}} textAlign={"center"}>
                    <Card>
                        <SignInWithGoogle setUser={setUser}/>
                    </Card>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"}>
                        <Card>
                        <SignInWithApple setUser={setUser}/>
                        </Card>
                    </Grid>


                    <Grid item xs={12} textAlign={"center"}>
                        <Card>
                        <Button style={{ color : theme.palette.text.primary }} startIcon={<LoginOutlined />} fullWidth={true}
                                onClick={()=> { setCognitoSignInForm(true)}}
                        >Sign Up/In With Email</Button>
                        </Card>
                    </Grid>


                </> }

                { !user && <Grid item xs={12} textAlign={"center"}><Divider>OR</Divider></Grid> }

                {
                    user &&  <Grid textAlign={"center"} item xs={12}>
                        <Button fullWidth={true} variant={"contained"} onClick={() => {
                            goForwardTo(location, navigate, "/switch");

                        }}>Your Groups</Button>
                    </Grid>
                }
                <Grid textAlign={"center"} item xs={12}>
                    <Button startIcon={<GroupIcon/>} fullWidth={true} variant={"outlined"} onClick={() => {
                        goForwardTo(location, navigate, "/new");

                    }}>{ user ? "Create New Group" : "Start Splitting" }</Button>
                </Grid>

                <Grid style={{marginTop: 10}} textAlign={"center"} item xs={12}>

                    { !window.cordova && <GetTheAppPanel/> }
                </Grid>
            </Grid>
    );
}

export default Home;