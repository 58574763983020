import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {Alert, Grid, InputAdornment, TextField} from "@mui/material";
import {BEGIN_ID_TIMESTAMP, compareMonetaryValues, CurrencySymbol} from "../Trip/Utils";
import SphereSvg from "../SvgImages/SphereSvg";
import Button from "@mui/material/Button";
import {renderCurrency} from "../Trip/Currencies";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {useTheme} from "@mui/material/styles";

function UnevenSplitEditor({activeParticipants, payers, amount, setUnevenItems, expenseEntry, currency, noTitle, participantNames}) {

    const [unevenOwnages, setUnevenOwnages] = useState({});
    const payer = payers.length > 1 ? null : payers[0]
    const theme = useTheme();

    useEffect(() => {
        let o = {};
        if (expenseEntry?.items) {
            let activeParticipantsSet = new Set(activeParticipants);
            expenseEntry.items.forEach(item => {
                if (item.assignees[0].name !== payer && activeParticipantsSet.has(item.assignees[0].name)) {
                    o[item.assignees[0].name] = item.price;
                }
            })

            setUnevenItems(expenseEntry.items);
        }
        setUnevenOwnages(o);
    }, [])
    const totalParticipantOwnage = () => {
        let totalOwnage = 0;
        for (let prop in unevenOwnages) {
            totalOwnage += unevenOwnages[prop];
        }
        return totalOwnage;
    }

    return (<>
            <Grid container style={{paddingLeft: 0}}>
                {activeParticipants.map(a => a !== payer && <>
                        <Grid item xs={7}>
                            <Button startIcon={<PersonOutlineIcon/>}>
                                <Typography variant={"inherit"} color={theme.palette.text.primary}>
                                    {participantNames[a]}
                                </Typography>
                            </Button>
                        </Grid><Grid xs={5} item><FormControl
                        key={"uneven_owes_" + a} variant="standard" fullWidth={true}>


                        <TextField
                            style={{marginTop: 0}}
                            margin={"dense"}
                            size="small"
                            type="number"
                            value={unevenOwnages[a]}
                            onChange={(e) => {
                                let clone = Object.assign({}, unevenOwnages);
                                clone[a] = e.target.value ? Number(e.target.value) : null;
                                setUnevenOwnages(clone);
                                let items = [];
                                let hasNulls = false;
                                let totalOwnage = 0;
                                for (let prop in clone) {
                                    if (clone[prop] === null) {
                                        hasNulls = true;
                                        break;
                                    }
                                    totalOwnage += clone[prop];
                                    items.push({
                                        quantity: 1,
                                        id: Date.now() - BEGIN_ID_TIMESTAMP,
                                        name: 'portion',
                                        price: clone[prop],
                                        assignees: [{name: prop, quantity: 1}]
                                    });
                                }
                                if(payer || payer === 0){
                                    items.push({
                                        quantity: 1,
                                        id: Date.now() - BEGIN_ID_TIMESTAMP + 1,
                                        name: 'portion',
                                        price: amount - totalOwnage,
                                        assignees: [{name: payer, quantity: 1}]
                                    });
                                }
                                if (hasNulls || totalOwnage > amount) {
                                    items = [];
                                }
                                setUnevenItems(items);
                            }}

                            InputProps={{
                                startAdornment: <InputAdornment position="start"><CurrencySymbol
                                    currency={currency}/></InputAdornment>,
                            }}

                        />
                    </FormControl></Grid>
                    </>
                )}
            </Grid>

            {totalParticipantOwnage() > amount && payers.length > 1 &&
                <Alert style={{marginTop: 10}} severity={"error"}>participants covering more than amount paid
                    for the expense ({renderCurrency(totalParticipantOwnage(), currency)}/{renderCurrency(amount, currency)} ). </Alert>}
            {  compareMonetaryValues(totalParticipantOwnage(), amount, currency)  > 0  && payers.length > 1 &&
                <Typography style={{marginTop: 10}} variant={"body2"}>Still need to cover <span style={{ color: 'red', fontWeight: 'bold'}}>{renderCurrency(amount - totalParticipantOwnage(), currency)}</span> of the expense.</Typography>}

            {totalParticipantOwnage() > amount && payers.length === 1 &&
                <Alert style={{marginTop: 10}} severity={"error"}>participants cannot owe {participantNames[payer]} more than amount paid
                    for the expense ({renderCurrency(totalParticipantOwnage(), currency)}/{renderCurrency(amount, currency)} ). </Alert>}
            {totalParticipantOwnage() <= amount && payers.length === 1 &&
                <Typography style={{marginTop: 10}} variant={"body2"}>
                    Payer ({participantNames[payer]}) covers <span style={{ fontWeight: 'bold'}}>{renderCurrency(amount - totalParticipantOwnage(), currency)}</span> of the expense.</Typography>}

        </>
    );
}

export default UnevenSplitEditor;