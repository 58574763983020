import React, {useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Grow,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    MobileStepper
} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {
    addTrackingToUser, getGroupVersion,
    getSettingsString,
    goBack,
    goForwardTo,
    isMobile,
    logActivity,
    shareSplitGroup
} from "../Trip/Utils";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LogoSvg from "../SvgImages/LogoSvg";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CurrencySelector from "../Trip/CurrencySelector";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import ListItemText from "@mui/material/ListItemText";
import ShareIcon from "@mui/icons-material/Share";
import Container from "@mui/material/Container";
import {API, graphqlOperation} from "aws-amplify";
import {createTrip} from "../../graphql/mutations";
import {getPreSignedUrl} from "../../graphql/queries";
import {serializeGroup} from "../Trip/Utils/expenseUtils";


const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}


function CreateNewGroupDialog({user}) {
    const [phase, setPhase] = useState(0);
    const [createError, setCreateError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [participants, setParticipants] = useState(["", ""])
    const [growButton, setGrowButton] = useState(true);
    const [currency, setCurrency] = useState("USD");

    const [newTrip, setNewTrip] = useState(null);
    const [name, setName] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const closeDialog = ()=> {
        goBack(location, navigate, '/');
    }
    function navigateToExpenses(id) {
        if(isMobile()){
            goForwardTo(location, navigate,"/" + id + "/expenses", true);
        } else {
            goForwardTo(location, navigate,"/" + id, true);
            //closeDialog();
        }

    }

    function getTrueParticipants() {
        let p = new Set();
        for (let i = 0, l = participants.length; i < l; i++) {
            participants[i] && participants[i].trim() && p.add(participants[i].trim());
        }
        return Array.from(p);
    }
    function canCreateGroup() {

        let trueParticipants = getTrueParticipants();
        return trueParticipants.length >= 2;

    }

    const handleNameChange = (name, index) => {
        // You can use a functional setState, so you get the old value and do some updates on it, and return the new state.
        let newParticipants = [...participants];
        newParticipants[index] = name;
        if (newParticipants[newParticipants.length - 1]) {
            newParticipants.push("")
        }
        setParticipants(newParticipants);
    }

    async function onSubmit() {
        try {
            setIsLoading(true);
            let participants = [];
            getTrueParticipants().forEach(p => {
                participants.push(p)
            });

            let response = await API.graphql(graphqlOperation(getPreSignedUrl, {
                id: "splitpal-new-group-3~|~" + JSON.stringify({name , currency, participants})
            }));
            let newId = response.data.getPreSignedUrl;
            logActivity('createGroup', { id: newId, name, currency, participants,expenseEntries: [] }, user);

            let localGroupsMap = JSON.parse(localStorage.getItem("splitpal_groups") || "{}");
            let newGroup = {
                id: newId,
                name: name,
                lastAccessed: Date.now()
            }
            localGroupsMap[newId] = newGroup;

            localStorage.splitpal_most_recent = newId;

            if(user){
                await addTrackingToUser(user,newGroup);
            }

            setIsLoading(false);


            localStorage.setItem("splitpal_groups", JSON.stringify(localGroupsMap));
            setNewTrip({ id : newId , name, participants});
            navigateToExpenses(newId);
        } catch (err) {
            if (err.errors) {
                setCreateError(err.errors[0].message);
            }
            console.log('error creating todo:', err)
            setIsLoading(false);
        }
    }

    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile()}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogContent>
                <Container>
                    {createError && <Alert severity="error">{createError}</Alert>}
                    {phase === 0 ? (<Box component="form" noValidate sx={{mt: 1}}>
                        <Grid container>
                            {  <Grid container>
                                <Grid style={{marginTop:20}} textAlign={"center"} item xs={12}>
                                    <LogoSvg/>
                                </Grid>

                            </Grid>
                            }

                            <Grid style={{marginTop:20}} item xs={12}>
                                <Typography textAlign={"center"} variant={"body2"}> Please give your group a name which is easy to remember.
                                </Typography>
                            </Grid>
                            <Grid style={{marginTop:20}} item xs={12}>
                                <Typography variant={"body1"}>Group Name</Typography>
                                <TextField margin="dense" key={"participant-homepage-name-input"}
                                           placeholder={"Group name..."}
                                           value={name}
                                           onChange={(e) => setName(e.target.value)}
                                           fullWidth  />
                            </Grid>
                            <Grid style={{marginTop:20}}  item xs={12}>
                                <CurrencySelector fullWidth currency={currency} setCurrency={setCurrency}/>
                            </Grid>
                            { !isMobile() && <Grid item xs={12}>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={!name}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                    onClick={() => {
                                        setPhase(1);
                                    }}


                                >
                                    Proceed
                                </LoadingButton>
                            </Grid> }
                        </Grid>


                    </Box>) : phase === 1 ? (
                        <Box noValidate sx={{mt: 1}}>
                            <Grid container>
                                <Grid item textAlign={"center"} xs={12}>
                                    <Typography variant={"body2"}>Add group members below.
                                    </Typography>
                                </Grid>
                            </Grid>
                            {participants.map((participant, index) =>

                                <TextField autoFocus={index === 0} margin="dense" key={"participant-homepage-input" + index}

                                           InputProps={{
                                               endAdornment: (participant && <InputAdornment position="end">

                                                   <ClearIcon onClick={() => {
                                                       let newParticipants = [...participants];
                                                       newParticipants.splice(index, 1);
                                                       setParticipants(newParticipants);
                                                   }
                                                   }/></InputAdornment>)

                                           }}

                                           value={participant}
                                           onChange={(e) => handleNameChange(e.target.value, index)}
                                           fullWidth label={ index > 0  ? ( (index + 1) + nth(index + 1) + " Person" ) : "Your Name" } />)}


                            { !isMobile() && <LoadingButton
                                loading={isLoading}
                                disabled={isLoading || !canCreateGroup() || !name}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                onClick={onSubmit}

                            >
                                Create Group
                            </LoadingButton> }

                        </Box>) : phase === 2 ?
                        (
                            <Box noValidate sx={{mt: 1}}>
                                <Grid container>
                                    <Grid item textAlign={"center"} xs={12}>
                                        <Typography variant={"body2"}>Group Has Been Created!
                                        </Typography>
                                    </Grid>
                                    <Grid item textAlign={"center"} xs={12}>
                                        <Typography variant={"body2"}>Following members are not yet invited:
                                        </Typography>
                                    </Grid>


                                </Grid>

                                <List>

                                    {
                                        getTrueParticipants().map((participant, index) => index > 0 &&
                                            <><ListItem disablePadding>
                                                <ListItemIcon>
                                                    <PersonIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={participant} />
                                            </ListItem>
                                                <Divider  variant="inset" component="li"/>
                                            </>
                                        )
                                    }
                                </List>



                                <Grow in={growButton}

                                      style={{ transformOrigin: '0 0 0' }}
                                      {...(growButton ? { timeout: 3000 } : {})}
                                >
                                    <LoadingButton
                                        style={{marginBottom: 0}}
                                        color={"info"}
                                        startIcon={<ShareIcon/>}
                                        loading={isLoading}
                                        disabled={!canCreateGroup() || !name}

                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                        onClick={async ()=> {
                                            await shareSplitGroup(newTrip.id, newTrip.participants);
                                        } }

                                    >
                                        Share Link
                                    </LoadingButton>
                                </Grow>
                                <Grid container style={{paddingBottom: 20}}>
                                    <Grid style={{marginTop: 20}} item textAlign={"center"} xs={12}>
                                        <Typography variant={"body1"}>You can collaborate in real time.
                                        </Typography>
                                    </Grid>


                                </Grid>

                            </Box>) : (<div>should be unreachable</div>)
                    }
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <MobileStepper style={{background: "transparent"}}
                                       variant="dots"

                                       steps={2}
                                       position="static"
                                       activeStep={Math.min(phase, 3)}
                                       nextButton={<div style={{width: "100%"}}></div>}
                                       backButton={<div style={{width: "100%"}}></div>}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign={"right"}  >
                        { phase !== 2 ?  <Button onClick={closeDialog}> Cancel</Button> :
                            <Button variant={"contained"} startIcon={<ArrowCircleRightOutlinedIcon/>} onClick={navigateToExpenses}>Go to your Group</Button> }
                        { isMobile() && phase === 0 &&
                            <Button disabled={!name} variant={"contained"} startIcon={<ArrowCircleRightOutlinedIcon/>} onClick={()=>{setPhase(1)} }> Next</Button> }
                        { isMobile() && phase === 1 &&
                            <LoadingButton
                                loading={isLoading}
                                disabled={!canCreateGroup() || !name}
                                variant={"contained"} startIcon={<ArrowCircleRightOutlinedIcon/>} onClick={onSubmit}> Create</LoadingButton> }
                    </Grid>
                </Grid>


            </DialogActions>
        </Dialog>
    );
}

export default CreateNewGroupDialog;