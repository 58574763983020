import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {CurrencySymbol, goBack, logActivity, updateSettings} from "../Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import CloseDialogButton from "./CloseDialogButton";
import PastSplitsSvg from "../SvgImages/Home/PastSplitsSvg";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {serializeGroup} from "../Trip/Utils/expenseUtils";
import {addSplitToArchive} from "../../graphql/queries";
import Alert from "@mui/material/Alert";

function ArchiveDialog({group,setGroup, user}) {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [archiveName, setArchiveName] = React.useState("");

    const closeDialog = () => {
        goBack(location, navigate, group ? '/' + group.id + '/results' : '/');
    }


    return (
        <Dialog
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Archive Split<CloseDialogButton onClick={closeDialog}/></DialogTitle>

            <DialogContent>
                {error && <Alert severity={"error"}>Error occurred while performing this action. Please reach out to info@splitpal.io for assistance.</Alert> }
                <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <PastSplitsSvg/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>All The Expenses and Paid Entries Will Be <span style={{fontWeight: "bold"}}>Moved To Archive</span>, Which Can be Downloaded Later via CSV.</Typography>

                    </Grid>
                    <Grid item xs={12} style={{paddingTop: 20}}>
                        <FormControl variant="standard" fullWidth={true}>

                            <TextField
                                margin={"dense"}
                                autoFocus={true}
                                label={"Archive Label"}
                                size="small"
                                type="text"
                                value={archiveName}
                                onFocus={event => {
                                    event.target.select();
                                }}

                                onChange={(e) => {
                                    setArchiveName(e.target.value);
                                }}

                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color={"info"}> Cancel</Button>
                <LoadingButton disabled={!archiveName} loading={loading} onClick={async ()=> {
                    setLoading(true);
                    try {


                        await API.graphql(graphqlOperation(addSplitToArchive, {
                            id : group.id,
                            name: archiveName,
                            body: JSON.stringify(group)
                        }));

                        let clone = JSON.parse(JSON.stringify(group));
                        clone.pastSplits++;
                        clone.expenseEntries = [];
                        await API.graphql(graphqlOperation(updateTrip,{
                            input: serializeGroup(clone)
                        }));
                        await logActivity("archive", {...group , data: archiveName }, user);

                        setGroup(clone);
                        closeDialog();
                    }catch(e) {
                        logActivity("error:archive", group, user);
                        console.error(e);
                        setError(e);
                    } finally {
                        setLoading(false);
                    }
                } }  color={"primary"}>Archive</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ArchiveDialog;