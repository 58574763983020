import React from 'react';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function CloseDialogButton({onClick}) {
    return (
        <IconButton
            aria-label="close"
            onClick={onClick}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.text.primary,
            }}
        >
            <CloseIcon/>
        </IconButton>
    );
}

export default CloseDialogButton;