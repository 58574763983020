import * as React from "react"

const SvgComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.28 80.12" {...props}>
        <defs>
            <style>{".cls-1{fill:#327DD9}"}</style>
        </defs>
        <g id="katman_2" data-name="katman 2">
            <g id="katman_1-2" data-name="katman 1">
                <path
                    className="cls-1"
                    d="M69.36 38.4V17.87l5.87-.1-5.73-6a14.41 14.41 0 0 0-2.74-6.21A14.71 14.71 0 0 0 56.37 0a14.35 14.35 0 0 0-11.54 5c-5.5 6.51-3 17 1.15 23.14a22.45 22.45 0 0 0 7.76 7.07 25.44 25.44 0 0 0 12.06 3.14ZM32.43 49.61q.07 15.17.17 30.39a33.44 33.44 0 0 1-22.26-8.83A33.66 33.66 0 0 1 0 47.53l30.43.09a2 2 0 0 1 2 1.99ZM38.58 47.69 69 47.52a33.42 33.42 0 0 1-8.84 22.26 33.62 33.62 0 0 1-23.63 10.34l.09-30.46a2 2 0 0 1 1.96-1.97ZM30.44 43.22 0 43.4a33.46 33.46 0 0 1 8.86-22.27A33.63 33.63 0 0 1 32.45 10.8l-.09 30.5a1.92 1.92 0 0 1-1.92 1.92Z"
                />
            </g>
        </g>
    </svg>
)

export default SvgComponent
