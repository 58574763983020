import React from 'react';
import {Chip, ListItem, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {renderCurrency} from "../../Trip/Currencies";
import {BEGIN_ID_TIMESTAMP, renderDateFromString} from "../../Trip/Utils";

function PaidPaymentRow({noUndo, trip, paidEntry}) {

    const navigate = useNavigate();
    let timestamp = Number(paidEntry.id) + BEGIN_ID_TIMESTAMP;
    let id = paidEntry.id;
    let payee = paidEntry.items.map(item=> trip.participants[item.assignees[0].name] ).join(' & ');
    let comment = paidEntry.description;

    let amount = paidEntry.amount;
    let payer = paidEntry.payers.map(p=> trip.participants[p]).join(' & ');


    return (
        <ListItem style={{border: "1px solid #B3B4B6",
            borderRadius: "12px", marginBottom: 5, paddingRight: 84}}
            key={"PaidPaymentRow_" + id}

            secondaryAction={
                !noUndo && <Chip style={{ borderRadius: 12 }} variant={"outlined"} color={"error"} size={"small"} onClick={() => {
                    navigate("/" + trip.id + "/results/delete/" + id);
                }} label={"Undo"}/>
            }
        >
            <ListItemText

                primary={<span> <span style={{fontWeight: "bold"}}>{payer}</span> paid <span style={{fontWeight: "bold"}}>{payee}</span> {renderCurrency(amount, trip.currency)}</span>}
                secondary={ renderDateFromString(paidEntry.date) + " " + comment}
            />


        </ListItem>
    );
}

export default PaidPaymentRow;