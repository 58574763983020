import React, {useEffect} from 'react';
import {CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Slide, StepLabel} from "@mui/material";
import CloseDialogButton from "./CloseDialogButton";
import {BEGIN_ID_TIMESTAMP, goBack, isMobile, monetaryValue} from "../Trip/Utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {API, graphqlOperation} from "aws-amplify";
import {getPreSignedUrl} from "../../graphql/queries";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import {renderCurrency} from "../Trip/Currencies";
import StepContent from "@mui/material/StepContent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import PastSplitPanel from "../PastSplitPanel";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import {useTheme} from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ColorlibStepIcon(props) {

    return (<span style={{  height: 24,
            width: 24,
            backgroundColor: props.active ? "#1565D8" : "rgba(55, 65, 81, 0.48)",
            borderRadius: "50%"
    }}></span>
    );
}


function HistoryDialog({group}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [data, setData] = React.useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [cursor, setCursor] = React.useState(null);
    const [loadingOlder, setLoadingOlder] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    let goBackUrl = '/';
    if (params.group) {
        goBackUrl += params.group + '/';
    }
    if (params.view) {
        goBackUrl += params.view;
    }

    const renderOwnageTable = (data, participantNames) => {
        return (<div>
            <TableContainer style={{marginTop: 5, marginBottom: 5}}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow><TableCell>Participant</TableCell>
                            <TableCell align={"right"}>Paid</TableCell>
                            <TableCell align={"right"}>Portion</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.participants.map((p, index) => <TableRow> <TableCell style={{paddingBottom: 0}}>{participantNames[p]}</TableCell>
                            <TableCell style={{paddingBottom: 0}} align={"right"}>{data.payerMap[p] || ""}</TableCell>
                            <TableCell style={{paddingBottom: 0}} align={"right"}>{monetaryValue(data.type === 1 ? data.amount / data.participants.length : data.pm[p], data.currency) }</TableCell>
                        </TableRow> ) }
                        <TableRow><TableCell>Total</TableCell><TableCell ></TableCell><TableCell align={"right"}>{renderCurrency(data.amount, data.currency)}</TableCell></TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>)
    }

    const renderExpenseEntry = ( logType)=> {
        return {label : (e)=>
                e.data.type === 4 ? <span><span style={{fontWeight: "bold"}}>{e.data.payer}</span> paid <span style={{fontWeight: "bold"}}>{e.data.payee}</span> { renderCurrency(e.data.amount, group.currency) }

        </span> :

                    <span>{logType === 'add' ? "Added Expense" : "Modified Expense"} <span style={{fontWeight: "bold"}}>
                        {logType === 'add' ? e.data.description : e.data.oldData.description}</span> </span>,

            content : (e)=> <>

                { e.data.type === 4 ? <span><i>{e.data.description ? e.data.description : "no comment" }</i></span> :
                    logType ==='modify' ? <Grid container>
                        <Grid xs={12} textAlign={"center"} item>
                            From
                        </Grid>
                        <Grid xs={12} item>
                            {renderOwnageTable(e.data.oldData, e.data.participantNames)}
                        </Grid>
                        <Grid xs={12} textAlign={"center"} item>
                            To
                        </Grid>
                        <Grid xs={12} item>
                            {renderOwnageTable(e.data.newData, e.data.participantNames)}
                        </Grid>
                        {e.data.oldData.description !== e.data.newData.description && <Grid xs={12} item>
                            <Typography variant={"body2"}>changed description to <span style={{ fontWeight: "bold"}}>{e.data.newData.description}</span></Typography>
                        </Grid> }
                    </Grid>: renderOwnageTable(e.data, e.data.participantNames)
                     }
            </>}
    }

    const validLogs = {
        'archive' : { label: (e)=> <span>Split archived: <span style={{fontWeight: "bold"}}>{e.data}</span></span>, content : (e) => <></> },
        'unlink' : { label: (e)=> <span>Participants Unlinked: <span style={{fontWeight: "bold"}}>{e.data.participantString}</span></span>, content : (e) => <></> },
        'link' : { label: (e)=> <span>Participants Linked: <span style={{fontWeight: "bold"}}>{e.data.participantString}</span></span>, content : (e) => <></> },
        'removeParticipant' : { label: (e)=> <span>Participant Removed: <span style={{fontWeight: "bold"}}>{e.data.participant}</span></span>, content : (e) => <></> },
        'addParticipant' : { label: (e)=> <span>Participant Added: <span style={{fontWeight: "bold"}}>{e.data.participant}</span></span>, content : (e) => <></> },
        'changeCurrency' : { label: (e)=> <span>Default Currency set to <span style={{fontWeight: "bold"}}>{e.data.newCurrency}</span> </span>, content : (e) => <></>  },
        'now' : {label: (e)=> 'Now', content : (e) => <>
                <PastSplitPanel noClose={true} isNew={true} group={group} split={{
                    id: (group.paidEntries?.length > 0 && Number(group.paidEntries[group.paidEntries.length - 1].id)) || (Date.now() - BEGIN_ID_TIMESTAMP),
                    body: group
                }} />
            </> },
        'createGroup' : { label : (e)=> 'Group Created' , content : (e)=> <>
                <div><span style={{fontWeight: "bold"}}>{group.name}</span> begins.</div>

            </> },
        'add' : renderExpenseEntry('add'),
        'modify' : renderExpenseEntry('modify'),
        'remove' : {label: (e)=>
                e.data.type === 4 ? <span>Undo Mark Paid <span style={{fontWeight: "bold"}}>{e.data.payer}</span>-><span style={{fontWeight: "bold"}}>{e.data.payee}</span> { renderCurrency(e.data.amount, group.currency) }

        </span> :
                <span>Removed Expense <span style={{fontWeight: "bold"}}>{e.data?.description}</span> </span>, content : (e)=> <></>}
    }
    const onClose = () => {
        goBack(location, navigate, goBackUrl);
    }
    const loadData = async ()=> {
        if(group){
            if(!data || data.length === 0){
                setLoading(true);
            } else {
                setLoadingOlder(true);
            }

            let str = "splitpal-history~|~" + group.id;
            if(cursor){
                str += `~|~${cursor.timestamp}~|~${cursor.day}`
            }
            let resp = await API.graphql(graphqlOperation(getPreSignedUrl, {
                id: str
            }));
            let parsedResp = JSON.parse(resp.data.getPreSignedUrl);

            setCursor(parsedResp.cursor);

            let items = parsedResp.Items.filter(d => validLogs[d.action]);
            const decorateWithPayerMap = (data)=>{
                data.payerMap = {};
                for(let i = 0, l = data.payers.length; i < l; i++){
                    data.payerMap[data.payers[i]] = data.payerAmounts[i];
                }
            }
            items.forEach(d=> {

                if(d.action === "modify"){
                    decorateWithPayerMap(d.data.oldData);
                    decorateWithPayerMap(d.data.newData);
                } else if(d.data?.type) {
                    if(d.data.type === 4){

                    } else {
                        decorateWithPayerMap(d.data)
                    }
                }
            })
            setData(data ? [...data, ...items] : items);
            setLoading(false);
            setLoadingOlder(false);
        }
    }
    useEffect(()=> {
        (async ()=> {
            await loadData();
        })();

    }, [group])


    return (
        <Dialog

            TransitionComponent={Transition}
            fullWidth={true}
            fullScreen={isMobile()}
            open={true}
            onClose={onClose}
        >
            <DialogTitle>History
                <CloseDialogButton onClick={onClose}/>
            </DialogTitle>
            <DialogContent>
                { !loading ?
                    <Box sx={{maxWidth: 400}}>
                        <Stepper activeStep={activeStep} orientation="vertical"   >
                            {[ { action: 'now' }, ...data].map((step, index) => (
                                <Step key={'history_' + step.timestamp}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} onClick={() => {
                                        setActiveStep(index);
                                    }}><span style={{ color: index === activeStep ? theme.palette.text.primary : theme.palette.text.secondary}}>{validLogs[step.action].label(step)}</span>
                                        <div><Typography variant={"caption"}>{ step.timestamp ? (new Date(step.timestamp)).toLocaleString() : ""}</Typography></div>
                                    </StepLabel>
                                    <StepContent>{ validLogs[step.action].content(step)}</StepContent>
                                </Step>
                                )) }
                        </Stepper>
                        { cursor ?
                            <LoadingButton
                                startIcon={<ArrowDownwardOutlinedIcon/>}
                                loading={loadingOlder}
                                fullWidth={true}
                                variant={"text"}
                                onClick={async()=>{ await loadData()}}>Show Older</LoadingButton>
                            : <></>}
                    </Box> : <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <CircularProgress/>
                    </Grid>
                </Grid>}
            </DialogContent>
        </Dialog>
    );
}

export default HistoryDialog;