import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Slide} from "@mui/material";
import {API, graphqlOperation} from "aws-amplify";
import {getCurrencyConversion, getPreSignedUrl, getReceipt} from "../../../graphql/queries";
import axios from "axios";
import {updateTrip} from "../../../graphql/mutations";
import {useLocation, useNavigate} from "react-router-dom";
import {
    _base64ToArrayBuffer,
    BEGIN_ID_TIMESTAMP,
    decorateFromSettingsString,
    expenseSavingDisableCondition,
    formatDate,
    goBack,
    logActivity, showInterstititalAd
} from "../../Trip/Utils";
import ExpenseStepper from "./ExpenseStepper";
import {getParticipantPortionMap, serializeGroup} from "../../Trip/Utils/expenseUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReceiptEditor from "../../CreateEntry/ReceiptEditor";
import CloseDialogButton from "../CloseDialogButton";
import Lottie from "react-lottie";
import * as scanReceiptAnimation from '../../../animations/scan-receipt.json'

const imageLoadingPhaseDescriptions = {
    0: "Initiating Upload",
    1: "Uploading Image",
    2: "Receipt Recognition"
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddExpenseDialog({trip, setTrip, type, receiptImageData, setReceiptImageData, user}) {

    const isMobile = window.innerWidth <= 768;
    const [receiptImageLoading, setReceiptImageLoading] = useState(false);
    const [payers, setPayers] = React.useState([]);
    const [payerAmounts, setPayerAmounts] = React.useState([]);
    const [receipt, setReceipt] = React.useState({items: [], tax: 0, tip: 0, discount: 0});
    const [amount, setAmount] = useState(null);
    const [description, setDescription] = useState('');
    const [activeParticipants, setActiveParticipants] = useState(trip.participants.map((p, i) => i));
    const [loading, setLoading] = useState(false);
    const [ocrError, setOcrError] = useState('');
    const [unevenItems, setUnevenItems] = useState([]);
    const [progress, setProgress] = React.useState(0);
    const [currency, setCurrency] = useState(trip.currency);
    const [imageLoadingPhase, setImageLoadingPhase] = useState(0);
    const [date, setDate] = useState(formatDate(Date.now()));
    const [error, setError] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(null);
    const [receiptImageFile, setReceiptImageFile] = useState(null);
    const [geometries, setGeometries] = useState([]);
    const canvasParentRef = useRef(null);

    const fromQuickReceiptAdd = !!receiptImageData;
    const participantsMap = {};
    trip.participants.forEach((p, i) => participantsMap[p] = i);

    const disabledCondition = () => {
        return expenseSavingDisableCondition(type, receipt.items, currency, amount, payers, payerAmounts, description, activeParticipants, unevenItems, date);
    }


    useEffect(() => {
        if (canvasParentRef.current && receiptImageFile) {
            setCanvasWidth(canvasParentRef.current.offsetWidth);

        }
        if (receiptImageData) {

            setReceiptImageData(null);
            handleCapture(receiptImageData.e, receiptImageData.dataUrl, true);
        }
    }, [imageLoadingPhase, receiptImageData]);

    const handleCanvasCallback = useCallback((node) => {
        if (node) {
            let img = new Image();
            let ctx = node.getContext('2d');
            img.onload = function () {

                let minx = 1;
                let miny = 1;
                let maxx = 0;
                let maxy = 0;

                geometries.forEach(g => {
                    g.Polygon.forEach(p => {
                        minx = Math.min(minx, p.X);
                        miny = Math.min(miny, p.Y);
                        maxx = Math.max(maxx, p.X);
                        maxy = Math.max(maxy, p.Y);
                    })
                })
                let x = img.width * minx;
                let y = img.height * miny
                let width = (maxx - minx) * img.width;
                let height = (maxy - miny) * img.height;

                let ratio = height / width;

                node.height = node.width * ratio;
                ctx.drawImage(img,

                    x, y, width, height,
                    0, 0, node.width, node.height
                );

                //node.width = 200;
                // maxy
                geometries.forEach(g => {
                    ctx.beginPath();
                    let bx = (g.Polygon[0].X - minx) / (maxx - minx);
                    let by = (g.Polygon[0].Y - miny) / (maxy - miny);
                    g.Polygon.forEach((p, index) => {


                        let x = (p.X - minx) / (maxx - minx);
                        let y = (p.Y - miny) / (maxy - miny);
                        if (index === 0) {
                            ctx.moveTo(x * node.width, y * node.height);
                        } else {
                            ctx.lineTo(x * node.width, y * node.height);
                        }
                        if (index === 3) {
                            ctx.lineTo(bx * node.width, by * node.height);
                        }

                    })
                    ctx.lineWidth = g.isTotal ? 3 : 2;
                    ctx.strokeStyle = g.isTotal ? 'blue' : 'red';
                    ctx.stroke();
                })

            }
            img.src = !!window.cordova ? "data:image/jpeg;base64," + receiptImageFile : URL.createObjectURL(receiptImageFile);
        }

    }, [receiptImageFile, geometries]);

    const navigate = useNavigate();
    const location = useLocation();

    const closeDialog = (e, reason) => {
        if (reason !== 'backdropClick') {
            goBack(location, navigate, '/' + trip.id + '/expenses');
        }
    }

    const handleCordovaCapture = async (e) => {

        navigator.camera.getPicture((result) => {
            handleCapture({target: {files: [_base64ToArrayBuffer(result)]}}, result);

        }, (e) => {
            console.log(e);
            //setError("Something is wrong with your camera");
        }, {
            quality: 50,
            destinationType: navigator.camera.DestinationType.DATA_URL, correctOrientation: true
        })

    }


    const handleCapture = async (e, dataUrl) => {

        logActivity('receiptCaptured', trip, user);

        setReceiptImageLoading(true);
        setReceiptImageFile(dataUrl || e.target.files[0]);

        const getNumericValueFromReceiptText = (text, currency) => {
            let cur_re = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
            let parts = cur_re.exec(text);
            return parseFloat(parts[1].replace(/\D/, '') + '.' + (parts[2] ? parts[2] : '00'));
        }

        try {
            setImageLoadingPhase(0);
            let id = trip.id + '/' + Date.now()


            let preSignedUrlResponse = await API.graphql(graphqlOperation(getPreSignedUrl, {
                id
            }));

            setImageLoadingPhase(1);
            await axios.put(
                preSignedUrlResponse.data.getPreSignedUrl, // The url which was generated
                e.target.files[0], { // Using your own json object
                    headers: {
                        'Content-Type': 'image/jpeg'
                    },
                    onUploadProgress: progressEvent => {
                        setProgress((progressEvent.loaded / progressEvent.total) * 100);
                    }
                })
            setImageLoadingPhase(2);

            let receiptExtractResponse = await API.graphql(graphqlOperation(getReceipt, {
                id
            }))
            let receiptExtract = JSON.parse(receiptExtractResponse.data.getReceipt);
            let items = [];
            let inc = 0;
            let tax = 0;
            let discount = 0;
            let tip = 0;
            let hasDescription = false;
            let usedGeometries = [];
            try {
                if (receiptExtract.ExpenseDocuments.length === 0
                    || receiptExtract.ExpenseDocuments[0].LineItemGroups.length === 0
                    || receiptExtract.ExpenseDocuments[0].LineItemGroups[0].LineItems.length === 0) {
                    throw "error"
                }
                receiptExtract.ExpenseDocuments.forEach(d => {
                    d.SummaryFields.forEach(summaryField => {
                        if (summaryField.Type.Text === "TAX") {
                            if (Number(summaryField.ValueDetection.Text.replace(/^\D+/g, ''))) {
                                tax = Math.max(tax, getNumericValueFromReceiptText(summaryField.ValueDetection.Text, trip.currency));
                                usedGeometries.push(summaryField.ValueDetection.Geometry)
                            }
                        }
                        if (summaryField.Type.Text === "VENDOR_NAME" && fromQuickReceiptAdd) {
                            setDescription(summaryField.ValueDetection.Text);
                            hasDescription = true;
                        }
                        if (summaryField.Type.Text === "TOTAL") {
                            setDescription(summaryField.ValueDetection.Text);
                            summaryField.ValueDetection.Geometry.isTotal = true;
                            usedGeometries.push(summaryField.ValueDetection.Geometry)
                        }
                    });
                    d.LineItemGroups.forEach(lineItemGroup => {
                        lineItemGroup.LineItems.forEach(lineItem => {
                            let item = {
                                name: "undefined",
                                id: Date.now() - BEGIN_ID_TIMESTAMP + (++inc),
                                quantity: 1,
                                price: 0
                            };
                            lineItem.LineItemExpenseFields.forEach(expenseField => {
                                if (expenseField.Type.Text === "ITEM") {
                                    item.name = expenseField.ValueDetection.Text;
                                    usedGeometries.push(expenseField.ValueDetection.Geometry)
                                }
                                if (expenseField.Type.Text === "PRICE") {

                                    let v = getNumericValueFromReceiptText(expenseField.ValueDetection.Text, trip.currency);
                                    if (v) {
                                        item.price = v;
                                    } else {
                                        item.price = item.price || 0;
                                    }
                                    usedGeometries.push(expenseField.ValueDetection.Geometry)
                                }
                                if (expenseField.Type.Text === "QUANTITY") {
                                    let v = Number(expenseField.ValueDetection.Text.replace(/[^\d.]/g, ''));
                                    if (v) {
                                        item.quantity = Math.round(v);
                                    } else {
                                        item.quantity = Math.round(item.quantity || 1);
                                    }
                                    usedGeometries.push(expenseField.ValueDetection.Geometry)
                                }
                            })
                            if (item.price) {
                                items.push(item);
                            }


                        })
                    })
                })
                if (!hasDescription && fromQuickReceiptAdd) {
                    setDescription("Uploaded Receipt");
                }
                setGeometries(usedGeometries);
                setReceipt({items, tax, tip, discount});
                setImageLoadingPhase(3);
            } catch (e) {
                setOcrError("Did not recognize receipt. Please enter items manually")
            }


        } catch (e) {
            console.log(e);
        }
        setReceiptImageLoading(false);
    }


    async function createEntry() {
        setLoading(true);
        try {
            setError(null);
            let submitDescription = description || "Receipt";
            let alreadyExists = false;
            (trip.expenseEntries || []).forEach(e => {
                if (e.description === description) {
                    alreadyExists = true;
                }
            })

            if (alreadyExists) {
                let lastOne = 0;
                (trip.expenseEntries || []).forEach(e => {
                    if (e.description.startsWith(description + " #")) {
                        let tokens = e.description.split("#");
                        let strNum = tokens[tokens.length - 1];
                        lastOne = Math.max(lastOne, Number(strNum));
                    }
                })
                submitDescription = (description + " #" + (lastOne + 1))
            }


            const id = trip.version > 1 ? trip.lastId : Date.now() - BEGIN_ID_TIMESTAMP;

            // see if baseCurrency is different from selected. If so fetch rate.
            // otherwise --- rate = 1
            let rate = 1;
            if (currency !== trip.currency) {
                let currencyResponse = await API.graphql(graphqlOperation(getCurrencyConversion, {
                    base: trip.currency,
                    date: date
                }));
                let rates = JSON.parse(currencyResponse.data.getCurrencyConversion);
                rate = rates["conversion_rates"][currency];
            }
            let newExpense = {
                id: id,
                description: submitDescription,
                payers,
                payerAmounts,
                currency,
                date,
                rate,
                participants: activeParticipants,
                tax: receipt.tax,
                discount: receipt.discount,
                tip: receipt.tip,
                type,
                items: type === 2 ? unevenItems : receipt.items
            }

            trip.expenseEntries.push(newExpense);
            let response = await API.graphql(graphqlOperation(updateTrip, {
                input: serializeGroup(trip)
            }));

            logActivity("add", {
                ...trip, data: {
                    participantNames: trip.participants,
                    type,
                    amount,
                    description,
                    payerAmounts,
                    payers,
                    participants: activeParticipants,
                    currency,
                    rate,
                    date,
                    pm: type === 3 ? {} : getParticipantPortionMap(newExpense, trip)
                }
            }, user);
            decorateFromSettingsString(response.data.updateTrip);
            setTrip(response.data.updateTrip);
            setLoading(false);
            if (receipt.items.length > 0) {
                navigate("/" + trip.id + "/expenses/assign/" + id, {replace: true});
                showInterstititalAd(trip, user);
            } else {

                let showAd = trip.expenseEntries?.length > 3 && (trip.expenseEntries || []).length % 4 === 0;
                if (showAd) {
                    showInterstititalAd(trip, user);

                }

                closeDialog();

            }


        } catch (e) {
            console.log(e);
            setError("Error occured while creating expense");
            setLoading(false);
        }

    }

    return (
        <Dialog

            TransitionComponent={Transition}

            fullScreen={isMobile}
            fullWidth={true}
            open={true}
            onClose={closeDialog}
        >
            <DialogTitle>{type === 1 ? 'Split Evenly' : type === 2 ? 'Split Unevenly' : "List items from your receipt"}
                <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>

            <DialogContent>
                {(type === 1 || type === 2) ?
                    <ExpenseStepper
                        noMultiplePayers={trip.version < 2}
                        setCurrency={setCurrency}
                        currency={currency}
                        amount={amount}
                        setAmount={setAmount}
                        setActiveParticipants={setActiveParticipants}
                        participants={trip.participants}
                        payers={payers}
                        payerAmounts={payerAmounts}
                        setPayerAmounts={setPayerAmounts}
                        setPayers={setPayers}
                        activeParticipants={activeParticipants}
                        description={description}
                        setDescription={setDescription}
                        addExpenseType={type}
                        setUnevenItems={setUnevenItems}
                    /> : <Grid container>
                        <Grid textAlign={"center"} item xs={4}>

                        </Grid>
                        <Grid textAlign={"center"} item xs={4}>

                        </Grid>
                        <Grid textAlign={"center"} item xs={4}>

                        </Grid>


                        <Grid style={{marginTop: 10}} item xs={12}>
                            {(receiptImageLoading) ?


                                <Grid container>
                                    <Box sx={{width: '100%'}}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Box sx={{width: '100%', mr: 1}}>
                                                <LinearProgress
                                                    variant={imageLoadingPhase === 1 ? "determinate" : "indeterminate"}
                                                    value={progress}/>
                                            </Box>
                                            <Box sx={{minWidth: 35}}>
                                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                                    progress,
                                                )}%`}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Grid textAlign={"center"} item xs={12}>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            {imageLoadingPhaseDescriptions[imageLoadingPhase]}
                                        </Typography>
                                    </Grid>
                                    <Grid textAlign={"center"} item xs={12}>
                                        <Lottie options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: scanReceiptAnimation
                                        }} height={300} isPaused={false} isStopped={false} />
                                    </Grid>
                                </Grid>
                                : <Grid container>
                                    <Grid item xs={12}>
                                        <ReceiptEditor currency={currency} setCurrency={setCurrency} receipt={receipt}
                                                       setReceipt={setReceipt}/>

                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: 20}}>
                                        {canvasWidth &&
                                            <Typography variant={"body2"} textAlign={"center"}>Something Doesn't match
                                                up? Refer to the image below to correct manually</Typography>}
                                    </Grid>
                                    <Grid ref={canvasParentRef} item xs={12}>
                                        {canvasWidth &&
                                            <canvas style={{borderRadius: 10}} ref={handleCanvasCallback} id="myCanvas"
                                                    width={canvasWidth} height="100"></canvas>}
                                    </Grid>
                                </Grid>
                            }
                        </Grid>


                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={loading}
                    disabled={disabledCondition()}
                    type="submit"
                    variant="contained"
                    onClick={createEntry}
                    startIcon={<CheckCircleOutlineOutlinedIcon/>}

                >
                    Add
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AddExpenseDialog;