import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {useLocation, useNavigate} from "react-router-dom";
import {BEGIN_ID_TIMESTAMP, CurrencySymbol, goBack} from "../Trip/Utils";

function ManualEntryDialog({trip }) {
    const [amount, setAmount] = React.useState(null);
    const [onLoading, setOnLoading] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [payer, setPayer] = React.useState("");
    const [payee, setPayee] = React.useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const closeDialog = ()=> {
        goBack(location, navigate, '/' + trip.id + '/expenses')
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Add Manual Correction"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant={"body2"}>
                        If someone in your group already paid someone and you would like to reflect that in calculations.
                        Use this form to create an entry that will appear under "paid" section of the result page.
                    </Typography>

                        <InputLabel style={{marginTop: 10}} id="manual-correction-payer-label">Payer</InputLabel>
                        <Select
                            labelId="manual-correction-payer-label"
                            fullWidth={true}
                            value={payer}
                            variant={"outlined"}
                            onChange={(e)=> {setPayer(e.target.value)}}
                        >
                            {
                                trip && trip.participants.map(p => (
                                    <MenuItem key={"trip_participant_" + p} value={p}>{p}</MenuItem>))
                            }
                        </Select>
                        <InputLabel id="manual-correction-payee-label">Payee</InputLabel>
                        <Select
                            fullWidth={true}
                            value={payee}
                            labelId="manual-correction-payee-label"
                            variant={"outlined"}
                            onChange={(e)=> {setPayee(e.target.value)}}
                        >
                            {
                                trip && trip.participants.map(p => (
                                    <MenuItem key={"payee_" + p} value={p}>{p}</MenuItem>))
                            }
                        </Select>

                    <FormControl margin="normal" variant="standard" fullWidth={true}>
                        <InputLabel htmlFor="manual-correction-amount-label">Amount</InputLabel>
                        <Input
                            variant="standard"
                            fullWidth={true}
                            labelId="manual-correction-amount-label"
                            type="number"
                            value={amount}
                            startAdornment={<InputAdornment position="start"><CurrencySymbol currency={trip.currency} /></InputAdornment>}
                            onChange={(e) => {
                                setAmount(e.target.value)
                            }}
                        />
                    </FormControl>
                </DialogContentText>
                <FormControl margin="normal" variant="standard" fullWidth={true}>
                    <TextField
                        fullWidth={true}
                        variant="outlined"
                        label="Comment (optional)"
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value)
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                {!onLoading && <Button color={"info"} onClick={closeDialog}>Cancel</Button>}
                <LoadingButton disabled={!payer || !payee || payer===payee || !amount } loading={onLoading} color={"success"} onClick={async () => {
                    setOnLoading(true);
                    try {
                        await API.graphql(graphqlOperation(updateTrip,{
                            input : {
                                id : trip.id,
                                paidEntries : [...(trip.paidEntries || []), {
                                    id : (Date.now() - BEGIN_ID_TIMESTAMP),
                                    description: "manual correction",
                                    payer : payer,
                                    payee : payee,
                                    amount : amount,
                                    comment: comment
                                }]
                            }
                        }));
                        setOnLoading(false);
                        closeDialog();

                    } catch (e) {
                        console.log(e);
                        setOnLoading(false);
                    }

                }} autoFocus>
                    Add Paid
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ManualEntryDialog;