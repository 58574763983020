import {createSvgIcon} from "@mui/material";

export const Bolt = createSvgIcon(
    <>
        <path d="M14.7682 8.24002C14.6882 8.08003 14.5682 8.00002 14.4082 8.00002H9.6882L14.3682 0.6C14.4482 0.48 14.4482 0.320016 14.3682 0.200016C14.2882 0.0800156 14.1682 0 14.0082 0H7.60821C7.44823 0 7.32823 0.0800156 7.24821 0.200016L1.24821 12.2C1.1682 12.32 1.1682 12.48 1.24821 12.6C1.32823 12.72 1.48821 12.8 1.60821 12.8H5.7282L1.24821 23.48C1.1682 23.64 1.24821 23.84 1.4082 23.96C1.44818 24 1.5282 24 1.60821 24C1.72821 24 1.84821 23.96 1.92823 23.88L14.7282 8.67999C14.8082 8.55998 14.8482 8.4 14.7682 8.24002ZM3.00818 21.32L6.6882 12.52C6.72818 12.4 6.72818 12.28 6.64821 12.16C6.5682 12.08 6.4482 12 6.3282 12H2.2482L7.84821 0.800016H13.2882L8.56821 8.19998C8.4882 8.31998 8.4882 8.47997 8.56821 8.59997C8.64823 8.71997 8.76823 8.79998 8.92821 8.79998H13.5682L3.00818 21.32Z" fill="#327DD9" stroke="#327DD9" stroke-width="0.5"/>
    </>,
    'Home',
);

export const CoolDone = createSvgIcon(
    <>
            <g clip-path="url(#clip0_40_719)">
                <path d="M8.75 0.3125L2.9375 6.70312L1.25 5.42188H0.3125L2.9375 9.6875L9.6875 0.3125H8.75Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_40_719">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
    </>
)