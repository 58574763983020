import React, {useEffect} from 'react';
import {Backdrop, Card, CardHeader, CircularProgress, Grid, IconButton, Stack, TextField} from "@mui/material";
import PersonRemoveAlt1RoundedIcon from "@mui/icons-material/PersonRemoveAlt1Rounded";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import {getEmailFromUser, goForwardTo, isMobile, logActivity} from "../Trip/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import AddLinkIcon from "@mui/icons-material/AddLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {serializeGroup} from "../Trip/Utils/expenseUtils";

function GroupMembers({group, user}) {

    const username = getEmailFromUser(user);

    const getUserParticipantFromGroup = (group)=> {
        let participant = null;
        if(group.assignedUsers){
            for(let prop in group.assignedUsers){
                if(group.assignedUsers[prop] === username){
                    participant = Number(prop);
                }
            }
        }
        return participant;
    }

    const navigate = useNavigate();
    const location = useLocation();
    let p = getUserParticipantFromGroup(group)
    const [userParticipant, setUserParticipant] = React.useState(p || p === 0 ? p : 1911);

    const [loading, setLoading] = React.useState(false);

    const involvementMap = {};


    useEffect(()=> {
        let p = getUserParticipantFromGroup(group);

        setUserParticipant(p || p === 0 ? p : 1911)
    }, [group]);


    group.expenseEntries?.forEach(e => {
        e.participants.forEach(p => involvementMap[p] = true);
    });

    return (<Stack
            style={{marginTop: '10px'}}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            {!isMobile() && <Grid container>
                <Grid textAlign={"center"} item xs={12}>
                    <Typography variant={"overline"}>Participants</Typography>
                </Grid>
            </Grid>}

            { username && group.version > 1 &&
                <Grid container>
                    <Grid textAlign={"center"} item xs={12}>
                        <FormControl margin="normal" variant="standard" fullWidth={true}>
                            <TextField
                                fullWidth={true}
                                autoFocus={true}
                                label={"Who Are You In This Group?"}
                                select
                                value={userParticipant}
                                variant={"outlined"}
                                renderValue={(v) => {
                                    return group.participants[v]
                                }}
                                onChange={async (event) => {

                                    let participant = (event.target.value || event.target.value  === 0) ? Number(event.target.value) : null;
                                    if(participant === 1911) {
                                        participant = null;
                                    }
                                    if(group.assignedUsers[participant] && group.assignedUsers[participant] !== username ) {
                                        goForwardTo(location, navigate, `/${group.id}/members/reassign/${participant}`);
                                    } else {
                                        setLoading(true);
                                        try {

                                            let clone = JSON.parse(JSON.stringify(group));


                                            clone.assignedUsers = clone.assignedUsers || {};
                                            for(let prop in clone.assignedUsers) {
                                                if(clone.assignedUsers[prop] === username) {
                                                    delete clone.assignedUsers[prop]
                                                }
                                            }
                                            if(participant !== null) {
                                                logActivity('participantAssumed', {...group , data : { participant: group.participants[participant] }  }, user)
                                                clone.assignedUsers[participant] = username;
                                            }


                                            let response = await API.graphql(graphqlOperation(updateTrip, {
                                                input : serializeGroup(clone)
                                            }));
                                            setUserParticipant(participant);
                                        } catch (e) {

                                        } finally {
                                            setLoading(false);
                                        }
                                    }

                                }}
                            >
                                <MenuItem key={'userParticipant-participant-'} value={1911}></MenuItem>
                                {
                                    group?.participants.map((name, index) => (
                                        <MenuItem key={'userParticipant-participant-' + index}
                                                  value={index}>{name}</MenuItem>))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                </Grid>

            }

            {
                group.linkedParticipants.map((lp, index) => <Card key={"member_card_" + lp.join("_")}
                                                                  sx={{maxWidth: 450, width: '100%'}} elevation={4}
                    >
                        <CardHeader
                            action={

                                lp.length > 1 ? <IconButton

                                    onClick={() => {
                                        goForwardTo(location, navigate, `/${group.id}/members/unlinkMembers/${lp.join("_")}`);

                                    }}

                                ><LinkOffIcon/> </IconButton> : <span onClick={() => {
                                    if (involvementMap[lp[0]]) {
                                        goForwardTo(location, navigate, `/${group.id}/members/removeMember/${lp[0]}`);
                                    }
                                }
                                }><IconButton onClick={() => {
                                    goForwardTo(location, navigate, `/${group.id}/members/removeMember/${lp[0]}`);

                                }} disabled={involvementMap[lp[0]]}><PersonRemoveAlt1RoundedIcon/></IconButton></span>}
                            style={{paddingBottom: 0, paddingTop: 5}}
                            title={<Typography style={{lineHeight: 2, fontWeight: "bold", display: "inherit"}}>
                                {lp.map(p => group.participants[p]).join(" & ")}
                            </Typography>}
                        />
                    </Card>
                )
            }

            <Button variant={"outlined"} onClick={() => {
                goForwardTo(location, navigate, `/${group.id}/members/addMember`);
            }}
                    startIcon={<PersonAddRoundedIcon/>}>Add New Member</Button>

            <>
                <Typography textAlign={"center"} style={{paddingTop: 4, color: "#5A7184"}}>
                    Some people are paying together?
                </Typography>
                <Typography style={{marginTop: -3, marginBottom: 40, paddingTop: 10, paddingBottom: 60}}
                            textAlign={"center"}>
                    <Button variant={"contained"} size={"small"} startIcon={<AddLinkIcon/>} onClick={() => {
                        goForwardTo(location, navigate, '/' + group.id + '/members/link');
                    }}>{group.linkedParticipants?.length > 0 ? "Link/Unlink Them" : "Link Them"}</Button>
                </Typography></>
        </Stack>
    )
        ;
}

export default GroupMembers;