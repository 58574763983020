import * as React from "react"

const SvgComponent = (props) => (
    <svg
        width={43}
        height={44}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >

        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.68 9.26v26.338A6.654 6.654 0 0 0 8.33 42.25H34.67a6.654 6.654 0 0 0 6.651-6.652V9.261a6.654 6.654 0 0 0-6.651-6.652H8.33A6.654 6.654 0 0 0 1.68 9.261Zm32.989-4.635a4.639 4.639 0 0 1 4.636 4.636v26.337a4.64 4.64 0 0 1-4.636 4.636H8.33a4.639 4.639 0 0 1-4.636-4.636V9.261a4.639 4.639 0 0 1 4.636-4.636H34.67Z"
            fill="#B3B4B6"
            stroke="#fff"
        />
        <path  transform="translate(10.75 11.38)"              fill="rgb(50, 125, 217)"
                            d="M20 2H4c-1 0-2 .9-2 2v3.01c0 .72.43 1.34 1 1.69V20c0 1.1 1.1 2 2 2h14c.9 0 2-.9 2-2V8.7c.57-.35 1-.97 1-1.69V4c0-1.1-1-2-2-2zm-5 12H9v-2h6v2zm5-7H4V4h16v3z"></path>
        <defs>
            <clipPath id="newGroupClip">
                <path
                    fill="#fff"
                    transform="translate(10.75 11.68)"
                    d="M0 0h21.5v21.5H0z"
                />
            </clipPath>
        </defs>
    </svg>
)

export default SvgComponent
