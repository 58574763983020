import React, {useEffect, useState} from 'react';
import {Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {getEmailFromUser, goBack, goForwardTo, isMobile, logActivity} from "../Trip/Utils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Auth} from "aws-amplify";
import SignInWithGoogle from "../SignInWithGoogle";
import SignInWithApple from "../SignIns/SignInWithApple";
import BigLoginSvg from "../SvgImages/BigLoginSvg";
import Typography from "@mui/material/Typography";
import {LoginOutlined, LogoutOutlined, NoAccountsOutlined} from "@mui/icons-material";
import SingleFieldUpdateDialog from "./SingleFieldUpdateDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import GetTheAppPanel from "../GetTheAppPanel";
import AppRater from "../AppRater";
import SignInWithEmail from "../SignIns/SignInWithEmail";
import CloseDialogButton from "./CloseDialogButton";
import PaymentsIcon from '@mui/icons-material/Payments';
import RemoveAdsSvg from "../SvgImages/RemoveAdsSvg";
function LoginDialog({group, user, setUser, setGroup}) {

    const [loginError, setLoginError] = useState(null);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [confirmError, setConfirmError] = React.useState('');
    const [signOutLoading, setSignOutLoading] = React.useState(false);
    const [cognitoSignInForm, setCognitoSignInForm] = React.useState(false);

    const [purchaseLoading, setPurchaseLoading] = React.useState(false);

    const [purchaseError, setPurchaseError] = React.useState("");

    const [showingAds, setShowingAds] = React.useState(!localStorage.SPLITPAL_REMOVE_ADS);

    const params = useParams();





    let goBackUrl = '/';
    if (params.group) {
        goBackUrl += params.group + '/';
    }
    if (params.view) {
        goBackUrl += params.view;
    }




    useEffect(() => {
        if (user || loginError) {
            onClose()
        }
    }, [user, loginError]);

    const navigate = useNavigate();
    const location = useLocation();

    const onClose = () => {
        goBack(location, navigate, goBackUrl);
    }


    return (


        <Dialog
            fullWidth={true}
            fullScreen={isMobile()}
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Account"}
                <CloseDialogButton onClick={onClose} />
            </DialogTitle>
            <DialogContent>

                { !cognitoSignInForm && <Grid container style={{
                    paddingTop: 20 , marginBottom: 20, paddingBottom: 20}}  >
                    <Grid item xs={12} textAlign={"center"}>
                        <BigLoginSvg/>
                    </Grid>
                    { user && <Grid item xs={12} textAlign={"center"}>
                        <Grid item xs={12} textAlign={"center"}>
                            <Typography variant={"h6"} style={{fontSize: "16px", fontWeight: "500"}}>
                                <b>{getEmailFromUser(user)}</b>
                            </Typography>

                        </Grid>
                    </Grid> }
                </Grid> }

                <Grid style={{
                    paddingBottom: 16,
                    background: "rgba(179, 180, 182, 0.12)",
                    paddingLeft: 0,
                    paddingRight: 20,
                    paddingTop: 16,
                    marginRight: 10,
                    marginLeft: 10,
                    marginTop: 0,
                    marginBottom: 10,
                    width: "calc(100% - 20px)",
                    borderRadius: 12
                }} container spacing={3}>


                    {
                        cognitoSignInForm && <SignInWithEmail setCognitoSignInForm={setCognitoSignInForm}/>
                    }

                    { !cognitoSignInForm && !user && <>
                        <Grid item xs={12} style={{paddingTop: 12}} textAlign={"center"}>
                            <Card><SignInWithGoogle setUser={setUser}/></Card>
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <Card>
                                <SignInWithApple setUser={setUser}/></Card>
                        </Grid>


                        <Grid item xs={12} textAlign={"center"}>
                            <Card>
                            <Button fullWidth={true} startIcon={<LoginOutlined/>}
                                    onClick={()=> { setCognitoSignInForm(true)}}
                            >Sign Up/In With Email</Button></Card>
                        </Grid>


                    </> }
                    {!cognitoSignInForm && (
                        user ? <>

                            <Grid item xs={12} textAlign={"center"} style={{paddingTop: 0}}>
                                <Card>
                                <LoadingButton fullWidth={true} loading={signOutLoading} startIcon={<LogoutOutlined/>}
                                               onClick={async () => {
                                                   logActivity("SignOut", null, user);
                                                   setSignOutLoading(true);
                                                   await Auth.signOut();
                                                   if(!!window.cordova){
                                                       try {
                                                           window.plugins.googleplus.disconnect(()=>{
                                                               console.log('disconnected.');
                                                           });
                                                       } catch(e) {
                                                           console.log('error while disconnecting ', e);
                                                       }

                                                   }

                                                   setUser(null);
                                                   setSignOutLoading(false);
                                                   goForwardTo(location, navigate, '/');
                                                   //onClose();

                                               }} variant={"text"}>Sign Out</LoadingButton></Card>
                            </Grid>

                            <Grid item xs={12} textAlign={"center"}>
                                <Card>
                                <Button fullWidth={true} startIcon={<PaymentsIcon/>} onClick={() => {
                                    let url = '/';
                                    if(group) {
                                        url+= group.id + '/';
                                    }
                                    url += 'home/payments';
                                    goForwardTo(location, navigate, url);
                                }}>Pay Back Methods</Button>
                                </Card>
                            </Grid>


                            { purchaseError && <Grid item xs={12} textAlign={"center"}><Typography style={{ color: 'red'}} variant={"caption"}>Remove Ads: {purchaseError} </Typography></Grid>}

                            <Grid item xs={12} textAlign={"center"}>
                                <Card>

                                <Button fullWidth={true} startIcon={<NoAccountsOutlined/>} onClick={() => {
                                    setConfirmDelete(true);
                                }} color={"error"}  >Close Permanently</Button>
                                </Card>
                            </Grid>
                            {window.cordova ? <AppRater user={user}/> : <GetTheAppPanel user={user}/>}
                        </> : !window.cordova ? <GetTheAppPanel user={user}/> : <AppRater user={user}/>
                    )
                    }

                    { window.cordova && !showingAds && <Grid item xs={12} textAlign={"center"}><Typography style={{ color: 'green'}}>Ad Free, Thanks for Support!</Typography></Grid> }

                    { window.cordova && showingAds &&
                        <Grid item xs={12} textAlign={"center"}>
                            <Card>

                                <LoadingButton loading={purchaseLoading} fullWidth={true} startIcon={<RemoveAdsSvg/>} onClick={async () => {
                                    console.log('trying to get ', window.removeAdsProductId , window.storePlatform);
                                    // eslint-disable-next-line no-undef
                                    let product = CdvPurchase.store.get(window.removeAdsProductId, window.storePlatform);
                                    setPurchaseError("");
                                    if(product) {
                                        if(!product.owned && product.canPurchase) {
                                            setPurchaseLoading(true);
                                            try {
                                                let offer = product.getOffer();
                                                let resp = await offer.order();
                                                if(resp?.isError) {
                                                    throw new Error(resp.message);
                                                }
                                                console.log('hmmm response', resp);
                                                setShowingAds(false);
                                                // eslint-disable-next-line no-undef
                                                await CdvPurchase.store.update();
                                                // eslint-disable-next-line no-undef
                                                product = CdvPurchase.store.get(window.removeAdsProductId, window.storePlatform);
                                                logActivity('successfullyPurchased', { data : localStorage.SPLITPAL_REMOVE_ADS  }, user);

                                            } catch(e) {
                                                if(e.message !== 'USER_CANCELLED') {
                                                    setPurchaseError(e.message);
                                                }

                                                logActivity('errorWhilePurchasing', { data : e }, user);
                                            } finally {
                                                setPurchaseLoading(false);
                                            }

                                        }
                                    }
                                }} >Remove Ads</LoadingButton>
                            </Card>
                        </Grid>
                    }
                </Grid>

                <SingleFieldUpdateDialog dialogTitle={"Are you sure?"} noField={true}
                                         dialogDescription={<><span><b>You are about to permanently remove the account and all associated data.</b></span><br/><br/><span>
                                             Please note that access links to any of the groups that you were part of would still be available for other participants.</span></>}
                                         loading={confirmLoading}
                                         error={confirmError}
                                         onUpdate={async()=> {
                                             setConfirmLoading(true);
                                             try {
                                                 await logActivity("PermanentlyClose", null, user);

                                                 localStorage.clear();
                                                 await Auth.signOut();
                                                 setUser(null);

                                                 setConfirmDelete(false);
                                                 if(setGroup){
                                                     setGroup(null)
                                                 }
                                                 goForwardTo(location, navigate, '/');



                                             }catch(e){
                                                 setConfirmError("Error occurred while permanently deleting your account.");
                                             } finally {
                                                 setConfirmLoading(false);
                                             }

                                         }}
                                         open={confirmDelete} onCancel={()=>{ setConfirmDelete(false) } } updateButtonText={"confirm"} />

            </DialogContent>
            <DialogActions>
                { cognitoSignInForm && <Button variant={"text"} onClick={ ()=> { setCognitoSignInForm(false);}}>Back</Button> }
            </DialogActions>
        </Dialog>

    );
}

export default LoginDialog;