import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {decorateFromSettingsString, getBitmapFromArray, goBack, logActivity, updateSettings} from "../../Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../../graphql/mutations";
import {serializeGroup} from "../../Trip/Utils/expenseUtils";
import CloseDialogButton from "../CloseDialogButton";

function UnlinkParticipantsDialog({ user, group, setGroup}) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const closeDialog = () => {
        goBack(location, navigate, group ? '/' + group.id + '/results' : '/');
    }

    let index = group.linkedParticipants.map(lp=> lp.join("_")).indexOf(params.participant);
    let key = getBitmapFromArray(params.participant.split('_'));
    let involved = !!group.lpUsedMap[key];
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Unlink Participants
            <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            { index < 0 ?
                <DialogContent>
                    <Alert severity={"warning"}>Looks like someone else has already unlinked these participants.</Alert>
                </DialogContent> : involved ? <DialogContent>
                    <Alert severity={"error"}>Cannot unlink these participants while they have "paid" entries on the settlement screen.
                    </Alert>
                    </DialogContent> :
                <DialogContent>
                <div>
                    Are you sure you want to unlink
                </div>
                <div>
                    <b>{group.linkedParticipants[index].map(p=> group.participants[p]).join(" & ")}</b>?
                </div>

            </DialogContent>}
            <DialogActions>
                <Button color={"info"} onClick={closeDialog}> Cancel</Button>
                { index >= 0 && !involved ? <LoadingButton loading={loading} color={"primary"} onClick={async()=> {
                    setLoading(true);
                    try {
                        let clone = JSON.parse(JSON.stringify(group));
                        logActivity("unlink", {...group, data : {
                            participantString : group.linkedParticipants[index].map(p=> group.participants[p]).join(" & ")
                        } }, user);
                        clone.linkedParticipants.splice(index,1);
                        let response = await API.graphql(graphqlOperation(updateTrip, {
                            input : serializeGroup(clone)
                        }));
                        decorateFromSettingsString(response.data.updateTrip);
                        setGroup(response.data.updateTrip);
                    }catch(e){
                        console.log('uh oh error occurred');
                    } finally {
                        setLoading(false);
                        closeDialog();
                    }
                } } >
                    Unlink
                </LoadingButton> : <></> }
            </DialogActions>
        </Dialog>
    );
}

export default UnlinkParticipantsDialog;