import React from 'react';
import {Currencies} from "./Utils";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {getCurrencySymbol} from "./Currencies";

function CurrencySelector({short, currency, setCurrency, fullWidth}) {
    return (
        <TextField
            id="standard-select-currency"
            InputProps={{
                disableUnderline: true
            }}
            variant={fullWidth ? "outlined" : "standard"}
            select
            fullWidth={fullWidth}
            label= { short ? "" : ""}
            value={currency}
            onChange={(event)=>{
                setCurrency(event.target.value);} }
            helperText={ short ? "" : "Please select your currency"}

        >
            {Currencies.map((option) => (
                <MenuItem key={option.abbreviation} value={option.abbreviation}>
                    { short && option.abbreviation === currency ?
                        <span style={{ width: 60}} dangerouslySetInnerHTML={{ __html: getCurrencySymbol(currency)}}></span> :
                        (option.currency + " (" + option.abbreviation + ")")}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default CurrencySelector;