import React, {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../../graphql/mutations";
import SingleFieldUpdateDialog from "../SingleFieldUpdateDialog";
import {decorateFromSettingsString, goBack, logActivity} from "../../Trip/Utils";
import {serializeGroup} from "../../Trip/Utils/expenseUtils";

function AddParticipantDialog({group, setGroup}) {
    const [participants, setParticipants] = useState(group.participants);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <SingleFieldUpdateDialog disableCondition={(value) => {
            if (!value) return true;
            let result = false;
            participants.forEach(p => {
                if (p === value) {
                    result = true;
                }
            })
            return result;

        }} fullScreen={false} open={true}
                                 dialogTitle={"Add New Member"}
                                 updateButtonText={"Add"}
                                 inputLabel={"Member Name"}
                                 loading={loading}
                                 error={error}
                                 onUpdate={async (participant) => {
                                     try {
                                         let clone = Array.from(participants);
                                         clone.push(participant);
                                         setParticipants(clone);
                                         setLoading(true);
                                         let input = {
                                             id: group.id,
                                             participants : clone
                                         };
                                         if(group.version > 1){
                                             group.participants = clone;
                                             input = serializeGroup(group);
                                         }
                                         let response = await API.graphql(graphqlOperation(updateTrip, {
                                             input
                                         }));
                                         await logActivity("addParticipant", { ...group, data: { participant } })
                                         setLoading(false);
                                         setError("");
                                         decorateFromSettingsString(response.data.updateTrip);
                                         setGroup(response.data.updateTrip);
                                         goBack(location, navigate, `/${group.id}/members`);
                                     } catch (e) {
                                         setError("error occured while adding participant");
                                     }
                                 }} onCancel={() => {
                                     goBack(location, navigate, `/${group.id}/members`);
        }}/>
    );
}

export default AddParticipantDialog;