import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import {InputAdornment, TextField} from "@mui/material";
import {CurrencySymbol} from "../../Trip/Utils";
import ActiveParticipantsGetter from "../../CreateEntry/ActiveParticipantsGetter";
import Typography from "@mui/material/Typography";
import UnevenSplitEditor from "../../CreateEntry/UnevenSplitEditor";
import {renderCurrency} from "../../Trip/Currencies";
import PayersGetter from "../../CreateEntry/PayersGetter";

export default function ExpenseStepper({
                                         setAmount, amount, currency,
                                         setCurrency,
                                         setActiveParticipants,
                                         participants,
                                         payers,
                                         setPayers,
                                         setPayerAmounts,
                                         payerAmounts,
                                         activeParticipants,
                                         description,
                                         setDescription, setDate,
                                         addExpenseType,
                                         setUnevenItems, noMultiplePayers
                                     }) {

    const [activeStep, setActiveStep] = React.useState(0);

    const stepMap = {
        'setDescription': {
            label: 'What was the expense?',
            value: description,
            disableCondition: ()=> !description,
            description: <>
                <FormControl margin="normal" variant="standard" fullWidth={true}>
                    <TextField
                        fullWidth={true}
                        autoFocus={true}
                        placeholder="for example groceries, beers "
                        value={description}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setActiveStep(1);
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                    />


                </FormControl>

            </>,
        },
        'setAmount': {
            label: 'How much was the expense?',
            value: renderCurrency(amount, currency),
            disableCondition: ()=> !amount || amount <= 0,
            description: <FormControl variant="standard" fullWidth={true}>
                <TextField
                    margin={"dense"}
                    autoFocus={true}
                    size="small"
                    type="number"
                    value={amount}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            setActiveStep(activeStep+1);
                            e.preventDefault();
                        }
                    }}
                    onChange={(e) => {
                        setAmount(e.target.value && Number(e.target.value));
                        if(e.target.value && Number(e.target.value) && payerAmounts.length === 1) {
                            setPayerAmounts([Number(e.target.value)]);
                        }
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><CurrencySymbol
                            currency={currency} setCurrency={setCurrency}/></InputAdornment>,
                    }}

                />
            </FormControl>,
        },
        'setParticipants': {
            label: 'Who Participated?',
            disableCondition: ()=> activeParticipants.length < 2,
            value: activeParticipants.length === participants.length ? 'Everyone' :
                activeParticipants.length + ' out of ' + participants.length
            ,
            description: <><ActiveParticipantsGetter
                setActiveParticipants={(v) => {
                    setPayers([]); // drop payers
                    setActiveParticipants(v);
                }}
                participantNames={participants}
                initialParticipants={activeParticipants}
                participants={participants.map((p, i) => i)}/>

                {activeParticipants && activeParticipants.length < 2 &&
                    <Typography color={"red"} variant={"caption"}>At least two participants required.</Typography>}
            </>,
        },

        'setPayers': {
            label: 'Who Paid for it?',
            disableCondition: ()=> payers.length === 0
                ,
            value: payers.map(p=> participants[p]).join(','),
            description: <PayersGetter currency={currency} amount={amount} setPayers={setPayers} payers={payers}
                                       setPayerAmounts={setPayerAmounts}
                                       payerAmounts={payerAmounts}
                                       noMultiplePayers={noMultiplePayers}
                                       participantNames={participants}
                                       participants={activeParticipants}/>,
        },
        'setUnevenItems' : {
            label: `Expense distribution`,
            disableCondition: ()=> true,
            value: <></>,
            description: <UnevenSplitEditor currency={currency} activeParticipants={activeParticipants}
                                            participantNames={participants}
                                            payers={payers}
                                            amount={amount}
                                            setUnevenItems={setUnevenItems} noTitle={true}/>
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let steps = [];
    if(addExpenseType === 1){
        steps.push(stepMap.setDescription, stepMap.setAmount, stepMap.setParticipants, stepMap.setPayers);
    } else if(addExpenseType === 3){
        steps.push(stepMap.setDescription, stepMap.setParticipants, stepMap.setPayers);
    } else if(addExpenseType === 2){
        steps.push(stepMap.setDescription, stepMap.setAmount, stepMap.setParticipants, stepMap.setPayers, stepMap.setUnevenItems);
    }

    return (
        <Box sx={{maxWidth: 400}}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel onClick={() => {
                            setActiveStep(index);
                        }}>
                            {step.label}
                            {activeStep !== index && <span style={{paddingLeft: 5}}>{step.value}</span>}
                        </StepLabel>
                        <StepContent>
                            {step.description}
                            <Box sx={{mb: 2}}>
                                <div>
                                    {index < steps.length - 1 && <Button
                                        disabled={ steps[activeStep].disableCondition() }
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{mt: 1, mr: 1}}
                                    >Next
                                    </Button>}
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{mt: 1, mr: 1}}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
