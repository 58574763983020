import React from 'react';
import {Dialog, DialogActions, DialogContent, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {goBack, logActivity} from "../Trip/Utils";
import BuyMeACoffee from "../SvgImages/BuyMeACoffee";

function DonateDialog({trip, user}) {

    const navigate = useNavigate();
    const location = useLocation();

    const closeDialog = () => {
        goBack(location, navigate, trip ? '/' + trip.id + '/results' : '/');
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography textAlign={"center"} variant={"h5"}>
                            You Are a True Pal to SplitPal!
                        </Typography>
                    </Grid>

                    <Grid item style={{ marginTop: 20}} xs={12}>
                        <Typography variant={"body2"} textAlign={"center"}>
                            Help Us Keep Going
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 25}}  textAlign={"center"}>

                        <BuyMeACoffee onClick={()=> {
                            let link = `https://www.buymeacoffee.com/SplitPal`;
                            if(window.cordova){
                                window.cordova.InAppBrowser.open(link,'_system');
                            } else {
                                window.open(link);
                            }
                            logActivity("DonationAttempted", null, user);
                        }}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}> Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DonateDialog;