import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Table,
    TableBody,
    TableContainer
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CurrencySymbol, goBack, isMobile} from "../Trip/Utils";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Alert from "@mui/material/Alert";
import {renderCurrency} from "../Trip/Currencies";
import CloseDialogButton from "./CloseDialogButton";
import {useTheme} from "@mui/material/styles";

function PendingPaymentExpenseDialog({trip}) {
    let theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    let payer = Number(params.payer);
    let payee = Number(params.payee);
    let leftLp = trip.linkedParticipants[payer];
    let rightLp = trip.linkedParticipants[payee];

    const isValid = leftLp && rightLp;

    let payerString = "";
    let payeeString = "";
    if(isValid){
        payerString = leftLp.map(p=> trip.participants[p]).join(" & ");
        payeeString = rightLp.map(p=> trip.participants[p]).join(" & ");
    }





    let leftArray = [];
    let rightArray = [];
    let containsExisitingPayement = false;
    if(isValid){
        for (const prop in trip.ownage.entriesOwnageMap) {
            let e = trip.ownage.entriesOwnageMap[prop];
            let expenseOwnageMatrix = e.expenseOwnageMatrix;
            for (const left of leftLp) {
                for (const right of rightLp) {
                    if(expenseOwnageMatrix[left][right] > 0){
                        if(e.expenseEntry.type === 4){
                            containsExisitingPayement = true;
                        }
                        let amount = expenseOwnageMatrix[left][right];
                        leftArray.push({
                            amount,
                            type: e.expenseEntry.type,
                            subPayer: leftLp.length > 1 ? trip.participants[left] : null,
                            subPayee: rightLp.length > 1 ? trip.participants[right] : null,
                            description: e.expenseEntry.description
                        })
                    }
                    if(expenseOwnageMatrix[right][left] > 0){
                        if(e.expenseEntry.type === 4){
                            containsExisitingPayement = true;
                        }
                        let amount = expenseOwnageMatrix[right][left]
                        rightArray.push({
                            amount,
                            type: e.expenseEntry.type,
                            subPayer: rightLp.length > 1 ? trip.participants[right] : null,
                            subPayee: leftLp.length > 1 ? trip.participants[left] : null,
                            description: e.expenseEntry.description
                        })
                    }
                }
            }


        }
    }



    const closeDialog = ()=> {
        goBack(location, navigate, '/' + trip.id + '/results')
    }

    const renderOwnageTable = (payer, payee, debtEntries, isMinus) =>{
        let total = 0;
        debtEntries.forEach(e => total +=e.amount);


        return (<TableContainer >
            <Table stickyHeader  size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell  align="left"><span style={{fontWeight: "bold"}}>{payer}</span> owes <span style={{fontWeight: "bold"}}>{payee}</span></TableCell>
                        <TableCell  align="right">{ isMinus && "-" }{renderCurrency(total, trip.currency)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        debtEntries.map(e =>
                                <TableRow
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell style={{ paddingTop: 0, paddingBottom: 0}} align="left">
                                        {e.type === 4 ? <span style={{color: theme.palette.secondary.main}}>existing payment</span> : e.description} {e.subPayer  ? ` (${e.subPayer})` : ""}
                                    </TableCell>
                                    <TableCell style={{ paddingTop: 0, paddingBottom: 0}} align="right">
                                        { isMinus && "-" }{renderCurrency(e.amount, trip.currency)}
                                    </TableCell>
                                </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>)
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}

            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Debt Details"} <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            { isValid ? trip.simplify ? <DialogContent>
                            details are not available when debt transactions are minimized
                    </DialogContent> :
                <DialogContent>
                    {renderOwnageTable(payerString, payeeString, leftArray)}
                    {rightArray.length > 0 && renderOwnageTable(payeeString, payerString, rightArray, true)}
                    <DialogContentText id="alert-dialog-description">
                        <Divider style={{paddingTop: 10}}/>
                        <div style={{float: 'right'}}>{renderCurrency(trip.ownage.totalOwnageMatrix[payer][payee], trip.currency)}</div>
                        {
                            containsExisitingPayement &&
                            <Typography style={{paddingTop: 20}} variant="caption" display="block">Note: looks
                                like {payeeString} already paid {payerString} , but expenses have been added, removed, or modified afterwards.
                                Please contact {payeeString} to see if they already indeed paid. If not,
                                or {payerString} has returned the payment, you can use undo feature in the
                                "paid" section to revert this. </Typography>
                        }
                    </DialogContentText>
                </DialogContent> :
                <DialogContent>
                    <Alert severity={"warning"}>Looks like someone else has updated the split (either removed expense or marked something as paid) and this entry is no longer valid.</Alert>
                </DialogContent>
            }
        </Dialog>
    );
}

export default PendingPaymentExpenseDialog;