import React from 'react';
import {Card, Collapse, List, ListItemButton, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import PendingPaymentRowListItem from "./PendingPaymentRowListItem";
import Divider from "@mui/material/Divider";
import {CurrencySymbol} from "../../Trip/Utils";
import {renderCurrency} from "../../Trip/Currencies";

function PendingPaymentRow({trip, rowIndex, ownageRow, expandedIntially}) {
    let linkedParticipants = trip.linkedParticipants[rowIndex];
    const [expanded, setExpanded] = React.useState(expandedIntially);



    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card>
            <List disablePadding={true}  sx={{ width: '100%' }}>
                <ListItemButton onClick={handleExpandClick}>
                    <Typography display="block" >
                        <span style={{fontWeight: "bold"}}>{linkedParticipants.map(p=> trip.participants[p]).join(" & ")}</span>
                    </Typography>
                    <ListItemText secondary={<span style={{paddingLeft: 5,paddingRight: 5,float: "right"}}>Owes {renderCurrency(ownageRow.reduce((n, a) => n + a, 0), trip.currency)}</span>} />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {
                        ownageRow.map((key,index) => key === 0 ? <></> : <PendingPaymentRowListItem row={rowIndex} col={index} trip={trip}/>)
                    }
                </Collapse>

            </List>
        </Card>


    );
}

export default PendingPaymentRow;
