import * as React from "react"

const SvgComponent = ({darkMode}) =>{
    let color1 = darkMode ? '#fff' : '#B3B4B6';
    let color2 = darkMode ? '#fff' : '#327DD9';

    return (
        <svg
        width={43}
        height={44}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m12 28.43 4.276 4.5 1.105-1.162-2.39-2.516H28v-1.644H14.99l2.391-2.515-1.105-1.163L12 28.43Z"
            fill={color1}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.575 16.535H10.75v2.015h16.825l-2.923 2.924 1.425 1.426 5.357-5.357-5.357-5.357-1.425 1.426 2.923 2.923Z"
            fill={color2}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.68 9.26v26.338A6.654 6.654 0 0 0 8.33 42.25H34.67a6.654 6.654 0 0 0 6.651-6.652V9.261a6.654 6.654 0 0 0-6.651-6.652H8.33A6.654 6.654 0 0 0 1.68 9.261Zm32.989-4.635a4.639 4.639 0 0 1 4.636 4.636v26.337a4.64 4.64 0 0 1-4.636 4.636H8.33a4.639 4.639 0 0 1-4.636-4.636V9.261a4.639 4.639 0 0 1 4.636-4.636H34.67Z"
            fill={color1}
            stroke="#fff"
        />
    </svg>
)}

export default SvgComponent
