//External imports

import {API, Auth, graphqlOperation} from "aws-amplify";
import React from 'react';
import jwt_decode from "jwt-decode";
import {logActivity} from "../Trip/Utils";
import AppleIcon from '@mui/icons-material/Apple';
import LoadingButton from "@mui/lab/LoadingButton";
import {useTheme} from "@mui/material/styles";
import {getPreSignedUrl} from "../../graphql/queries";
import {Backdrop, CircularProgress} from "@mui/material";


const SignInWithApple = ({outlined, setUser}) => {
    const [loading, setLoading] = React.useState(false);
    const theme = useTheme()
    return (
        <>
            <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        <LoadingButton style={{ color : theme.palette.text.primary }} loading={loading} startIcon={<AppleIcon/>}
                       onClick={()=>{


                           logActivity("SignInWithAppleAttempt", null, null);

                           if(!!window.cordova) {
                               setLoading(true);

                               window.cordova.plugins.SignInWithApple.signin(
                                   {requestedScopes: [1]},
                                   async function (success) {
                                       console.log(success);
                                       try {

                                           let response = await API.graphql(graphqlOperation(getPreSignedUrl, {
                                               id: "splitpal-signin-apple~|~" + success.identityToken })
                                           );
                                           let tokens = response.data.getPreSignedUrl.split("~|~");

                                           await Auth.signIn({
                                               username: tokens[0],
                                               password: tokens[1],
                                               attributes: {
                                                   email: tokens[0]
                                               },
                                           });
                                           logActivity("SignInWithAppleSuccess", { data: tokens[0]}, null);

                                       } catch (e) {
                                           logActivity("SignInWithAppleError", null, null);
                                           console.log(
                                               'Auth.federatedSignIn error', e
                                           )
                                       } finally {
                                           setLoading(false);
                                       }
                                   },
                                   function (err) {
                                       setLoading(false);
                                       console.error(err)
                                   }
                               )
                           }else {
                               Auth.federatedSignIn({
                                   provider : 'SignInWithApple'
                               });
                           }

                       } }
        >Sign In With Apple</LoadingButton></>
    )
}

export default SignInWithApple