import * as React from "react"
const SvgComponent = (props) => (
    <svg

        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        imageRendering="optimizeQuality"
        shapeRendering="geometricPrecision"
        style={{
            width: 36,
            height: 36,
            marginRight: 4
        }}
        textRendering="geometricPrecision"
        viewBox="0 0 1333.33 1333.33"
        {...props}
    >
        <g fillRule="nonzero">
            <path
                fill="#3396cd"
                d="M157.62 0h1018.09c87.04 0 157.62 70.58 157.62 157.62v1018.09c0 87.04-70.58 157.62-157.62 157.62H157.62C70.58 1333.33 0 1262.74 0 1175.7V157.62C0 70.58 70.59 0 157.63 0z"
            />
            <path
                fill="#fff"
                d="M995.24 271.32c28.68 47.29 41.55 96.05 41.55 157.62 0 196.38-167.62 451.42-303.67 630.49H422.45L297.88 314.34 570 288.5l66.17 530.15c61.5-100.31 137.55-257.93 137.55-365.32 0-58.84-10.08-98.84-25.84-131.78l247.36-50.23z"
                style={{
                    width: 48,
                }}
            />
        </g>
    </svg>
)
export default SvgComponent
