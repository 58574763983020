import * as React from "react"

const SvgComponent = (props) => (
    <svg height={38} width={38} xmlns="http://www.w3.org/2000/svg" {...props}>
        <title />
        <g fill="none" fillRule="evenodd" transform={"scale(1.58,1.58)"} strokeWidth={"4"}>
            <path
                d="M2.181 10C3.121 4.88 7.608 1 13 1c6.075 0 11 4.925 11 11s-4.925 11-11 11a11 11 0 0 1-9.798-5.995H4.34A9.996 9.996 0 0 0 13 22c5.523 0 10-4.477 10-10S18.523 2 13 2c-4.838 0-8.873 3.436-9.8 8H2.181ZM12.5 12.35l5.21 3.071.509-.861-4.719-2.806V5.032h-1v7.318ZM2.5 13 5 10H0l2.5 3Z"
                fill="rgb(50, 125, 217)"
            />
        </g>
    </svg>
)

export default SvgComponent
