import React from 'react';
import {Chip, Link, ListItem, ListItemText} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {useNavigate} from "react-router-dom";
import VenmoLogoSvg from "../../SvgImages/VenmoLogoSvg";
import {renderCurrency} from "../../Trip/Currencies";
import {useTheme} from "@mui/material/styles";

function PendingPaymentRowListItem({trip, row, col}) {

    const navigate = useNavigate();
    const theme = useTheme();
    const darkMode = theme.custom.mode === 'dark';

    return (
        <>


            <ListItem style={{paddingTop: 0, paddingBottom: 0,
                background: "rgba(179, 180, 182, 0.12)", paddingLeft: 10, paddingRight: 10, marginRight: 10, marginLeft: 10, marginBottom: 10,
                width: "calc(100% - 20px)",
                borderRadius: 12
            }}
                      key={"PendingPaymentRowListItem_" + row + "_" + col}
            >
                <ListItemText

                    primary={trip.linkedParticipants[col].map(p=> trip.participants[p]).join(' & ')}
                    secondary={
                        <Link component={"button"} onClick={() => {
                            navigate("/" + trip.id + "/results/details/" + row + "/" + col);
                        }}>
                            {renderCurrency(trip.ownage.totalOwnageMatrix[row][col],trip.currency)}</Link>
                    }
                />



                <Chip
                    style={{marginLeft: 5, backgroundColor: "#327DD9", fontSize: "12px"}}
                    color="primary" variant="filled"
                    size={'small'}
                    label="Pay"
                    onClick={() => {
                        navigate("/" + trip.id + "/results/markpaid/" + row + "/" + col + "/n");
                    }}
                    icon={<DoneIcon/>}
                />

            </ListItem>
        </>
    );
}

export default PendingPaymentRowListItem;