import React from 'react';
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {getMaterialDesignColorFromString, getTotalExpenseEntryAmount, goForwardTo} from "./Trip/Utils";
import Typography from "@mui/material/Typography";
import {renderCurrency} from "./Trip/Currencies";
import Button from "@mui/material/Button";
import HistoryIcon from "@mui/icons-material/History";
import {useLocation, useNavigate} from "react-router-dom";
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import { useTheme } from '@mui/material/styles';

function GroupTitleCard({group}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    let totalExpenses = 0;
    if (group) {
        group.expenseEntries.forEach(e => {
            totalExpenses += e.type === 4 ? 0 : getTotalExpenseEntryAmount(e) / e.rate;
        })
    }

    return (
        <Grid
            item xs={12}>
            <Card
                style={{background: theme.custom.groupCard, boxShadow: theme.custom.groupCardShadow}}>
                <CardHeader style={{paddingTop: 8}}
                            title={<Typography style={{color: "white", fontWeight: "bolder"}}
                                               variant={"h5"}>{group.name}</Typography>}
                            subheader={<span style={{color: "white"}}>Total spent</span>}
                />
                <CardContent style={{paddingTop: 0, paddingLeft: 16, paddingBottom: 8, paddingRight: 5}}>
                    <Typography variant={"h5"} style={{color: "white"}}>
                        {renderCurrency(totalExpenses, group.currency)}


                        <Button variant={"text"} endIcon={<ArrowDropDownCircleOutlinedIcon/>} onClick={() => {
                            goForwardTo(location, navigate, "/" + group.id + "/home/change");

                        }}
                                style={{borderColor: "white", float: "right", color: "white", marginTop: -6}}>{group.currency}</Button>

                        {/*{(group.pastSplits && group.pastSplits > 0) || (group.numberOfPastSplits && group.numberOfPastSplits > 0) ?*/}
                        {/*    <Button variant={"outlined"} startIcon={<HistoryIcon/>} onClick={() => {*/}
                        {/*        goForwardTo(location, navigate, "/" + group.id + "/expenses/archive");*/}

                        {/*    }}*/}
                        {/*            style={{borderColor: "white", float: "right", color: "white", marginTop: -6}}> Past*/}
                        {/*        Splits</Button> : <></>}*/}
                    </Typography>

                </CardContent>

            </Card>

        </Grid>
    );
}

export default GroupTitleCard;