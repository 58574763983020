import React, {useState} from 'react';
import SingleFieldUpdateDialog from "../SingleFieldUpdateDialog";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getEmailFromUser, goBack, logActivity} from "../../Trip/Utils";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../../graphql/mutations";
import {serializeGroup} from "../../Trip/Utils/expenseUtils";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

function ConfirmReplaceParticipantDialog({group, setGroup, user}) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const participant = Number(params.participant);

    const involvementMap = {};

    group.expenseEntries?.forEach(e => {
        e.participants.forEach(p => involvementMap[p] = true);
    });

    return (<SingleFieldUpdateDialog
        open={true}
        error={error}
        loading={loading}
        noField={true}
        onCancel={() => {
            goBack(location, navigate, `/${group.id}/members`);
        }}
        dialogTitle={"Reassign Participant"}
        dialogDescription={<Grid container>
            <Alert severity={"warning"}>
                <div>
                    <span style={{fontWeight: "bold"}}>{group.participants[participant]}</span> is already assigned to
                    another user. </div><div style={{paddingTop: 10}}>Do you still want to proceed?</div>
            </Alert>
        </Grid>}
        onUpdate={async ()=>{
            setLoading(true);
            try {
                let email = getEmailFromUser(user);
                let clone = JSON.parse(JSON.stringify(group));
                let p = participant;
                if(participant === 1911){
                    p = null;
                }

                clone.assignedUsers = clone.assignedUsers || {};
                for(let prop in clone.assignedUsers) {
                    if(clone.assignedUsers[prop] === email) {
                        delete clone.assignedUsers[prop]
                    }
                }
                if(p !== null) {
                    logActivity('participantAssumed', {...group , data : { replaced: group.assignedUsers[p],
                            p: group.participants[p] }  }, user)
                    clone.assignedUsers[p] = email;
                }

                setGroup(clone);
                let response = await API.graphql(graphqlOperation(updateTrip, {
                    input : serializeGroup(clone)
                }));
                goBack(location, navigate, `/${group.id}/members`);

            } catch (e) {

            } finally {
                setLoading(false);
            }
        }}
        updateButtonText={"Proceed"}

    />);
}

export default ConfirmReplaceParticipantDialog;