import React from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from 'react';
import {API, graphqlOperation} from "aws-amplify";
import {getArchivedSplits} from "../../graphql/queries";
import {BEGIN_ID_TIMESTAMP, getPaidEntryGroups, getTotalExpenseEntryAmount, goBack, isMobile} from "../Trip/Utils";
import {
    Backdrop, Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid, List, Paper,
    Stack, Tab, tableCellClasses
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {renderCurrency} from "../Trip/Currencies";
import PaidPaymentRow from "../Results/PaidPaymentRow/PaidPaymentRow";
import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {getParticipantPortionMap} from "../Trip/Utils/expenseUtils";
import {TabContext, TabList} from "@mui/lab";
import CloseDialogButton from "./CloseDialogButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const StyledRotatedTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function PastSplitDialog({group}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [split, setSplit] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [tabValue, setTabValue] = React.useState("1");
    let array = group.expenseEntries.sort((a, b)=> a.id - b.id);
    const closeDialog = ()=> {
        goBack(location, navigate, '/');
    }

    useEffect(()=>{
        (async()=> {
            if(params.entry === 'new'){
                setSplit(group)
            }else {
                setLoading(true);
                const splitData = await API.graphql(graphqlOperation(getArchivedSplits, {id: params.group}));
                let pastSplits = JSON.parse(splitData.data.getArchivedSplits);
                let pastSplitObject = pastSplits.find( e=> e.id == params.entry)
                setSplit(JSON.parse(pastSplitObject.body));
                setName(pastSplitObject.name);
                setLoading(false);
            }

        })();
    }, [] )

    const renderHtmlTable = (split)=> {

        let totalMap = {};
        let group = split;
        let portionMaps = {};
        for(let i = 0, l = group.expenseEntries.length; i < l; i++){
            let expenseEntry = group.expenseEntries[i];
            let map = getParticipantPortionMap(expenseEntry,group);
            let portionMap = {};
            for(let i = 0, l = group.linkedParticipants.length; i <l ; i++){
                portionMap[i] = 0;
                let lp = group.linkedParticipants[i];
                for(let j = 0, l = lp.length; j < l; j++){
                    portionMap[i] -= (map[lp[j]] || 0);
                    totalMap[i] = totalMap[i] || 0;
                    totalMap[i] -= (map[lp[j]] || 0);
                }
            }
            for(let i = 0, l = expenseEntry.payers.length; i < l; i++){
                portionMap[group.linkedParticipantMap[expenseEntry.payers[i]]] += expenseEntry.payerAmounts[i];
                totalMap[i] = totalMap[i] || 0;
                totalMap[i] += expenseEntry.payerAmounts[i];

            }
            portionMaps[expenseEntry.id] = portionMap;
        }

        const renderLinkedParticipants = (lp) => {
            return (<>
                {lp.map(p => <div>{group.participants[p]}</div> )}
            </>)
        }
        return (
            <Grid container>

                <Stack>
                </Stack>
                { group.expenseEntries.map(e =>  <>

                        <Grid xs={9}>
                            <Stack>
                                {
                                    group.linkedParticipants.map( (lp, index) => portionMaps[e.id] ? <div></div> : <div></div> )
                                }
                            </Stack>
                        </Grid>
                        <Grid xs={3}>
                            { renderCurrency(232, group.currency) }
                        </Grid>
                        <Grid xs={12}>
                        <Divider/>
                        </Grid>
                    </>

                )

                }
            </Grid>
        )
    }


    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile()}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >            <DialogTitle id="alert-dialog-title">
            <TabContext value={tabValue}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={(event, newValue) => {
                        setTabValue(newValue);
                    }} aria-label="basic tabs example">
                        <Tab label="Balance"
                             value={"1"}/>
                        <Tab label="Details" value={"2"}/>

                    </TabList>
                </Box>
            </TabContext>
            <CloseDialogButton onClick={closeDialog}/>
        </DialogTitle>
            <DialogContent>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                {!loading && split && tabValue === "1" && renderHtmlTable(split) }
            </DialogContent>
        </Dialog>
    );
}

export default PastSplitDialog;