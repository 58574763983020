import * as React from "react"

const SvgComponent = ({darkMode}) =>{
    let color1 = darkMode ? '#fff' : '#B3B4B6';
    let color2 = darkMode ? '#fff' : '#327DD9';
    
    return (
        <svg
        width={43}
        height={44}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#shareLinkClip)">
            <path
                d="M28.32 25.069a4.174 4.174 0 0 0-3.44 1.813l-5.83-2.633c.413-1 .415-2.123.003-3.124l5.823-2.647a4.174 4.174 0 1 0-.74-2.368c.003.237.027.472.071.705l-6.19 2.813a4.183 4.183 0 1 0-.013 6.115l6.206 2.803a4.245 4.245 0 0 0-.07.703 4.18 4.18 0 1 0 4.18-4.18Zm0-11.348a2.39 2.39 0 1 1 0 4.78 2.39 2.39 0 0 1 0-4.78ZM15.18 25.07a2.389 2.389 0 1 1-.001-4.778 2.389 2.389 0 0 1 .002 4.778Zm13.14 6.569a2.39 2.39 0 1 1 0-4.779 2.39 2.39 0 0 1 0 4.779Z"
                fill={color2}
                stroke="#fff"
                strokeWidth={0.2}
            />
            <circle cx={28.3} cy={29.23} r={3.6} fill="#fff" stroke={color1} />
            <circle cx={28.3} cy={16.13} r={3.6} fill="#fff" stroke={color1} />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.68 9.26v26.338A6.654 6.654 0 0 0 8.33 42.25H34.67a6.654 6.654 0 0 0 6.651-6.652V9.261a6.654 6.654 0 0 0-6.651-6.652H8.33A6.654 6.654 0 0 0 1.68 9.261Zm32.989-4.635a4.639 4.639 0 0 1 4.636 4.636v26.337a4.64 4.64 0 0 1-4.636 4.636H8.33a4.639 4.639 0 0 1-4.636-4.636V9.261a4.639 4.639 0 0 1 4.636-4.636H34.67Z"
            fill={color1}
            stroke="#fff"
        />
        <defs>
            <clipPath id="shareLinkClip">
                <path
                    fill="#fff"
                    transform="translate(11 11.93)"
                    d="M0 0h21.5v21.5H0z"
                />
            </clipPath>
        </defs>
    </svg>
) }

export default SvgComponent
