import React, {useState} from 'react';
import AppBar from "@mui/material/AppBar";
import {Avatar, Fade, Grid, IconButton, Slide, Stack, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import EntriesList from "./EntriesList/EntriesList";
import AddExpenseDialog from "./Dialogs/AddExpenseDialog/AddExpenseDialog";
import TripLoading from "./Trip/TripLoading";
import EditExpenseDialog from "./Dialogs/EditExpenseDialog";
import Results from "./Results/Results";
import ManualEntryDialog from "./Dialogs/ManualEntryDialog";
import ItemAssignmentDialog from "./Dialogs/ItemAssignmentDialog";
import DeleteExpenseDialog from "./Dialogs/DeleteExpenseDialog";
import ItemizedExpenseDetailsDialog from "./Dialogs/ItemizedExpenseDetailsDialog";
import PendingPaymentExpenseDialog from "./Dialogs/PendingPaymentExpenseDialog";
import MarkPaidDialog from "./Dialogs/MarkPaidDialog";
import SquareLogoSvg from "./SvgImages/SquareLogoSvg";
import Typography from "@mui/material/Typography";
import AddParticipantDialog from "./Dialogs/ManageParticipants/AddParticipantDialog";
import RemoveParticipantDialog from "./Dialogs/ManageParticipants/RemoveParticipantDialog";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import Divider from "@mui/material/Divider";
import {getEmailFromUser, goForwardTo, logActivity} from "./Trip/Utils";
import MenuIcon from "@mui/icons-material/Menu";
import LogoSvg from "./SvgImages/LogoSvg";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import PastSplitsDialog from "./Dialogs/PastSplitsDialog";
import GroupMembersDialog from "./GroupMembersDialog";
import Home from "./Trip/Home";
import DonateDialog from "./Dialogs/DonateDialog";
import LinkParticipantsDialog from "./Dialogs/LinkParticipantsDialog";
import SplitPalLoginSvg from "./SvgImages/SplitPalLoginSvg";
import SwitchGroupsDialog from "./Dialogs/SwitchGroupsDialog";
import LimitReached from "./Dialogs/LimitReached";
import PastSplitDialog from "./Dialogs/PastSplitDialog";
import SimplifyDialog from "./Dialogs/SimplifyDialog";
import UnlinkParticipantsDialog from "./Dialogs/ManageParticipants/UnlinkParticipantsDialog";
import HistoryDialog from "./Dialogs/HistoryDialog";
import HistorySvg from "./SvgImages/HistorySvg";
import CreateNewGroupDialog from "./Dialogs/CreateNewGroupDialog";
import GroupActionPanel from "./GroupActionPanel";
import AddExpensePanel from "./AddExpensePanel";
import LoginDialog from "./Dialogs/LoginDialog";
import BuyMeACoffee from "./SvgImages/BuyMeACoffee";
import ChangeDefaultCurrencyDialog from "./Dialogs/ChangeDefaultCurrencyDialog";
import {TollOutlined} from "@mui/icons-material";
import FeedbackDialog from "./Dialogs/FeedbackDialog";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import ArchiveDialog from "./Dialogs/ArchiveDialog";
import PreferredPaymentDialog from "./Dialogs/PreferredPaymentDialog";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {useTheme} from "@mui/material/styles";
import ConfirmReplaceParticipantDialog from "./Dialogs/ManageParticipants/ConfirmReplaceParticipantDialog";

function DesktopApp({setUser, user, group, error, setGroup, darkMode, setDarkMode}) {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [receiptImageData, setReceiptImageData] = useState(null);

    return (
        error ? <Container style={{marginTop: 85}}><Alert variant={"outlined"} severity={"error"}>
            {error}
        </Alert>
            <Grid container>
                <Grid item xs={12} style={{paddingTop: 20}} textAlign={"center"}>
                    <Button variant={"contained"} onClick={() => {
                        window.location.reload();
                    }} startIcon={<RefreshIcon/>}>Refresh</Button>
                </Grid>
            </Grid>
        </Container> : <>
            <AppBar elevation={0} style={{border: "none"}} position="fixed" color="transparent" variant={"outlined"}>
                <Toolbar>
                    <IconButton onClick={() => {
                        setOpen(true)
                    }} edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}>
                        <MenuIcon/>
                    </IconButton>
                    <SquareLogoSvg style={{height: 40}}/>
                    <Slide direction="down" in={!group} unmountOnExit mountOnEnter>
                        <Typography variant={"h6"} style={{marginLeft: 5}}> SplitPal</Typography>
                    </Slide>
                    {group && <Fade timeout={{enter: 1500, exit: 0}} in={!!group} unmountOnExit mountOnEnter>
                        <Typography variant={"overline"} style={{marginLeft: 5}}> {group.name}</Typography>
                    </Fade>}

                    <Box sx={{flexGrow: 1}}/>
                    { group && <IconButton style={{ position: "absolute" , right: 110}} onClick={()=>{
                        let newDarkMode = !darkMode;
                        setDarkMode(newDarkMode);
                        localStorage.SPLITPAL_PREFERRED_THEME = newDarkMode ? "dark" : "light";
                    }}> { darkMode? <Brightness4Icon style={{ color: 'rgb(50, 125, 217)'}} color={"primary"} fontSize={"large"}/> : <Brightness7Icon style={{ color: 'rgb(50, 125, 217)'}} fontSize={"large"}/>}  </IconButton> }

                    { group && <IconButton onClick={()=> {
                        goForwardTo(location, navigate,"/" + group.id + "/" + (params.view || 'home') + "/history");
                    }} style={{ position: "absolute" , right: 56}}>
                        <HistorySvg />
                    </IconButton> }
                    <IconButton style={{ position: "absolute" , right: 12, paddingRight: 0, paddingTop: 0, paddingBottom: 0}}
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=> {
                                    if(group){
                                        goForwardTo(location, navigate,"/" + group.id + "/" + (params.view || 'home') + "/user");
                                    } else {
                                        goForwardTo(location, navigate, "/user");
                                    }
                                }}
                                color="inherit"
                    >
                        { (user?.attributes?.picture || user?.picture) ? <Avatar alt={getEmailFromUser(user)} src={user?.attributes?.picture || user?.picture}/> :
                            user ? <Avatar alt={getEmailFromUser(user)} src={user?.attributes?.picture || user?.picture}/> :
                                <SplitPalLoginSvg/>}
                    </IconButton>
                </Toolbar>

                <Drawer
                    anchor={'left'}
                    open={open}
                    onClose={() => {
                        setOpen(false)
                    }}
                >
                    <Box
                        sx={{width: 300}}
                        role="presentation"
                        onClick={() => {
                            setOpen(false)
                        }}
                        onKeyDown={() => {
                            setOpen(false)
                        }}
                    >
                        <div style={{padding: 20}}>
                            <LogoSvg/>
                        </div>
                        <Grid container style={{padding: 20}}>
                            <Grid item xs={12}>
                                <Typography variant={"body2"}>
                                    Thank you for using SplitPal, we hope that you have a wonderful day.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 20}} textAlign={"right"}>
                                <Typography variant={"body2"}>
                                    -SplitPal Team
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 25}}  textAlign={"center"}>

                                <BuyMeACoffee onClick={()=> {
                                    window.open(`https://www.buymeacoffee.com/SplitPal`);
                                    logActivity("DonationAttempted", null, user);
                                }}/>
                            </Grid>

                        </Grid>
                        <List>



                        </List>
                    </Box>
                </Drawer>


            </AppBar>
            <Container style={{marginTop: 64 }}>
                <Grid style={{marginTop: 80}} container spacing={2}>
                    <Grid item xs={group ? 4 : 2} key={"trip_grid_left_side"}>
                        { group && <EntriesList trip={group}/> }

                    </Grid>

                    <Grid item xs={group ? 4 : 8} key={"trip_grid_left_middle"}>
                        { group && <Stack
                            style={{ borderRadius: 20, background: theme.custom.midbox}}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <AddExpensePanel setReceiptImageData={setReceiptImageData} group={group} desktop={true} />



                            <GroupActionPanel desktop={true} group={group} user={user}/>
                            <Button variant={"contained"} startIcon={<TollOutlined/>} onClick={() => {
                                goForwardTo(location, navigate, "/" + group.id + "/home/change");

                            }}
                                    >{group.currency}</Button>
                            <Button variant={"outlined"} onClick={()=> {
                                goForwardTo(location, navigate,"/" + group.id + "/expenses/participants");
                            }
                            }>Manage Participants</Button>




                            <Button onClick={() => {
                                goForwardTo(location, navigate, "/" + group.id + "/home/feedback");

                            }} startIcon={<FeedbackOutlinedIcon/>} variant={"outlined"}> Feedback</Button>


                            <div style={{height: 'calc(100vh - 850px)'}}>
                                <Divider orientation={"vertical"}/>
                            </div>

                        </Stack> }
                        {!group && <Home setUser={setUser} group={group} user={user}/>}
                    </Grid>
                    <Grid style={{paddingLeft: 20, paddingRight: 20}} xs={group ? 4 : 2} key={"trip_grid_left_right_side"} item>
                        { group && <Results trip={group}/> }
                    </Grid>
                </Grid>
            </Container>
            <Routes>


                <Route path="open" element={group ?
                    <AddExpenseDialog setTrip={setGroup} type={3} setReceiptImageData={setReceiptImageData} receiptImageData={receiptImageData} openCreateEntryDialog={true} trip={group} user={user}/> : <TripLoading/>
                }/>
                <Route path="quickeven" element={group ?
                    <AddExpenseDialog setReceiptImageData={setReceiptImageData} receiptImageData={receiptImageData} setTrip={setGroup} type={1} openCreateEntryDialog={true} trip={group} user={user}/> : <TripLoading/>
                }/>
                <Route path="quickuneven" element={group ?
                    <AddExpenseDialog setReceiptImageData={setReceiptImageData} receiptImageData={receiptImageData} setTrip={setGroup} type={2} openCreateEntryDialog={true} trip={group} user={user}/> : <TripLoading/>
                }/>

                <Route path={"archive"} element={group && <PastSplitsDialog trip={group}/>}/>

                <Route path="history" element={group ?
                    <HistoryDialog  group={group} /> : <TripLoading/>
                }/>

                <Route path="manual" element={group ?
                    <ManualEntryDialog trip={group}/> : <TripLoading/>
                }/>
                <Route path="link" element={group ?
                    <LinkParticipantsDialog group={group} setGroup={setGroup} user={user}/> : <TripLoading/>
                }/>
                <Route path="donate" element={group ?
                    <DonateDialog user={user} trip={group}/> : <TripLoading/>
                }/>

                <Route path="limit" element={group ?
                    <LimitReached/> : <TripLoading/>
                }/>
                <Route path="simplify" element={group ?
                    <SimplifyDialog setGroup={setGroup} user={user} trip={group}/> : <TripLoading/>
                }/>
                <Route path="doarchive" element={group ?
                    <ArchiveDialog setGroup={setGroup} user={user} group={group}/> : <TripLoading/>
                }/>
                <Route path="feedback" element={group ?
                    <FeedbackDialog group={group} user={user}/> : <TripLoading/>
                }/>

                <Route path="change" element={group ?
                    <ChangeDefaultCurrencyDialog setGroup={setGroup} user={user} group={group}/> : <TripLoading/>
                }/>

                <Route path="assign/:entry" element={group ?

                    <ItemAssignmentDialog
                        trip={group} user={user}/> : <TripLoading/>
                }/>
                <Route path="delete/:entry" element={group ?

                    <DeleteExpenseDialog setGroup={setGroup} user={user}
                        trip={group}/> : <TripLoading/>
                }/>

                <Route path="edit/:entry" element={group ?
                    <EditExpenseDialog user={user}
                        trip={group}/> : <TripLoading/>
                }/>
                <Route path="past/:entry" element={group ?
                    <PastSplitDialog group={group}
                        /> : <TripLoading/>
                }/>
                <Route path="itemizedDetails/:entry/:participant/:payee" element={group ?

                    <ItemizedExpenseDetailsDialog
                        trip={group}/> : <TripLoading/>
                }/>
                <Route path="addMember" element={group ?
                    <AddParticipantDialog group={group} setGroup={setGroup}/> : <TripLoading/>
                }/>
                <Route path="removeMember/:participant" element={group ?
                    <RemoveParticipantDialog group={group} setGroup={setGroup}/> : <TripLoading/>
                }/>
                <Route path="reassign/:participant" element={group ?
                    <ConfirmReplaceParticipantDialog group={group} setGroup={setGroup} user={user}/> : <TripLoading/>
                }/>
                <Route path="unlinkMembers/:participant" element={group ?
                    <UnlinkParticipantsDialog group={group} setGroup={setGroup}/>: <TripLoading/>
                }/>
                <Route path="details/:payer/:payee" element={group ?

                    <PendingPaymentExpenseDialog trip={group}/> : <TripLoading/>
                }/>
                <Route path="markpaid/:payer/:payee/:venmo" element={group ?

                    <MarkPaidDialog trip={group} setGroup={setGroup} user={user}/> : <TripLoading/>
                }/>
                <Route path="participants" element={group ?

                    <GroupMembersDialog group={group} user={user}/>: <TripLoading/>
                }/>

                <Route path="switch" element={group ? <SwitchGroupsDialog setGroup={setGroup} user={user} /> : <TripLoading/>}/>
                <Route path="new" element={group ? <CreateNewGroupDialog setGroup={setGroup} user={user} /> : <TripLoading/>}/>
                <Route path="user" element={group ?
                    <LoginDialog group={group} setGroup={setGroup} user={user} setUser={setUser}/>  : <TripLoading/>
                }/>
                <Route path="payments" element={group ?
                    <PreferredPaymentDialog group={group} setGroup={setGroup} user={user} setUser={setUser}/>  : <TripLoading/>
                }/>
            </Routes>

        </>
    );
}

export default DesktopApp;