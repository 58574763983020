import React, {useState} from 'react';
import AppBar from "@mui/material/AppBar";
import {Avatar, Fade, IconButton, Slide, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Home from "./Trip/Home";
import SplitBottomNavigation from "./Trip/Navigation/SplitBottomNavigation";
import EntriesList from "./EntriesList/EntriesList";
import AddExpenseDialog from "./Dialogs/AddExpenseDialog/AddExpenseDialog";
import TripLoading from "./Trip/TripLoading";
import EditExpenseDialog from "./Dialogs/EditExpenseDialog";
import Results from "./Results/Results";
import ManualEntryDialog from "./Dialogs/ManualEntryDialog";
import ItemAssignmentDialog from "./Dialogs/ItemAssignmentDialog";
import DeleteExpenseDialog from "./Dialogs/DeleteExpenseDialog";
import ItemizedExpenseDetailsDialog from "./Dialogs/ItemizedExpenseDetailsDialog";
import PendingPaymentExpenseDialog from "./Dialogs/PendingPaymentExpenseDialog";
import MarkPaidDialog from "./Dialogs/MarkPaidDialog";
import GroupMembers from "./GroupMembers/GroupMembers";
import Typography from "@mui/material/Typography";
import AddParticipantDialog from "./Dialogs/ManageParticipants/AddParticipantDialog";
import RemoveParticipantDialog from "./Dialogs/ManageParticipants/RemoveParticipantDialog";
import DonateDialog from "./Dialogs/DonateDialog";
import LinkParticipantsDialog from "./Dialogs/LinkParticipantsDialog";
import PastSplitsDialog from "./Dialogs/PastSplitsDialog";
import SplitPalLoginSvg from "./SvgImages/SplitPalLoginSvg";
import {getEmailFromUser, goForwardTo} from "./Trip/Utils";
import SwitchGroupsDialog from "./Dialogs/SwitchGroupsDialog";
import LoginDialog from "./Dialogs/LoginDialog";
import LimitReached from "./Dialogs/LimitReached";
import PastSplitDialog from "./Dialogs/PastSplitDialog";
import SimplifyDialog from "./Dialogs/SimplifyDialog";
import UnlinkParticipantsDialog from "./Dialogs/ManageParticipants/UnlinkParticipantsDialog";
import HistorySvg from "./SvgImages/HistorySvg";
import HistoryDialog from "./Dialogs/HistoryDialog";
import CreateNewGroupDialog from "./Dialogs/CreateNewGroupDialog";
import ChangeDefaultCurrencyDialog from "./Dialogs/ChangeDefaultCurrencyDialog";
import {Feedback} from "@mui/icons-material";
import FeedbackDialog from "./Dialogs/FeedbackDialog";
import ArchiveDialog from "./Dialogs/ArchiveDialog";
import PreferredPaymentDialog from "./Dialogs/PreferredPaymentDialog";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ConfirmReplaceParticipantDialog from "./Dialogs/ManageParticipants/ConfirmReplaceParticipantDialog";
function MobileApp({setUser, user, group, setGroup, darkMode, setDarkMode}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [receiptImageData, setReceiptImageData] = useState(null);
    return (<>
            <AppBar elevation={0} style={{border: "none", backdropFilter: "blur(16px)" }} position="fixed" color="transparent" variant={"outlined"}>
                <Toolbar>

                        <Slide direction="down" in={!params.view} unmountOnExit mountOnEnter>
                            <Typography variant={"h5"} style={{position: 'fixed',  marginLeft: 5, color: "#327DD9", fontWeight: "bolder"}}> SplitPal</Typography>
                        </Slide>
                    { group && <Fade timeout={{enter : 1500, exit: 0 }} in={!!params.view && params.view === 'members'} unmountOnExit mountOnEnter>
                            <Typography variant={"h5"} style={{marginLeft: 5, color: "#327DD9", fontWeight: "bolder"}}> Participants</Typography>
                        </Fade> }
                    { group && <Fade timeout={{enter : 1500, exit: 0 }} in={!!params.view && params.view === 'expenses'} unmountOnExit mountOnEnter>
                        <Typography variant={"h5"} style={{marginLeft: 5, color: "#327DD9", fontWeight: "bolder"}}> Expenses</Typography>
                    </Fade> }
                    { group && <Fade timeout={{enter : 1500, exit: 0 }} in={!!params.view && params.view === 'results'} unmountOnExit mountOnEnter>
                        <Typography variant={"h5"} style={{marginLeft: 5, color: "#327DD9", fontWeight: "bolder"}}> Settlement</Typography>
                    </Fade> }



                    <Box sx={{flexGrow: 1}}/>
                    { group && <IconButton style={{ position: "absolute" , right: 110}} onClick={()=>{
                        let newDarkMode = !darkMode;
                        setDarkMode(newDarkMode);
                        localStorage.SPLITPAL_PREFERRED_THEME = newDarkMode ? "dark" : "light";
                    }}> { darkMode? <Brightness4Icon style={{ color: 'rgb(50, 125, 217)'}} color={"primary"} fontSize={"large"}/> : <Brightness7Icon style={{ color: 'rgb(50, 125, 217)'}} fontSize={"large"}/>}  </IconButton> }
                    { group && <IconButton onClick={()=> {
                        goForwardTo(location, navigate,"/" + group.id + "/" + (params.view || 'home') + "/history");
                    }} style={{ position: "absolute" , right: 56}}>
                        <HistorySvg />
                    </IconButton> }
                    { group && <IconButton style={{ position: "absolute" , right: 12, paddingRight: 0, paddingTop: 0, paddingBottom: 0}}
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={()=> {
                                    if(group){
                                        goForwardTo(location, navigate,"/" + group.id + "/" + (params.view || 'home') + "/user");
                                    } else {
                                        goForwardTo(location, navigate, "/user");
                                    }


                                }}
                                color="inherit"
                    >
                        { (user?.attributes?.picture || user?.picture) ? <Avatar alt={getEmailFromUser(user)} src={user?.attributes?.picture || user?.picture}/> :
                            user ? <Avatar alt={getEmailFromUser(user)} src={getEmailFromUser(user)}/> :
                                <SplitPalLoginSvg/>}
                    </IconButton> }


                </Toolbar>
            </AppBar>

            <Container style={{marginTop: 64}}>
                { (!params.view || params.view ==="home") && <Home setReceiptImageData={setReceiptImageData} setUser={setUser} user={user} id={params.group} group={group} /> }
                { params.view === "expenses" && group && <EntriesList  setReceiptImageData={setReceiptImageData}  trip={group}/> }
                { params.view === "results" && group && <Results user={user} trip={group} /> }
                { params.view === "members" && group && <GroupMembers user={user} group={group} /> }
                <SplitBottomNavigation group ={group} />
            </Container>
            <Routes>


                <Route path={"archive"} element={group && <PastSplitsDialog trip={group}/>}/>
                <Route path="history" element={group ?
                    <HistoryDialog  group={group} /> : <TripLoading/>
                }/>
                <Route path="open" element={group ?
                    <AddExpenseDialog setTrip={setGroup} type={3} setReceiptImageData={setReceiptImageData} receiptImageData={receiptImageData} openCreateEntryDialog={true} trip={group} user={user}/> : <TripLoading/>
                }/>

                <Route path="quickeven" element={group ?
                    <AddExpenseDialog setTrip={setGroup} type={1} openCreateEntryDialog={true} trip={group} user={user}/> : <TripLoading/>
                }/>
                <Route path="quickuneven" element={group ?
                    <AddExpenseDialog setTrip={setGroup} type={2} openCreateEntryDialog={true} trip={group} user={user}/> : <TripLoading/>
                }/>
                <Route path="limit" element={group ?
                    <LimitReached/> : <TripLoading/>
                }/>

                <Route path="user" element={group ?
                    <LoginDialog group={group} setGroup={setGroup} user={user} setUser={setUser}/>  : <TripLoading/>
                }/>
                <Route path="payments" element={group ?
                    <PreferredPaymentDialog setGroup={setGroup} user={user} setUser={setUser}/>  : <TripLoading/>
                }/>

                <Route path="switch" element={group ? <SwitchGroupsDialog setGroup={setGroup} user={user} /> : <TripLoading/>}/>

                <Route path="new" element={group ? <CreateNewGroupDialog setGroup={setGroup} user={user} /> : <TripLoading/>}/>

                <Route path="manual" element={group ?
                    <ManualEntryDialog trip={group}/>  : <TripLoading/>
                }/>
                <Route path="link" element={group ?
                    <LinkParticipantsDialog group={group} setGroup={setGroup} user={user}/> : <TripLoading/>
                }/>

                <Route path="donate" element={group ?
                    <DonateDialog user={user} trip={group}/> : <TripLoading/>
                }/>

                <Route path="simplify" element={group ?
                    <SimplifyDialog setGroup={setGroup} user={user} trip={group}/> : <TripLoading/>
                }/>
                <Route path="doarchive" element={group ?
                    <ArchiveDialog setGroup={setGroup} user={user} group={group}/> : <TripLoading/>
                }/>
                <Route path="feedback" element={group ?
                    <FeedbackDialog group={group} user={user}/> : <TripLoading/>
                }/>

                <Route path="change" element={group ?
                    <ChangeDefaultCurrencyDialog setGroup={setGroup} user={user} group={group}/> : <TripLoading/>
                }/>

                <Route path="assign/:entry" element={group ?

                    <ItemAssignmentDialog
                        trip={group} user={user}/> : <TripLoading/>
                }/>
                <Route path="past/:entry" element={group ?
                    <PastSplitDialog
                        group={group}/> : <TripLoading/>
                }/>

                <Route path="delete/:entry" element={group ?

                    <DeleteExpenseDialog setGroup={setGroup} user={user}
                        trip={group}/> : <TripLoading/>
                }/>

                <Route path="itemizedDetails/:entry/:participant/:payee" element={group ?

                    <ItemizedExpenseDetailsDialog
                        trip={group}/> : <TripLoading/>
                }/>

                <Route path="addMember" element={group ?
                    <AddParticipantDialog group={group} setGroup={setGroup} />: <TripLoading/>
                }/>
                <Route path="link" element={group ?
                    <LinkParticipantsDialog group={group} setGroup={group} user={user}/>: <TripLoading/>
                }/>
                <Route path="removeMember/:participant" element={group ?
                    <RemoveParticipantDialog group={group} setGroup={setGroup} />: <TripLoading/>
                }/>
                <Route path="reassign/:participant" element={group ?
                    <ConfirmReplaceParticipantDialog group={group} setGroup={setGroup} user={user} />: <TripLoading/>
                }/>
                <Route path="unlinkMembers/:participant" element={group ?
                    <UnlinkParticipantsDialog group={group} setGroup={setGroup}/>: <TripLoading/>
                }/>

                <Route path="details/:payer/:payee" element={group ?

                    <PendingPaymentExpenseDialog trip={group} /> : <TripLoading/>
                }/>
                <Route path="markpaid/:payer/:payee/:venmo" element={group ?

                    <MarkPaidDialog trip={group} setGroup={setGroup} user={user}/> : <TripLoading/>
                }/>

                <Route path="edit/:entry" element={group ?
                    <EditExpenseDialog user={user}
                        trip={group}/> : <TripLoading/>
                }/>
            </Routes>
        </>
    );
}

export default MobileApp;