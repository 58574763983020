import React, {useRef, useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import {Button, IconButton, InputAdornment, Menu, Snackbar, TextField, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import Grid from '@mui/material/Grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import {styled} from "@mui/material/styles";
import ReceiptItemDialog from "../Dialogs/ReceiptItemDialog";
import {BEGIN_ID_TIMESTAMP, CurrencySymbol, monetaryValue} from "../Trip/Utils";
import {getCurrencyFraction, renderCurrency} from "../Trip/Currencies";
import DiscountIcon from '@mui/icons-material/Discount';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function Row({receipt, setReceipt, item, isNewItem, currency}) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(item.name);
    const [quantity, setQuantity] = React.useState(item.quantity);
    const [price, setPrice] = React.useState(item.price);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openReceiptItemDialog, setOpenReceiptItemDialog] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState("");
    const menuOpen = Boolean(anchorEl);

    const onKeyPress = (e)=>{
        if(e.key === 'Enter' && isNewItem && price && quantity > 0 && name){
            let clone = Object.assign({}, receipt);
            clone.items.push({ assignees :[], name, price: Number(price), quantity: Number(quantity), id : Date.now() - BEGIN_ID_TIMESTAMP });
            setReceipt(clone);
            setOpen(false);
            e.preventDefault();
        }
    }
    return (

            <StyledTableRow onClick={()=>{ }}
                style={{border: 0}}
                key={"top_itemrow"+ item.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

                { !isNewItem && <TableCell style={{borderBottom: 0, paddingLeft: 0, paddingRight: 0}} align="center">{item.quantity}</TableCell> }
                { !isNewItem && <TableCell style={{borderBottom: 0, paddingLeft: 0}} align="left">{item.name}</TableCell> }
                { !isNewItem && <TableCell style={{borderBottom: 0}} align="right">
                    { item.price && renderCurrency(item.price, currency) }</TableCell> }
                { !isNewItem && <TableCell align={"center"} style={{padding: 0}}>

                    <IconButton style={{padding: 0}}>
                        <MoreVertIcon style={{padding: 0}} onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}/>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={menuOpen}
                            onClose={() => {
                                setAnchorEl(null);
                            }}
                            onClick={() => {
                                setAnchorEl(null);
                            }}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem  onClick={()=> {
                                let clone = Object.assign({}, receipt);
                                clone.items = clone.items.filter(i => { return i.id !== item.id })
                                setReceipt(clone);
                                setDeleteConfirmed("Item Deleted");
                            }}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                Delete
                            </MenuItem>
                            <MenuItem onClick={()=> {
                                setOpenReceiptItemDialog(true);
                            }}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                Edit
                            </MenuItem>
                            <MenuItem onClick={()=> {
                                let clone = Object.assign({}, receipt);
                                clone.items = clone.items.filter(i => { return i.id !== item.id })
                                clone.discount = clone.discount || 0;
                                clone.discount += item.price || 0;
                                clone.discount = Number(clone.discount);
                                setReceipt(clone);

                                setDeleteConfirmed("Item Added To Discount & Removed from List");
                            }}>
                                <ListItemIcon>
                                    <DiscountIcon color={"success"} fontSize="small" />
                                </ListItemIcon>
                                Add to Discount
                            </MenuItem>
                        </Menu>
                    </IconButton>


                </TableCell> }
                <ReceiptItemDialog currency={currency} item={item} receipt={receipt} setReceipt={setReceipt} isNewItem={isNewItem}  openReceiptItemDialog={openReceiptItemDialog} setOpenReceiptItemDialog={setOpenReceiptItemDialog}/>
                <Snackbar
                    open={deleteConfirmed}
                    autoHideDuration={3000}
                    onClose={()=> {setDeleteConfirmed("")} }
                    message={deleteConfirmed}
                />
            </StyledTableRow>

    );
}


function ReceiptEditor({receipt, setReceipt , currency, setCurrency}) {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(null);
    const [tax, setTax] = useState(receipt.tax);
    const [tip, setTip] = useState(receipt.tip);
    const [discount, setDiscount] = useState(receipt.discount);
    const [addNewItemDialogOpen, setAddNewItemDialogOpen] = useState(false);

    const nameInput = useRef(null);

    useEffect(() => {
        setDiscount(receipt.discount);
    }, [receipt.discount]);

    function getSubtotal(addTaxAndTip) {
        let total = 0;
        receipt.items.forEach(i => total+= (i.price) );
        if(addTaxAndTip){
            total+= ( Number(tax) + Number(tip) - Math.abs(Number(discount || 0)) );
        }
        return monetaryValue(total, currency);
    }

    return (
        <>
        <TableContainer>
            <Table size="small" aria-label="a dense table">
                { receipt.items.length > 0 && <TableHead>
                    <TableRow>
                        <TableCell align="left" style={{paddingLeft: 0, paddingRight: 0}}>Qty.</TableCell>
                        <TableCell align="left" style={{paddingLeft: 0}}>Name</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead> }
                <TableBody>
                    <Row isNewItem={true} item={{name: '', price: null, quantity: 1 , assignees : [] }} receipt={receipt} setReceipt={setReceipt} currency={currency}></Row>
                    {receipt.items.map((item) => (
                        <Row item={item} receipt={receipt} setReceipt={setReceipt} currency={currency}></Row>
                    ))}
                    <TableRow>
                        <TableCell colSpan={4} style={{paddingLeft: 0, paddingRight: 0}}>
                            <Button onClick={()=> {
                                setAddNewItemDialogOpen(true);
                            }} startIcon={<AddIcon/>} size={"small"} fullWidth={true} variant={"contained"}>Add New Item</Button>
                        </TableCell>
                    </TableRow>

                </TableBody>

            </Table>
        </TableContainer>
            <Grid style={{paddingTop: 10}} container xs={12}>
                <Grid item xs={6}><Typography style={{paddingTop: 8}} variant={"subtitle2"}>Tax</Typography></Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth={true}  >

                        <TextField
                            id="receipt-editor-tax"
                            type="number"
                            size={"small"}
                            value={tax}
                            InputProps={{
                                startAdornment : <InputAdornment position="start"><CurrencySymbol currency={currency} /></InputAdornment>
                            }}

                            onChange={(e) => {
                                let clone = Object.assign({}, receipt);
                                clone.tax =  (Number(e.target.value) || 0);
                                setReceipt(clone);
                                setTax(e.target.value)
                            }}
                        />
                    </FormControl>

                </Grid>
            </Grid>
            <Grid style={{paddingTop: 10}} container xs={12}>
                <Grid item xs={6}><Typography style={{paddingTop: 8}} variant={"subtitle2"}>Tip</Typography></Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth={true} >
                        <TextField
                            id="receipt-editor-tip"
                            type="number"
                            size={"small"}
                            value={tip}
                            InputProps={{
                                startAdornment : <InputAdornment position="start"><CurrencySymbol currency={currency} /></InputAdornment>
                            }}
                            onChange={(e) => {

                                let clone = Object.assign({}, receipt);
                                clone.tip = (Number(e.target.value) || 0);
                                setReceipt(clone);
                                setTip(e.target.value)
                            }}
                            startAdornment={<InputAdornment position="start"><CurrencySymbol currency={currency} /></InputAdornment>}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid style={{paddingTop: 10}} container xs={12}>
                <Grid item xs={6}><Typography style={{paddingTop: 8}} variant={"subtitle2"}>Discounts</Typography></Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth={true} >
                        <TextField
                            id="receipt-editor-discount"
                            type="number"
                            size={"small"}
                            value={discount}
                            onChange={(e) => {

                                let clone = Object.assign({}, receipt);
                                clone.discount = Math.abs(Number(e.target.value) || 0);
                                setReceipt(clone);
                                setDiscount(Number(e.target.value) || null )
                            }}
                            InputProps={{
                                startAdornment : <InputAdornment position="start"><CurrencySymbol currency={currency} /></InputAdornment>
                            }}
                            />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid style={{paddingTop: 10}} container xs={12}>
                <Grid item xs={6}><Typography style={{paddingTop: 8}} variant={"subtitle2"}>Total</Typography></Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth={true} >
                        <TextField
                            id="receipt-editor-total"
                            type="number"
                            size={"small"}
                            value={getSubtotal(true)}
                            InputProps={{
                                startAdornment : <InputAdornment position="start"><CurrencySymbol setCurrency={setCurrency} currency={currency} /></InputAdornment>
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>


                <ReceiptItemDialog currency={currency} item={null} setReceipt={setReceipt} receipt={receipt} setOpenReceiptItemDialog={setAddNewItemDialogOpen} openReceiptItemDialog={addNewItemDialogOpen}/>

        </>
    );
}

export default ReceiptEditor;