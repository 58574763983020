import React, {useState} from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputAdornment,
    Switch,
    Tab,
    TextField
} from "@mui/material";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import ActiveParticipantsGetter from "../CreateEntry/ActiveParticipantsGetter";
import Typography from "@mui/material/Typography";
import ReceiptEditor from "../CreateEntry/ReceiptEditor";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CurrencySymbol, expenseSavingDisableCondition, goBack, logActivity, monetaryValue} from "../Trip/Utils";
import UnevenSplitEditor from "../CreateEntry/UnevenSplitEditor";
import Alert from "@mui/material/Alert";
import ExpenseDateInput from "../Trip/ExpenseDateInput";
import {getCurrencyConversion} from "../../graphql/queries";
import {getParticipantPortionMap, serializeGroup} from "../Trip/Utils/expenseUtils";
import PayersGetter from "../CreateEntry/PayersGetter";
import CloseDialogButton from "./CloseDialogButton";

function EditExpenseDialog({trip , user }) {
    const params = useParams();
    let id = Number(params.entry);

    const expenseEntry = trip.expenseEntries.find(e => e.id === id);



    const [loading , setLoading] = useState(false);
    const [description , setDescription] = useState(expenseEntry?.description);
    const [payers , setPayers] = useState(expenseEntry?.payers);
    const [amount , setAmount] = useState(expenseEntry?.amount);
    const [payerAmounts, setPayerAmounts] = React.useState(expenseEntry?.payerAmounts);
    const [date, setDate] = useState(expenseEntry?.date);
    const [currency, setCurrency] = useState(expenseEntry?.currency);
    const [type, setType] = React.useState(expenseEntry?.type);
    const [tabValue, setTabValue] = React.useState("1");
    const [activeParticipants, setActiveParticipants] = React.useState(expenseEntry?.participants);
    const [receipt, setReceipt] = useState(Object.assign({}, expenseEntry));
    const [unevenItems, setUnevenItems] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const saveButtonDisabled = ()=> {
        return expenseSavingDisableCondition(type, receipt.items, currency, amount, payers, payerAmounts, description, activeParticipants, unevenItems, date );
    }
    const closeDialog = ()=> {
        goBack(location, navigate, '/' + trip.id + '/expenses')
    }

    const getExpenseAmount = () => {

        if(expenseEntry?.type === 3){
            let totalItemsAmount = 0;
            totalItemsAmount += receipt.items.map(a=>a.price).reduce((a, b) => a + b, 0);
            totalItemsAmount -= receipt.discount;
            totalItemsAmount += receipt.tip + receipt.tax;
            return totalItemsAmount;
        } else {
            return amount;
        }
    }

    const isMobile = window.innerWidth <= 768;
    return (
        <Dialog
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Edit Expense"} { !loading && <CloseDialogButton onClick={closeDialog}/> }
            </DialogTitle>
            {expenseEntry ? <DialogContent>
                    <TabContext value={tabValue}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={(event, newValue) => {
                                setTabValue(newValue);
                            }} aria-label="basic tabs example">
                                <Tab disabled={!activeParticipants || activeParticipants.length < 2} label="Details"
                                     value={"1"}/>
                                <Tab label="Participants" value={"2"}/>
                                {expenseEntry.type === 3 &&
                                    <Tab disabled={!activeParticipants || activeParticipants.length < 2} label="Items"
                                         value={"3"}/>}
                            </TabList>
                        </Box>

                        <TabPanel style={{paddingLeft: 0, paddingRight: 0}} value={"1"}>

                            <FormControl margin="normal" variant="standard" fullWidth={true}>
                                <ExpenseDateInput setDate={setDate} defaultValue={date}/>
                            </FormControl>

                            <FormControl margin="normal" variant="standard" fullWidth={true}>
                                <TextField
                                    fullWidth={true}
                                    variant={"outlined"}
                                    label="Description"
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }}
                                />
                            </FormControl>
                            {
                                expenseEntry.type !== 3 && <FormControl variant="standard" fullWidth={true}>

                                    <TextField
                                        margin={"dense"}
                                        autoFocus={true}
                                        label={"Amount"}
                                        size="small"
                                        type="number"
                                        value={amount}

                                        onChange={(e) => {
                                            let v = e.target.value && monetaryValue(Number(e.target.value, currency));
                                            if(type !== 3){
                                                if(payers.length === 1){
                                                    setPayerAmounts([v]);
                                                }
                                            }
                                            if(type === 2 && payers.length === 1) {
                                                console.log('strange');
                                                let payer = payers[0];
                                                let otherAmount = 0;
                                                let payerItem = null;
                                                for (const unevenItem of unevenItems) {
                                                    if(unevenItem.assignees[0].name === payer) {
                                                        payerItem = unevenItem;
                                                    } else {
                                                        otherAmount += unevenItem.price;
                                                    }
                                                }
                                                let payerCovers = Math.max(0, v - otherAmount);
                                                if(payerItem) {
                                                    payerItem.price = payerCovers;
                                                }
                                              setUnevenItems(unevenItems);
                                            }
                                            setAmount(v);
                                        }}

                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><CurrencySymbol
                                                currency={currency} setCurrency={setCurrency}/></InputAdornment>,
                                        }}

                                    />
                                </FormControl>
                            }

                            <PayersGetter payerAmounts={payerAmounts} showLabel={true}
                                          setPayerAmounts={setPayerAmounts}
                                          noMultiplePayers={trip.version < 2}
                                          payers={payers}
                                          setPayers={setPayers}
                                          participants={activeParticipants}
                                          amount={getExpenseAmount()}
                                          participantNames={trip.participants}
                                          currency={currency}
                            />

                            <Grid container>
                                <Grid item xs={12}>
                                    {expenseEntry.type !== 3 && <FormControlLabel style={{marginTop: 10}} onChange={(e) => {
                                        setType(e.target.checked ? 1 : 2)
                                    }} control={<Switch checked={type === 1}/>} label={type === 1 ? "Split Evenly" : "Specify Expense Distribution"}/>

                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    { type === 2 && <UnevenSplitEditor
                                        expenseEntry={expenseEntry}
                                        currency={currency}
                                        setUnevenItems={setUnevenItems}
                                        participantNames={trip.participants}
                                        payers={payers}
                                        amount={amount}
                                        activeParticipants={activeParticipants}/>}
                                </Grid>
                            </Grid>


                        </TabPanel>
                        <TabPanel value={"2"}>
                            <ActiveParticipantsGetter disableSelectAll={true} payers={payers}
                                                      initialParticipants={activeParticipants}
                                                      participants={trip.participants.map((p, i)=> i)}
                                                      participantNames={trip.participants}
                                                      setActiveParticipants={setActiveParticipants}/>
                            {(!activeParticipants || activeParticipants.length < 2) &&
                                <Typography variant={"body2"} color={"red"}>
                                    You must select select at least 2 participants.
                                </Typography>
                            }
                        </TabPanel>
                        <TabPanel style={{ paddingLeft: 0 , paddingRight: 0}} value={"3"}>
                            <ReceiptEditor currency={currency} setCurrency={setCurrency} setReceipt={setReceipt} receipt={receipt}/>
                        </TabPanel>
                    </TabContext>


                </DialogContent> :
                <DialogContent>
                    <Alert severity={"warning"}>Looks like someone has removed this expense from the split.</Alert>
                </DialogContent>
            }
            <DialogActions>
                { expenseEntry && <LoadingButton disabled={saveButtonDisabled() }  variant={"contained"}  loading={loading} color={"primary"} onClick={async () => {
                    setLoading(true);
                    try {
                        let expenseEntries = trip.expenseEntries;
                        let index = expenseEntries.findIndex(e => e.id === expenseEntry.id);
                        let clone = Object.assign({}, expenseEntry);
                        clone.description = description;
                        clone.payers = payers;
                        clone.currency = currency;
                        clone.date = date;
                        clone.rate = expenseEntry.rate;
                        clone.amount = Number(amount);
                        clone.payerAmounts = payerAmounts;
                        clone.participants = activeParticipants;

                        const participantsSet = new Set(clone.participants);
                        if(expenseEntry.type === 3){
                            clone.items = [];
                            clone.tax = receipt.tax;
                            clone.discount = receipt.discount;
                            clone.tip = receipt.tip;
                            receipt.items.forEach(item => {
                                let cloneItem = JSON.parse(JSON.stringify(item));
                                if(cloneItem.assignees){
                                    for(let i = cloneItem.assignees.length-1; i >= 0; i--){
                                        if(!participantsSet.has(cloneItem.assignees[i].name)){
                                            cloneItem.assignees.splice(i, 1);
                                        }
                                    }
                                }
                                clone.items.push(cloneItem);
                            })
                        } else {
                            clone.type = type;
                            clone.items = unevenItems;
                        }

                        if(currency !== trip.currency){
                            if(currency !== expenseEntry.currency || date !== expenseEntry.date ){
                                let currencyResponse = await API.graphql(graphqlOperation(getCurrencyConversion, {
                                    base: trip.currency,
                                    date: date
                                } ));
                                let rates = JSON.parse(currencyResponse.data.getCurrencyConversion);
                                clone.rate = rates["conversion_rates"][currency];
                            }
                        } else {
                            clone.rate = 1;
                        }
                        let oldState = expenseEntries[index];
                        expenseEntries[index] = clone;

                        trip.expenseEntries = expenseEntries;

                        let resp = await API.graphql(graphqlOperation(updateTrip,{
                            input: serializeGroup(trip)
                        }));
                        logActivity("modify", {
                            ...trip,
                            data:
                                {
                                    participantNames: trip.participants,
                                    oldData: {
                                        type: oldState.type,
                                        amount: oldState.amount,
                                        description: oldState.description,
                                        payerAmounts: oldState.payerAmounts,
                                        payers: oldState.payers,
                                        participants: oldState.participants,
                                        currency: oldState.currency,
                                        rate: oldState.rate,
                                        date : oldState.date,
                                        pm: getParticipantPortionMap(oldState, trip)
                                    },
                                    newData: {
                                        type,
                                        amount,
                                        description,
                                        payerAmounts,
                                        payers,
                                        participants: activeParticipants,
                                        currency,
                                        rate: clone.rate,
                                        date,
                                        pm: getParticipantPortionMap(clone, trip)
                                    }
                                }

                        }, user);


                        setLoading(false);
                        closeDialog();


                    } catch (e) {
                        console.log(e);
                        setLoading(false);
                    }

                }} autoFocus>
                    Save
                </LoadingButton> }
            </DialogActions>
        </Dialog>
    );
}

export default EditExpenseDialog;