import React, {useEffect, useState} from 'react';
import {
    Alert,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack
} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {decorateFromSettingsString, goBack, isMobile} from "../Trip/Utils";
import {API, graphqlOperation} from "aws-amplify";
import {getArchivedSplits} from "../../graphql/queries";
import PastSplitPanel from "../PastSplitPanel";
import CloseDialogButton from "./CloseDialogButton";


function PastSplitsDialog({trip}) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const [pastSplits, setPastSplits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSplitInHtml, setShowSplitInHtml] = React.useState(null);

    useEffect(()=>{
        (async()=> {
            setLoading(true);
            const splitData = await API.graphql(graphqlOperation(getArchivedSplits, {id: params.group}));
            let pastSplits = JSON.parse(splitData.data.getArchivedSplits);
            pastSplits.forEach(split => {
                let body = JSON.parse(split.body);
                decorateFromSettingsString(body);
                body.splitName = split.name;
                body.closed = split.id;
                split.body = body;
            } )
            setPastSplits(pastSplits.reverse())
            setLoading(false);
        })();
    }, [] )






    const closeDialog = ()=> {
        goBack(location, navigate, '/');
    }

    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile()}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Completed Splits
                <CloseDialogButton onClick={closeDialog}/>
            </DialogTitle>
            <DialogContent>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {!loading && pastSplits.length === 0 && <Alert severity={"info"}> This group does not have previously Completed Splits.</Alert> }
                <Stack>
                    {pastSplits.map(p => <PastSplitPanel group={p.body}/>)}
                </Stack>

            </DialogContent>
        </Dialog>
    );
}

export default PastSplitsDialog;