import {goForwardTo, isShortMobile, logActivity, shareSplitGroup} from "./Trip/Utils";
import {Card, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardButton from "./Trip/CardButton";
import ShareLinkSvg from "./SvgImages/Home/ShareLinkSvg";
import SwitchGroupSvg from "./SvgImages/Home/SwitchGroupSvg";
import NewGroupSvg from "./SvgImages/Home/NewGroupSvg";
import {useLocation, useNavigate} from "react-router-dom";
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import React from "react";
import PastSplitsSvg from "./SvgImages/Home/PastSplitsSvg";
import Button from "@mui/material/Button";
import SplitPalLoginSvg from "./SvgImages/SplitPalLoginSvg";
import {useTheme} from "@mui/material/styles";

function GroupActionPanel({ group, user, desktop}) {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const darkMode = theme.custom.mode === 'dark';


    return (<Grid container>
        <Grid item xs={12} style={{marginTop: 20}}>
            <Typography variant={"h6"} style={{fontSize: "16px", fontWeight: "500", lineHeight: "24px"}}
                        textAlign={"center"}><PeopleOutlinedIcon color={"primary"} style={{marginBottom: -7}}/> Group Actions</Typography>
        </Grid>
        <Grid container spacing={2} style={{marginTop: 5}}>



            { !group && <Grid item xs={2}/> }
            { desktop && <Grid item xs={2}/> }
            { group && <Grid item xs={4} textAlign={"center"}>
                <CardButton icon={<ShareLinkSvg darkMode={darkMode} />} label={"Share Link"}
                            onClick={async () => {
                                await shareSplitGroup(group.id, group.participants);
                                logActivity('shareLink', group, user);
                            }}/>
            </Grid> }
            <Grid item xs={4} textAlign={"center"}>
                <CardButton icon={<SwitchGroupSvg darkMode={darkMode} />} label={isShortMobile() ? "Switch" : "Switch"}
                            onClick={() => {
                                group ?
                                goForwardTo(location, navigate,  "/" + group.id + "/home/switch") :
                                    goForwardTo(location, navigate, "/switch")
                            }}/>

            </Grid>
            {/*{ group.pastSplits > 0 && <Button variant={"outlined"} startIcon={<HistoryIcon/>} onClick={() => {*/}
            {/*    goForwardTo(location, navigate, "/" + group.id + "/expenses/archive");*/}

            {/*}}>Past Splits</Button> }*/}

            { desktop && <Grid item xs={2}/> }
            { desktop &&  (group.pastSplits ? <Grid item xs={2}/> : <Grid item xs={4}/>) }
            <Grid item xs={4} textAlign={"center"}>
                <CardButton icon={<NewGroupSvg darkMode={darkMode}/>} label={"New Group"}
                            onClick={() => {
                                goForwardTo(location, navigate, "/", true);

                                group ?
                                    goForwardTo(location, navigate,  "/" + group.id + "/home/new") :
                                    goForwardTo(location, navigate, "/new")

                            }}/>
            </Grid>
            { !group && <Grid item xs={2}/> }
            { desktop && group ? group.pastSplits > 0 ? <Grid item xs={4} textAlign={"center"}>
                <CardButton icon={<PastSplitsSvg darkMode={darkMode}/>} label={"Past Splits"}
                            onClick={() => {
                                goForwardTo(location, navigate,  "/" + group.id + "/home/archive");

                            }}/>
            </Grid>  : <Grid item xs={4}/> : <Grid item xs={4}/> }
            {!desktop && group?.pastSplits > 0 && <Grid style={{paddingLeft: 16}} xs={12} textAlign={"center"}> <Card>
                <Button fullWidth={true} style={{ color : theme.palette.text.primary }}
                onClick={() => {
                    goForwardTo(location, navigate,  "/" + group.id + "/home/archive");

                }}

                startIcon={
              <PastSplitsSvg/>
            } >Past Splits</Button>
            </Card>
            </Grid>  }

        </Grid>
    </Grid>)
}

export default GroupActionPanel;