export const Currencies = {
    "AED": {
        "name": "UAE Dirham",
        "fractionSize": 2,
        "symbol": {
            "grapheme": ".د.إ",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.إ",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "AFN": {
        "name": "Afghani",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "؋",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": "؋",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "ALL": {
        "name": "Lek",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "L",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Lek",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "AMD": {
        "name": "Armenian Dram",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "դր.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "դր.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "ANG": {
        "name": "Netherlands Antillean Guilder",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "ƒ",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "NAƒ",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "AOA": {
        "name": "Kwanza",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Kz",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "ARS": {
        "name": "Argentine Peso",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "AUD": {
        "name": "Australian Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "A$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "AWG": {
        "name": "Aruban Florin",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "ƒ",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Afl",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "AZN": {
        "name": "Azerbaijanian Manat",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₼",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₼",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BAM": {
        "name": "Convertible Mark",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "KM",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "KM",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BBD": {
        "name": "Barbados Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BDT": {
        "name": "Taka",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "৳",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BGN": {
        "name": "Bulgarian Lev",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "лв",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "лв",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BHD": {
        "name": "Bahraini Dinar",
        "fractionSize": 3,
        "symbol": {
            "grapheme": ".د.ب",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.ب",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "BIF": {
        "name": "Burundi Franc",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "FBu",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BMD": {
        "name": "Bermudian Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "BD$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BND": {
        "name": "Brunei Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BOB": {
        "name": "Boliviano",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Bs.",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Bs.",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BOV": {
        "name": "Mvdol",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Bov",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BRL": {
        "name": "Brazilian Real",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "R$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "R$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BSD": {
        "name": "Bahamian Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BTN": {
        "name": "Ngultrum",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Nu.",
            "rightSignPosition" : false, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BWP": {
        "name": "Pula",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "P",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "P",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "BYN": {
        "name": "Belarussian Ruble",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "p.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "р.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "BYR": {
        "name": "Belarussian Ruble",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "p.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "р.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "BZD": {
        "name": "Belize Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "BZ$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "BZ$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "CAD": {
        "name": "Canadian Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "CA$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "CDF": {
        "name": "Congolese Franc",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "FC",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CHE": {
        "name": "WIR Euro",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "CHE",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CHF": {
        "name": "Swiss Franc",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "fr.",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CHW": {
        "name": "WIR Franc",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "CHW",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CLF": {
        "name": "Unidad de Fomento",
        "fractionSize": 4,
        "symbol": {
            "grapheme": "UF",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CLP": {
        "name": "Chilean Peso",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CNY": {
        "name": "Yuan Renminbi",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "元",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "元",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "COP": {
        "name": "Colombian Peso",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "COU": {
        "name": "Unidad de Valor Real",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "COU",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CRC": {
        "name": "Cost Rican Colon",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₡",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₡",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "CUC": {
        "name": "Peso Convertible",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "CUC",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CUP": {
        "name": "Cuban Peso",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$MN",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "$MN",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "CVE": {
        "name": "Cabo Verde Escudo",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "esc",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "CZK": {
        "name": "Czech Koruna",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Kč",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Kč",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "DJF": {
        "name": "Djibouti Franc",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "Fdj",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "DKK": {
        "name": "Danish Krone",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "kr",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "DOP": {
        "name": "Dominican Peso",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "RD$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "RD$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "DZD": {
        "name": "Algerian Dinar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": ".د.ج",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.ج",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "EEK": {
        "name": "Estonian Kroon",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "kr",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "EGP": {
        "name": "Egyptian Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": ".ج.م",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "ERN": {
        "name": "Nakfa",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Nkf",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "ETB": {
        "name": "Ethiopian Birr",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Br",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "EUR": {
        "name": "Euro",
        "fractionSize": 2,
        "usesCommasInsteadOfDecimals" : true,
        "symbol": {
            "grapheme": "€",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "€",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "FJD": {
        "name": "Fiji Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "FJ$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "FKP": {
        "name": "Falkland Islands Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GBP": {
        "name": "Pound Sterling",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "GEL": {
        "name": "Lari",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "GEL",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GGP": {
        "name": "Guernsey Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GHC": {
        "name": "Ghanaian Cedi",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "¢",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "¢",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "GHS": {
        "name": "Ghan Cedi",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "GH₵",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GIP": {
        "name": "Gibraltar Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GMD": {
        "name": "Dalasi",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "D",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GNF": {
        "name": "Guine Franc",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "GFr",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "GTQ": {
        "name": "Quetzal",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Q",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Q",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "GYD": {
        "name": "Guyan Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "GY$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "HKD": {
        "name": "Hong Kong Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "HK$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "HNL": {
        "name": "Lempira",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "L",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "L",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "HRK": {
        "name": "Croatian Kuna",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "kn",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "kn",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "HTG": {
        "name": "Gourde",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "G",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "HUF": {
        "name": "Forint",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "Ft",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Ft",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "IDR": {
        "name": "Rupiah",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Rp",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Rp",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "ILS": {
        "name": "New Israeli Sheqel",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₪",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₪",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "IMP": {
        "name": "Manx Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "INR": {
        "name": "Indian Rupee",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₹",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₹",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "IQD": {
        "name": "Iraqi Dinar",
        "fractionSize": 3,
        "symbol": {
            "grapheme": ".د.ع",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.ع",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "TVD" : {
        "fractionSize": 3,
        "symbol": {
            "grapheme": "TV$",
            "rightSignPosition" : true, "hasSpace" : true,
        }
    },
    "IRR": {
        "name": "Iranian Rial",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "﷼",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": "﷼",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "ISK": {
        "name": "Iceland Krona",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "kr",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "JEP": {
        "name": "Jersey Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "JMD": {
        "name": "Jamaican Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "J$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "J$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "JOD": {
        "name": "Jordanian Dinar",
        "fractionSize": 3,
        "symbol": {
            "grapheme": ".د.إ",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": null
    },
    "JPY": {
        "name": "Yen",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "¥",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "¥",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "KES": {
        "name": "Kenyan Shilling",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "KSh",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "KSh",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "KGS": {
        "name": "Som",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "сом",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "сом",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "KHR": {
        "name": "Riel",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "៛",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "៛",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "KMF": {
        "name": "Comoro Franc",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "CF",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "KPW": {
        "name": "North Korean Won",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "₩",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "KRW": {
        "name": "Won",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "₩",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₩",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "KWD": {
        "name": "Kuwaiti Dinar",
        "fractionSize": 3,
        "symbol": {
            "grapheme": ".د.ك",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.ك",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "KYD": {
        "name": "Cayman Islands Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "CI$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "KZT": {
        "name": "Tenge",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₸",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₸",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "LAK": {
        "name": "Kip",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₭",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₭",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "LBP": {
        "name": "Lebanese Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": ".ل.ل",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "LKR": {
        "name": "Sri Lank Rupee",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₨",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "LRD": {
        "name": "Liberian Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "L$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "LSL": {
        "name": "Loti",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "LSL",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "LTL": {
        "name": "Lithuanian Litas",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Lt",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Lt",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "LVL": {
        "name": "Latvian Lats",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Ls",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Ls",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "LYD": {
        "name": "Libyan Dinar",
        "fractionSize": 3,
        "symbol": {
            "grapheme": ".د.ل",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.ل",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "MAD": {
        "name": "Moroccan Dirham",
        "fractionSize": 2,
        "symbol": {
            "grapheme": ".د.م",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.م",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "MDL": {
        "name": "Moldovan Leu",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "lei",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MGA": {
        "name": "Malagasy ariary",
        "fractionSize": 1,
        "symbol": {
            "grapheme": "Ar",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MKD": {
        "name": "Denar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "ден",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "ден",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "MMK": {
        "name": "Kyat",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "K",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MNT": {
        "name": "Tugrik",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₮",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₮",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "MOP": {
        "name": "Pataca",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "MOP$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MRO": {
        "name": "Ouguiya",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "ouguiya",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MUR": {
        "name": "Mauritius Rupee",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₨",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MVR": {
        "name": "Rufiyaa",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "MVR",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MWK": {
        "name": "Kwacha",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "MK",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MXN": {
        "name": "Mexican Peso",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MXV": {
        "name": "Mexican Unidad de Inversion (UDI)",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "UDI",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "MYR": {
        "name": "Malaysian Ringgit",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "RM",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "RM",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "MZN": {
        "name": "Mozambique Metical",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "MT",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "MT",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "NAD": {
        "name": "Namibi Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "N$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "NGN": {
        "name": "Naira",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₦",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₦",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "NIO": {
        "name": "Cordob Oro",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "C$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "C$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "NOK": {
        "name": "Norwegian Krone",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "kr",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "NPR": {
        "name": "Nepalese Rupee",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₨",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "NZD": {
        "name": "New Zealand Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "NZ$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "OMR": {
        "name": "Rial Omani",
        "fractionSize": 3,
        "symbol": {
            "grapheme": "﷼",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".ر.ع",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "PAB": {
        "name": "Balboa",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "B/.",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "B/.",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "PEN": {
        "name": "Nuevo Sol",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "S/",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "S/",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "PGK": {
        "name": "Kina",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "K",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "PHP": {
        "name": "Philippine Peso",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₱",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₱",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "PKR": {
        "name": "Pakistan Rupee",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₨",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "PLN": {
        "name": "Zloty",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "zł",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "zł",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "PYG": {
        "name": "Guarani",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "Gs",
            "rightSignPosition" : true, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Gs",
            "rightSignPosition" : true, "hasSpace" : false,
            "rtl": false
        }
    },
    "QAR": {
        "name": "Qatari Rial",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "﷼",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".ر.ق",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "RON": {
        "name": "New Romanian Leu",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "lei",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "lei",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "RSD": {
        "name": "Serbian Dinar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Дин.",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Дин.",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "RUB": {
        "name": "Russian Ruble",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₽",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₽",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "RUR": {
        "name": "Russian Ruble",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₽",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₽",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "RWF": {
        "name": "Rwand Franc",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "R₣",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SAR": {
        "name": "Saudi Riyal",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "﷼",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".ر.س",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "SBD": {
        "name": "Solomon Islands Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "SI$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "SCR": {
        "name": "Seychelles Rupee",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₨",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SDG": {
        "name": "Sudanese Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "SDG",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SEK": {
        "name": "Swedish Krona",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "kr",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SGD": {
        "name": "Singapore Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "S$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "SHP": {
        "name": "Saint Helen Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SLL": {
        "name": "Leone",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Le",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SOS": {
        "name": "Somali Shilling",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "S",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "S",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "SRD": {
        "name": "Surinam Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SSP": {
        "name": "South Sudanese Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "SS£",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "STD": {
        "name": "Dobra",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Db",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "SVC": {
        "name": "El Salvador Colon",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "C",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "SYP": {
        "name": "Syrian Pound",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "£",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": ".ل.س",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "SZL": {
        "name": "Lilangeni",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "L",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "THB": {
        "name": "Baht",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "฿",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "฿",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "TJS": {
        "name": "Somoni",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "SM",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "TMT": {
        "name": "Turkmenistan New Manat",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "T",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "TND": {
        "name": "Tunisian Dinar",
        "fractionSize": 3,
        "symbol": {
            "grapheme": ".د.ت",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".د.ت",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "TOP": {
        "name": "Pa’anga",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "T$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "TRL": {
        "name": "Turkish Lira",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₤",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "TRY": {
        "name": "Turkish Lira",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₺",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₺",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "TTD": {
        "name": "Trinidad and Tobago Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "TT$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "TT$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "TWD": {
        "name": "New Taiwan Dollar",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "NT$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "NT$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "TZS": {
        "name": "Tanzanian Shilling",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "TSh",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "TSh",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "UAH": {
        "name": "Hryvnia",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "₴",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₴",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "UGX": {
        "name": "Ugand Shilling",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "USh",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "USh",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "USD": {
        "name": "US Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "USN": {
        "name": "US Dollar (Next day)",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "UYI": {
        "name": "Uruguay Peso en Unidades Indexadas (URUIURUI)",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "$U",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "UYU": {
        "name": "Peso Uruguayo",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "$U",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "$U",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "UZS": {
        "name": "Uzbekistan Sum",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "so’m",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "so’m",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "VEF": {
        "name": "Bolivar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Bs",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Bs",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "VES": {
        "name": "Bolivar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Bs",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Bs",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "VND": {
        "name": "Dong",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "₫",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₫",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "VUV": {
        "name": "Vatu",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "VT",
            "rightSignPosition" : true, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "WST": {
        "name": "Tala",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "WS$",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "XAF": {
        "name": "CFA Franc BEAC",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "FCFA",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "FCFA",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "XCD": {
        "name": "East Caribbean Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "EC$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "XDR": {
        "name": "SDR (Special Drawing Right)",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "SDR",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "XOF": {
        "name": "CFA Franc BCEAO",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "CFA",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "CFA",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "XPF": {
        "name": "CFP Franc",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "₣",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "XSU": {
        "name": "Sucre",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "XSU",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "XUA": {
        "name": "ADB Unit of Account",
        "fractionSize": 0,
        "symbol": {
            "grapheme": "XUA",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "YER": {
        "name": "Yemeni Rial",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "﷼",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        },
        "uniqSymbol": {
            "grapheme": ".ر.ي",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": true
        }
    },
    "ZAR": {
        "name": "Rand",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "R",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "R",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "ZMW": {
        "name": "Zambian Kwacha",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "K",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "ZWD": {
        "name": "Zimbabwe Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Z$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Z$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        }
    },
    "ZWL": {
        "name": "Zimbabwe Dollar",
        "fractionSize": 2,
        "symbol": {
            "grapheme": "Z$",
            "rightSignPosition" : false, "hasSpace" : false,
            "rtl": false
        },
        "uniqSymbol": null
    },
    "BTC": {
        "name": "BTC",
        "fractionSize": 4,
        "symbol": {
            "grapheme": "₿",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "₿",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "ETH": {
        "name": "ETH",
        "fractionSize": 4,
        "symbol": {
            "grapheme": "Ξ",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Ξ",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    },
    "LTC": {
        "name": "LTC",
        "fractionSize": 4,
        "symbol": {
            "grapheme": "Ł",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        },
        "uniqSymbol": {
            "grapheme": "Ł",
            "rightSignPosition" : true, "hasSpace" : true,
            "rtl": false
        }
    }
}

export const renderCurrency = (value, currency, rate, otherCurrency)=> {
    let locale = window.navigator?.language || 'en-US';
    let v = Number(value);
    if(rate){
        v = v / rate;
    }
    if(Currencies[currency]){
        let result = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(v)
        if(otherCurrency && otherCurrency !== currency){
            result += ` (${renderCurrency(value, otherCurrency)})`;
        }
        return result;
    } else {
        return 'n/a';
    }

}

export const getCurrencySymbol = (currency) => {
    return currency ? Currencies[currency].symbol.grapheme  : "??";
}

export const getCurrencyFraction = (currency) => {
    return currency ? Currencies[currency].fractionSize  : 2;
}

export const usesCommasInsteadOfDecimals = (currency) => {
    return currency ? Currencies[currency].usesCommasInsteadOfDecimals  : false;
}