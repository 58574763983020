import React from 'react';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import GroupsIcon from '@mui/icons-material/Groups';
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {Alert, FormControlLabel, Grid, InputAdornment, TextField} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {compareMonetaryValues, CurrencySymbol} from "../Trip/Utils";
import {getCurrencyFraction, renderCurrency} from "../Trip/Currencies";
import Typography from "@mui/material/Typography";

function PayersGetter({setPayers, participants, participantNames, payers, currency, amount, setPayerAmounts, payerAmounts ,showLabel, noMultiplePayers}) {


    const cp = {};
    const initialAmountsMap = {};
    participants.forEach(p => cp[p] = false);
    payers.forEach((p, i) => { cp[p] = true; initialAmountsMap[p] = payerAmounts[i] });
    const [checkedParticipants, setCheckedParticipants] = React.useState(cp);
    const [payerAmountsMap, setPayerAmountsMap] = React.useState(initialAmountsMap);
    const [multipleSelection, setMultipleSelection] = React.useState(payers.length > 1);
    const handleSetPayer = (event) => {
        if (event.target.value === '~~~p~multiple') {
            setMultipleSelection(true);
            setCheckedParticipants({});
            setPayerAmountsMap({});
            setPayers([]);
            setPayerAmounts([]);
        } else {
            setPayers([event.target.value]);
            setPayerAmounts([amount]);
            setMultipleSelection(false);
        }
    };
    const getActiveParticipants = (cp) => {
        let activeParticipants = [];
        for (let i = 0, l = participants.length; i < l; i++) {
            let name = participants[i];
            if (cp[name]) {
                activeParticipants.push(name);
            }
        }
        return activeParticipants;
    }

    const payersTotal = payerAmounts.reduce((a, b) => a + b, 0);

    const updatePayerAmounts = (participant , value , latestCheckedParticipants)=> {
        let clone = Object.assign({}, payerAmountsMap);
        if((participant || participant === 0)){
            clone[participant] = value;
        }

        setPayerAmountsMap(clone);
        let newPayerAmounts = [];
        for(let i = 0, l = participants.length; i < l; i++){
            if(latestCheckedParticipants[participants[i]]){
                newPayerAmounts.push(clone[participants[i]] || 0);
            }
        }
        setPayerAmounts(newPayerAmounts);
    }



    return (<FormControl margin="normal" variant="standard" fullWidth={true}>
            <TextField
                fullWidth={true}
                autoFocus={true}
                label={showLabel && "Payer(s)"}
                select
                value={multipleSelection ? '~~~p~multiple' : payers[0]}
                variant={"outlined"}
                renderValue={(v) => {
                    return v === '~~~p~multiple' ? 'Multiple Payers' : participantNames[v]
                }}
                onChange={handleSetPayer}
            >
                {
                    participants && participants.map((name) => (
                        <MenuItem key={'payer-participant-' + name} value={name}>{participantNames[name]}</MenuItem>))
                }


                {!noMultiplePayers && <Divider/> }

                {!noMultiplePayers && <MenuItem key={'select-multiple-payers-menu-item'} value={'~~~p~multiple'}>
                        <ListItemText primary={<span><GroupsIcon color={'success'} style={{marginBottom: -7}}/><span style={{paddingLeft: 5}}>Multiple Payers</span></span>}/>
                    </MenuItem>

                }



            </TextField>
            {
                multipleSelection ?
                    <Grid container style={{paddingTop: 10}}>

                        { amount && <Grid item xs={12}>
                            {payers.length === 0 &&
                                <Typography style={{marginTop: 10}} variant={"body2"}>Please select at least one payer.</Typography>}
                            {payers.length > 0 && compareMonetaryValues(payersTotal, amount, currency) < 0  &&
                                <Typography style={{marginTop: 10}} variant={"body2"}>Total has to be exactly the expense amount <span style={{ fontWeight: "bold", color: "red" }}>{renderCurrency(payersTotal, currency)}</span>/{renderCurrency(amount, currency)}. </Typography>}
                            {payers.length > 0 && compareMonetaryValues(payersTotal, amount, currency) > 0 &&
                                <Typography style={{marginTop: 10}} variant={"body2"}><span style={{ fontWeight: "bold", color: "red" }}>{renderCurrency(amount - payersTotal, currency)}</span> left to distribute.</Typography>}
                        </Grid> }
                        {
                            !amount && <Grid item xs={12}><Typography style={{marginTop: 10}} variant={"body2"}>Invalid expense amount.</Typography></Grid>
                        }

                        {participants.map((participant, index) =>
                            <><Grid item xs={7}>
                                <FormControlLabel key={participant}
                                                  label={participantNames[participant]}
                                                  value={checkedParticipants[participant]}
                                                  control={<Checkbox key={'payer-getter' - participant}
                                                                     checked={checkedParticipants[participant]}
                                                                     onChange={(event, checked) => {
                                                                         let clone = Object.assign({}, checkedParticipants);
                                                                         clone[participant] = checked;
                                                                         setCheckedParticipants(clone);
                                                                         setPayers(getActiveParticipants(clone));
                                                                         updatePayerAmounts(null, null, clone);
                                                                     }}/>}
                                />
                            </Grid><Grid item xs={5}>

                                {checkedParticipants[participant] && <FormControl
                                    key={"payer_amounts_owes_" + participant} variant="standard" fullWidth={true}>

                                    <TextField
                                        style={{marginTop: 0}}
                                        margin={"dense"}
                                        size="small"
                                        type="number"
                                        value={payerAmountsMap[participant]}
                                        onChange={(e) => {

                                            updatePayerAmounts(participant, e.target.value ? Math.abs(Number(e.target.value)) : null, checkedParticipants);
                                        }}

                                        InputProps={{
                                            inputMode: "numeric",
                                            pattern: '[0-9]*',
                                            startAdornment: <InputAdornment position="start"><CurrencySymbol
                                                currency={currency}/></InputAdornment>,
                                        }}

                                    />
                                </FormControl>}

                            </Grid>
                            </>
                        )
                        }

                    </Grid>


                    : <></>
            }
        </FormControl>
    );
}

export default PayersGetter;