import * as React from "react"

const SvgComponent = ({darkMode}) =>{
    let color1 = darkMode ? '#fff' : '#B3B4B6';
    let color2 = darkMode ? '#fff' : '#327DD9';

    return (
    <svg
        width={43}
        height={42}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m4.416 32.187 16.534-9.546 16.534 9.546c-5.506 8.903-17.13 11.873-26.258 6.603a19.448 19.448 0 0 1-6.81-6.603ZM39.135 29.248 22.6 19.702V.609c10.463.317 18.848 8.899 18.848 19.44 0 3.213-.796 6.373-2.313 9.2ZM19.451.61v19.092L2.918 29.248c-4.957-9.22-1.718-20.772 7.41-26.042A19.448 19.448 0 0 1 19.451.609Z"
            stroke={color1}
            strokeWidth={1.2}
        />
        <path
            d="M27.95 17.251a.385.385 0 1 0 .77 0 2.586 2.586 0 0 1 2.584-2.583.385.385 0 1 0 0-.77 3.357 3.357 0 0 0-3.354 3.353Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M27.95 18.735a.385.385 0 1 0 .77 0v-1.484a.385.385 0 1 0-.77 0v1.484ZM29.434 11.809c0 1.55 1.309 2.859 2.86 2.859 1.55 0 2.858-1.309 2.858-2.86 0-1.55-1.309-2.858-2.859-2.858s-2.859 1.309-2.859 2.859Zm.77 0c0-1.132.957-2.089 2.09-2.089 1.13 0 2.088.957 2.088 2.089 0 1.132-.957 2.089-2.089 2.089-1.132 0-2.089-.957-2.089-2.09Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M35.866 17.251a.385.385 0 1 0 .77 0 3.357 3.357 0 0 0-3.353-3.353.385.385 0 1 0 0 .77 2.586 2.586 0 0 1 2.583 2.583Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M35.866 18.735a.385.385 0 1 0 .77 0v-1.484a.385.385 0 1 0-.77 0v1.484Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M28.154 19.075a8.792 8.792 0 0 0 8.279 0 .386.386 0 0 0-.362-.68h-.001a8.01 8.01 0 0 1-7.553 0 .385.385 0 0 0-.363.68Zm8.255-.044a8.74 8.74 0 0 1-8.232 0h8.232ZM5.95 17.251a.385.385 0 1 0 .77 0 2.586 2.586 0 0 1 2.584-2.583.385.385 0 1 0 0-.77A3.357 3.357 0 0 0 5.95 17.25Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M5.95 18.735a.385.385 0 1 0 .77 0v-1.484a.385.385 0 1 0-.77 0v1.484ZM7.434 11.809c0 1.55 1.309 2.859 2.86 2.859 1.55 0 2.858-1.309 2.858-2.86 0-1.55-1.309-2.858-2.859-2.858s-2.859 1.309-2.859 2.859Zm.77 0c0-1.132.957-2.089 2.09-2.089 1.13 0 2.088.957 2.088 2.089 0 1.132-.957 2.089-2.089 2.089-1.132 0-2.089-.957-2.089-2.09Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M13.866 17.251a.385.385 0 1 0 .77 0 3.357 3.357 0 0 0-3.353-3.353.385.385 0 1 0 0 .77 2.586 2.586 0 0 1 2.583 2.583Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M13.866 18.735a.385.385 0 1 0 .77 0v-1.484a.385.385 0 1 0-.77 0v1.484Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M6.154 19.075a8.792 8.792 0 0 0 8.279 0 .386.386 0 0 0-.363-.68 8.011 8.011 0 0 1-7.553 0 .385.385 0 0 0-.362.68Zm8.255-.044a8.74 8.74 0 0 1-8.232 0h8.232ZM16.096 35.161a.385.385 0 1 0 .77 0 2.586 2.586 0 0 1 2.584-2.583.385.385 0 1 0 0-.77 3.357 3.357 0 0 0-3.354 3.353Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M16.096 36.645a.385.385 0 1 0 .77 0v-1.484a.385.385 0 1 0-.77 0v1.484ZM17.58 29.719c0 1.55 1.309 2.859 2.86 2.859 1.55 0 2.858-1.31 2.858-2.86 0-1.55-1.309-2.858-2.859-2.858s-2.859 1.309-2.859 2.859Zm.77 0c0-1.132.957-2.09 2.09-2.09 1.13 0 2.088.958 2.088 2.09s-.957 2.089-2.089 2.089c-1.132 0-2.089-.957-2.089-2.09Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M24.012 35.161a.385.385 0 1 0 .77 0 3.357 3.357 0 0 0-3.353-3.353.385.385 0 1 0 0 .77 2.586 2.586 0 0 1 2.583 2.583Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M24.012 36.645a.385.385 0 1 0 .77 0v-1.484a.385.385 0 1 0-.77 0v1.484Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M16.3 36.985a8.792 8.792 0 0 0 8.279 0 .386.386 0 0 0-.363-.68 8.012 8.012 0 0 1-7.553 0 .385.385 0 0 0-.362.68Zm8.255-.044a8.742 8.742 0 0 1-8.232 0h8.232Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
    </svg>
) }

export default SvgComponent
