import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HashRouter, Route, Routes} from "react-router-dom";
import CreateNewGroupDialog from "./components/Dialogs/CreateNewGroupDialog";
import {amplifyConfigure, getEmailFromUser, isMobile, syncUserData} from "./components/Trip/Utils";
import LoginDialog from "./components/Dialogs/LoginDialog";
import {theme} from "./theme2";
import {darkTheme} from "./darkTheme";
import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import {Auth, Hub} from "aws-amplify";
import SwitchGroupsDialog from "./components/Dialogs/SwitchGroupsDialog";
import PastSplitsDialog from "./components/Dialogs/PastSplitsDialog";
import DonateDialog from "./components/Dialogs/DonateDialog";
import {GoogleOAuthProvider} from "@react-oauth/google";

amplifyConfigure();

const Wrapper = () => {
    const [darkMode, setDarkMode] = React.useState(localStorage.SPLITPAL_PREFERRED_THEME ?
        (localStorage.SPLITPAL_PREFERRED_THEME === "dark") :  (isMobile())  ) ;
    const [user, setUser] = useState(null);
    const [group, setGroup] = React.useState();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                console.log('setting current user', currentUser);
                setUser(currentUser)
            } )
            .catch(() => console.log("Not signed in"));
        return Hub.listen("auth", async ({payload: {event, data}}) => {
            if(event === "signIn"){
                console.log('trying to  sign in', event, data);
                try {
                    await syncUserData(getEmailFromUser(data));
                    setUser(data);
                }catch(e){
                    console.error(e);
                }
            }
        });
    }, []);

    return (
        <GoogleOAuthProvider clientId="984890731200-61qvgl3d30opu293bgaqa60ir1jahmj0.apps.googleusercontent.com">
        <ThemeProvider theme={darkMode ? darkTheme : theme}>
            <Box sx={{width: '100%'}}>
                <CssBaseline/>
                <HashRouter>
                <Routes>
                    <Route path={""} element={<App darkMode={darkMode} setDarkMode={setDarkMode} group={group} setGroup={setGroup} user={user} setUser={setUser}/>}/>
                    <Route path={"user"} element={<LoginDialog user={user} setUser={setUser}/>}/>
                    <Route path="new" element={<CreateNewGroupDialog user={user}/>}/>
                    <Route path="switch" element={<SwitchGroupsDialog setGroup={setGroup} user={user}/>}/>
                    <Route path="donate" element={<DonateDialog user={user}/>}/>

                    <Route path={":group/past"} element={<PastSplitsDialog/>}/>
                    <Route path={":group"} element={<App  darkMode={darkMode} setDarkMode={setDarkMode} group={group} setGroup={setGroup} user={user} setUser={setUser}/>}/>
                    <Route path={":group/:view/*"} element={<App  darkMode={darkMode} setDarkMode={setDarkMode} group={group} setGroup={setGroup} user={user} setUser={setUser}/>}/>
                </Routes>
                </HashRouter>
            </Box>
        </ThemeProvider>
        </GoogleOAuthProvider>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Wrapper/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
