import * as React from "react"

const CsvSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24.5 28"
        {...props}
    >
        <g
            style={{
                display: "inline",
            }}
        >
            <g
                style={{
                    opacity: 1,
                }}
            >
                <path
                    d="m56.296 227.278-.073-4.167-3.241-.376-3.242-.376v-27.864l3.242-.376 3.24-.377.07-10.12.07-10.12h31.086l6.414 6.418 6.414 6.418v45.107H56.368Z"
                    style={{
                        fill: "#fff",
                        strokeWidth: 0.26458332,
                    }}
                    transform="matrix(.47234 0 0 .47234 -23.082 -81.588)"
                />
                <path
                    style={{
                        fill: "#999898",
                        strokeWidth: 0.26458332,
                    }}
                    d="m48.642 179.373.019-5.58.708 1.367c.173-.67.446-2.383.265-2.31-.243.099-.199 3.582-.199 6.28v3.682h42.863v-21.96l.006-22.016-4.772.015s-.007-.036-.007-.346c0-.303-.017-.426-.017-.426h4.662c-4.035-3.97-8.13-8.146-12.135-12.147.346-.083.097-.1-.702-.005H49.436v9.636c0 7.46-.059 13.835.198 13.938.181.073.136-4.027-.265-4.015l-.67 1.688-.057-11.854v-10.187h31.622l12.828 12.836v45.637h-44.45Z"
                    transform="matrix(.47234 0 0 .47234 -19.566 -58.875)"
                />
                <path
                    style={{
                        fill: "#207245",
                        fillOpacity: 1,
                        strokeWidth: 0.26458332,
                    }}
                    d="M60.019 176.586c-9.381-1.225-17.979-2.27-17.979-2.27s-.013-3.699-.013-13.993c0-11.07.044-13.937.044-13.937s21.001-2.649 22.512-2.887l.992-.156v16.956c0 15.986-.008 16.943-.008 16.943s-3.002-.324-5.548-.656z"
                    transform="matrix(.47234 0 0 .47234 -19.566 -58.875)"
                />
            </g>
            <path
                style={{
                    opacity: 1,
                    fill: "none",
                    fillOpacity: 1,
                    stroke: "#999898",
                    strokeWidth: 0.36464465,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 4,
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                }}
                d="m21.78 275.527-3.646.018v-6.188"
                transform="translate(0 -269)"
            />
            <path
                d="M6.742 283.064c.401-.029.569.017.973-.002-.475.972-.645 1.744-1.128 2.713.489.996.988 1.985 1.48 2.98-.43-.024-.856-.051-1.285-.081-.302-.742-.67-1.458-.887-2.234-.242.722-.587 1.403-.864 2.111-.39-.005-.78-.021-1.169-.037.457-.895.898-1.796 1.37-2.685-.4-.916-.84-1.814-1.252-2.725.391-.023.782-.046 1.173-.067.265.695.555 1.382.774 2.095.235-.756.815-2.068.815-2.068z"
                style={{
                    opacity: 1,
                    fill: "#fff",
                    strokeWidth: 0.17781839,
                }}
                transform="translate(0 -269)"
            />
            <path
                style={{
                    opacity: 1,
                    fill: "#85be9f",
                    fillOpacity: 1,
                    strokeWidth: 0.37860319,
                }}
                d="M20.676 294.04h-1.11v-1.161h-.117c-.521.89-1.289 1.336-2.302 1.336-.745 0-1.328-.196-1.75-.588-.421-.392-.632-.913-.632-1.562 0-1.39.824-2.198 2.47-2.426l2.243-.312c0-1.264-.514-1.896-1.541-1.896-.902 0-1.715.305-2.44.915v-1.22c.735-.465 1.582-.697 2.542-.697 1.758 0 2.637.925 2.637 2.775zm-1.11-3.78-1.785.258c-.549.081-.963.223-1.243.428-.28.204-.419.566-.419 1.085 0 .379.129.688.387.927.257.24.6.36 1.03.36.587 0 1.073-.216 1.456-.647.383-.432.574-.977.574-1.638z"
                transform="translate(0 -269)"
            />
            <path
                style={{
                    opacity: 1,
                    fill: "#85be9f",
                    fillOpacity: 1,
                    strokeWidth: 0.26458332,
                }}
                d="m22.551 292.933-.86 2.646h-.63l.63-2.646z"
                transform="translate(0 -269)"
            />
        </g>
    </svg>
)

export default CsvSvg
