import React, {useEffect} from 'react';
import {
    Card,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, Grid,
    InputAdornment,
    TextField
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    BEGIN_ID_TIMESTAMP, CurrencySymbol,
    decorateFromSettingsString,
    formatDate, getUserProfileData,
    goBack, isMobile,
    logActivity,
    monetaryValue
} from "../Trip/Utils";
import Alert from "@mui/material/Alert";
import {renderCurrency} from "../Trip/Currencies";
import { serializeGroup} from "../Trip/Utils/expenseUtils";
import VenmoLogoSvg from "../SvgImages/VenmoLogoSvg";
import {useTheme} from "@mui/material/styles";
import CashAppSvg from "../SvgImages/CashAppSvg";

function MarkPaidDialog({trip, setGroup, user}) {


    const theme = useTheme();

    const darkMode = theme.custom.mode === 'dark';

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [onMarkPaidLoading, setOnMarkPaidLoading] = React.useState(false);
    const isVenmo = params.venmo === "venmo";
    const [description, setDescription] = React.useState(isVenmo ? "paid with Venmo" : "");
    const [loadingUserData, setLoadingUserData] = React.useState(false);

    
    const [country, setCountry] = React.useState('');
    const [simpleText, setSimpleText] = React.useState('');
    const [venmoUsername, setVenmoUsername] = React.useState('');
    const [cashAppUsername, setCashAppUsername] = React.useState('');
    const [useSimpleText, setUseSimpleText] = React.useState(false);
    const [useVenmo, setUseVenmo] = React.useState(false);
    const [useCashApp, setUseCashApp] = React.useState(false);
    let payer = Number(params.payer);
    let payee = Number(params.payee);


    let leftLp = trip.linkedParticipants[payer];
    let rightLp = trip.linkedParticipants[payee];


    let payeeUsername = null;
    if(trip.assignedUsers){
        for(let i = 0, l = rightLp.length; i <l;i++){
            if(trip.assignedUsers[rightLp[i]]){
                payeeUsername = trip.assignedUsers[rightLp[i]];
                break;
            }
        }
    }

    useEffect(() => {
        (async () => {
            if (payeeUsername) {
                setLoadingUserData(true);

                try {


                    let resp = await getUserProfileData(payeeUsername);
                    let data = JSON.parse(resp.data.getPreSignedUrl);

                    if (!data) {

                    } else {
                        setCountry(data.country);
                        setSimpleText(data.simpleText);
                        setVenmoUsername(data.venmoUsername);
                        setCashAppUsername(data.cashAppUsername);
                        setUseVenmo(data.useVenmo);
                        setUseCashApp(data.useCashApp);
                        setUseSimpleText(data.useSimpleText);
                    }

                } catch (e) {

                } finally {
                    setLoadingUserData(false);
                }
            }

        })();

    }, [payeeUsername]);


    const [amount, setAmount] = React.useState(monetaryValue(trip.ownage.totalOwnageMatrix[payer][payee], trip.currency));

    const payerString = leftLp.map(p=> trip.participants[p]).join(" & ");
    const payeeString = rightLp.map(p=> trip.participants[p]).join(" & ");

    let isValid = true;

    if(!trip.ownage.totalOwnageMatrix[payer][payee] ||  trip.ownage.totalOwnageMatrix[payer][payee] === 0 ){
        isValid = false;
    }
    let payerAmounts = [];
    for(let i = 0, l = leftLp.length; i < l; i++){
        payerAmounts.push(i === 0 ? amount : 0);
    }
    const closeDialog = ()=> {
        goBack(location, navigate, '/' + trip.id + '/results')
    }
    const markPaid = async () => {
        setOnMarkPaidLoading(true);
        try {
            let clone = JSON.parse(JSON.stringify(trip));
            clone.expenseEntries.push({
                type : 4,
                description,
                id: Date.now() - BEGIN_ID_TIMESTAMP,
                amount,
                participants : [...leftLp, ...rightLp],
                payers :leftLp,
                payerAmounts,
                currency: clone.currency,
                rate: 1,
                date: formatDate(Date.now())
            })
            let response = await API.graphql(graphqlOperation(updateTrip, {
                input : serializeGroup(clone)
            }));

            logActivity("add", {...trip,  user , data : { type: 4, payer: payerString, payee: payeeString, amount, description }});
            decorateFromSettingsString(response.data.updateTrip);
            setGroup(response.data.updateTrip);
            setOnMarkPaidLoading(false);
            closeDialog();

        } catch (e) {
            console.log(e);
            setOnMarkPaidLoading(false);
        }
    }
    const onKeyPress = async (e)=>{
        if(e.key === 'Enter'){
            await markPaid();
        }
    }
    
    let helperText = "";
    let diff =  monetaryValue(trip.ownage.totalOwnageMatrix[payer][payee], trip.currency) - monetaryValue(amount, trip.currency);
    let disableMarkPaid = false;
    if(diff < 0){
        helperText = <span style={{color: 'red'}}>Cannot mark paid more than what participant owes.</span>;
        disableMarkPaid = true;
    } else if( diff > 0) {
        helperText = <span>{payerString} would still owe <span style={{fontWeight: "bold"}}>{renderCurrency(diff, trip.currency)}</span>.</span>;
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Pay Options"}
            </DialogTitle>
            { isValid ? <DialogContent>

                {payeeUsername &&  <Grid container spacing={2} style={{paddingBottom: 16}} >


                    { loadingUserData &&  <Grid item xs={12} textAlign={"center"}>
                            <CircularProgress/>
                        </Grid> }

                    { useSimpleText &&  <Grid item xs={12}>
                        <Card style={{padding:10}}>
                            <div>{payeeString}'s instructions:</div>
                            <div style={{paddingTop: 10}}>{simpleText}</div>
                        </Card>
                    </Grid> }
                    { useVenmo &&  <Grid item xs={12} textAlign={"center"}>
                        <Card style={{padding:10}}>

                            <Button
                                onClick={() => {
                                    let url = `https://venmo.com/?txn=pay&note=${trip.name}&amount=${amount}&recipients=${venmoUsername}`;
                                    if(window.cordova){
                                        window.cordova.InAppBrowser.open(url,'_system');
                                    } else {
                                        window.open(url,'_system');
                                    }
                                }}
                                style={{ color: theme.palette.text.primary, fontWeight: 'bold', padding: 0}} startIcon={<VenmoLogoSvg/>}>Venmo</Button>
                        </Card>
                    </Grid> }
                    { useCashApp &&  <Grid item xs={12} textAlign={"center"}>
                        <Card style={{padding:10}}><Button style={{ padding: 0, color: theme.palette.text.primary, fontWeight: "bold"}} startIcon={<CashAppSvg/>} onClick={()=>{
                            let url = `https://cash.app/$${cashAppUsername}/${amount}`;
                            if(window.cordova){
                                window.cordova.InAppBrowser.open(url,'_system');
                            } else {
                                window.open(url,'_system');
                            }
                        } }>Cash App</Button>    </Card>
                    </Grid> }
                </Grid> }

                {!payeeUsername && trip.version >= 3 &&  <Grid container>
                    <Grid xs={12} style={{paddingBottom: 10}} item><Alert severity={"info"}>
                        <span style={{ fontWeight: "bold"}}>{payeeString}</span> is not assigned to a logged-in user, therefore pay back methods are not specified.
                    </Alert> </Grid>
                </Grid> }

                    <FormControl variant="standard" fullWidth={true}>

                        <TextField
                            margin={"dense"}
                            autoFocus={true}
                            label={"Amount"}
                            size="small"
                            type="number"
                            value={amount}
                            helperText={  helperText}
                            onFocus={event => {
                                event.target.select();
                            }}

                            onChange={(e) => {
                                setAmount((e.target.value || e.target.value === 0) ? Number(e.target.value) : null);

                            }}

                            InputProps={{
                                startAdornment: <InputAdornment position="start"><CurrencySymbol
                                    currency={trip.currency}/></InputAdornment>,
                            }}

                        />
                    </FormControl>

                <FormControl margin="normal" variant="standard" fullWidth={true}>
                    <TextField
                        fullWidth={true}
                        label="comment (optional)"
                        onKeyPress={onKeyPress}

                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                    />
                </FormControl>
            </DialogContent> :
                <DialogContent>
                    <Alert severity={"warning"}>Looks like someone else has updated the split (either removed expense or marked something as paid) and this entry is no longer valid.</Alert>

                </DialogContent>
            }
            <DialogActions>
                {!onMarkPaidLoading && <Button color={"info"} onClick={closeDialog}>Cancel</Button>}
                <LoadingButton disabled={!isValid || disableMarkPaid || !amount } loading={onMarkPaidLoading} color={"success"} onClick={ markPaid} autoFocus>
                    Record Payment
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default MarkPaidDialog;