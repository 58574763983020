import React from 'react';
import {Dialog, DialogActions, DialogContent, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {goBack, updateSettings} from "../Trip/Utils";
import LoadingButton from "@mui/lab/LoadingButton";
import SimplifyNormalSvg from "../SvgImages/SimplifyNormalSvg";
import SimplifyReducedSvg from "../SvgImages/SimplifyReducedSvg";
import {API, graphqlOperation} from "aws-amplify";
import {updateTrip} from "../../graphql/mutations";
import {serializeGroup} from "../Trip/Utils/expenseUtils";

function SimplifyDialog({trip, setGroup}) {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const closeDialog = () => {
        goBack(location, navigate, trip ? '/' + trip.id + '/results' : '/');
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} textAlign={"center"}>
                        <Typography variant={"overline"}>Normal</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} textAlign={"center"}><span style={{ fontWeight: "bold"}}>Person A</span> owes <span style={{ fontWeight: "bold"}}>$10</span> to <span style={{ fontWeight: "bold"}}>Person B</span></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} textAlign={"center"}><span style={{ fontWeight: "bold"}}>Person B</span> owes <span style={{ fontWeight: "bold"}}>$10</span> to <span style={{ fontWeight: "bold"}}>Person C</span></Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"} >
                        <SimplifyNormalSvg/>
                    </Grid>

                    <Grid item xs={12}  textAlign={"center"}>
                        <Typography variant={"overline"}>Minimized</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"} textAlign={"center"}><span style={{ fontWeight: "bold"}}>Person A</span> owes <span style={{ fontWeight: "bold"}}>$10</span> to <span style={{ fontWeight: "bold"}}>Person C</span></Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"} >
                        <SimplifyReducedSvg/>
                    </Grid>



                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color={"info"}> Cancel</Button>
                <LoadingButton loading={loading} onClick={async ()=> {
                    setLoading(true);
                    try {
                        trip.simplify = trip.simplify ? 0 : 1;

                        await API.graphql(graphqlOperation(updateTrip,{
                            input : serializeGroup(trip)
                        }));
                        setGroup(trip);
                        closeDialog();
                    }catch(e) {
                        console.error(e);
                        setError(e);
                    } finally {
                        setLoading(false);
                    }
                } }  color={"primary"}>{ trip.simplify ? 'Disable' : 'Enable'}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default SimplifyDialog;