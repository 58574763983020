import React from 'react';
import {Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {renderCurrency} from "./Trip/Currencies";
import Button from "@mui/material/Button";
import CsvSvg from "./SvgImages/CsvSvg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {getTotalExpenseEntryAmount, goForwardTo} from "./Trip/Utils";
import {getParticipantPortionMap} from "./Trip/Utils/expenseUtils";

function PastSplitPanel({ group }) {

    let totalMap = {};
    let portionMaps = {};
    for(let i = 0, l = group.expenseEntries.length; i < l; i++){
        let expenseEntry = group.expenseEntries[i];
        let map = getParticipantPortionMap(expenseEntry,group);
        let portionMap = {};
        for(let i = 0, l = group.linkedParticipants.length; i <l ; i++){
            portionMap[i] = 0;
            let lp = group.linkedParticipants[i];
            for(let j = 0, l = lp.length; j < l; j++){
                portionMap[i] -= (map[lp[j]] || 0);
                totalMap[i] = totalMap[i] || 0;
                totalMap[i] -= (map[lp[j]] || 0);
            }
        }
        for(let i = 0, l = expenseEntry.payers.length; i < l; i++){
            portionMap[group.linkedParticipantMap[expenseEntry.payers[i]]] += expenseEntry.payerAmounts[i];
            totalMap[group.linkedParticipantMap[expenseEntry.payers[i]]] = totalMap[group.linkedParticipantMap[expenseEntry.payers[i]]] || 0;
            totalMap[group.linkedParticipantMap[expenseEntry.payers[i]]] += expenseEntry.payerAmounts[i];

        }
        portionMaps[expenseEntry.id] = portionMap;
    }


    const getTotalSpent = (split) => {

        let total = 0;
        split.expenseEntries.forEach(e => {
            total += e.type === 4 ? 0 : getTotalExpenseEntryAmount(e) / e.rate;
        });
        return total;
    }

    const generateCsv = (split) => {
        let array = [];
        array.push(["Group Name", group.name]);
        if(split.splitName){
            array.push([]);
            array.push(['Event Name', group.splitName]);
            array.push(["Closed On ", (new Date(Number(split.closed))).toLocaleString('en-US')]);
        }
        array.push(["Total Spent", renderCurrency(getTotalSpent(split), group.currency)] );
        array.push([]);
        array.push(["Balance"]);
        array.push([]);
        array.push(["Date", "Expense", ...group.linkedParticipants.map(lp=>lp.map(p=>group.participants[p]).join(" & ") ) , "comment" ]);
        for (const expenseEntry of group.expenseEntries) {
            array.push([expenseEntry.date, expenseEntry.type === 4 ? "Payment" : expenseEntry.description,
                ...group.linkedParticipants.map( (lp, index) =>
                    renderCurrency(portionMaps[expenseEntry.id][index] || 0 , expenseEntry.currency, expenseEntry.rate, group.currency )) ,
                (expenseEntry.type === 4 && expenseEntry.description) || ""
            ])
        }
        array.push([]);
        array.push(["", "Final", ...group.linkedParticipants.map( (lp, index)=>renderCurrency(totalMap[index] || 0, group.currency)) ]);

        for(let i = 0, l = array.length; i < l; i++){
            for(let j= 0, l2 = array.length; j < l2; j++){
                if(array[i][j]){
                    array[i][j] = array[i][j].replaceAll('"', '');
                    array[i][j] = array[i][j].replaceAll(',', '');
                    array[i][j] = array[i][j].replaceAll('\n', '');
                    array[i][j] = array[i][j].replaceAll('\r', '');
                    array[i][j] = array[i][j].replaceAll('\t', '');
                    array[i][j] = array[i][j].replaceAll('#', '');
                }
            }
        }
        return array;
    }

    return (
            <Grid container>
                { group.splitName && <Grid item xs={12}>
                    <Divider><Typography variant={"overline"}>{group.splitName}</Typography></Divider>
                </Grid> }
                { group.closed && <Grid item xs={12}>
                    <Typography style={{ color: 'rgb(101, 116, 139)'}} variant={"body2"}>closed on {(new Date(Number(group.closed))).toLocaleString('en-US')}</Typography>
                </Grid> }

                <Grid item xs={12}>

                    <Grid item xs={12}
                          style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              background: "rgba(179, 180, 182, 0.12)",
                              paddingLeft: 10,
                              paddingRight: 10,
                              marginRight: 10,
                              marginLeft: 10,
                              marginBottom: 10,
                              width: "calc(100% - 20px)",
                              borderRadius: 12
                          }}
                    >
                        <Typography style={{ paddingLeft: 14, paddingTop: 10}} variant={"body2"}>Total Spent: {renderCurrency(getTotalSpent(group), group && group.currency)}</Typography>
                        <Button onClick={() => {
                            const rows = generateCsv(group);

                            if(window.cordova) {
                                function writeLog(str, logOb) {
                                    if (!logOb) return;
                                    logOb.createWriter(function(fileWriter) {
                                        fileWriter.seek(fileWriter.length);

                                        var blob = new Blob([str], {
                                            type: 'text/plain'
                                        });
                                        fileWriter.write(blob);
                                        fileWriter.onwriteend = function () {
                                            window.cordova.plugins.fileOpener2.open(logOb.nativeURL, 'text/csv', {
                                                error: function error(err) {
                                                    console.error(err);
                                                    alert("Unable to download");
                                                },
                                                success: function success() {
                                                    console.log("success with opening the file");
                                                }
                                            });
                                        };
                                    }, (error)=> { console.log('unable to write ', error)});
                                }

                                window.resolveLocalFileSystemURL(
                                    window.cordova.platformId === 'android' ?
                                    window.cordova.file.externalDataDirectory :
                                        window.cordova.file.documentsDirectory

                            , function(dir) {
                                    console.log("got main dir", dir);
                                    dir.getFile(`data-${Date.now()}.csv`, {
                                        create: true
                                    }, function(file) {
                                        console.log("got the file", file);
                                        writeLog(rows.map(e => e.join(",")).join("\n"), file);
                                    });
                                });



                            } else {
                                let csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF"
                                    + encodeURI(rows.map(e => e.join(",")).join("\n"));
                                let link = document.createElement("a");
                                link.setAttribute("href", csvContent);
                                link.setAttribute("download",  "data.csv");
                                document.body.appendChild(link); // Required for FF

                                link.click();
                            }



                        }
                        } startIcon={<CsvSvg/>}>CSV</Button>
                    </Grid>


                </Grid>
            </Grid>
    );
}

export default PastSplitPanel;