import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Input, InputLabel, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";


function SingleFieldUpdateDialog({open, noField, disableCondition, error, onCancel, onUpdate, updateButtonText, fullScreen, dialogTitle, inputLabel, dialogDescription, loading}) {
    const [value, setValue] = useState(null);

    const onKeyPress = (e)=>{
        if(e.key === 'Enter' && value){
            onUpdate(value);
            setValue(null);
        }
    }

    return (
        <Dialog

            fullScreen={fullScreen}
            maxWidth={'sm'}
            open={open}
            onClose={()=>{
                setValue(null);
                onCancel(null);
            } }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                { error ? <Alert severity={"error"}>{error}</Alert> : <Stack>
                    <Typography variant={"body2"}>{dialogDescription}</Typography>

                    {!noField && <FormControl key={"row_name_item"} margin={"normal"} variant="standard" >
                        <TextField
                            onKeyPress={onKeyPress}
                            autoFocus={true}
                            fullWidth={true}
                            variant={"outlined"}
                            label={inputLabel}
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value)
                            }}

                        />
                    </FormControl> }

                </Stack> }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} color="info" onClick={()=>{
                    setValue(null);
                    onCancel(null);
                } }> Cancel</Button>
                <LoadingButton loading={loading} disabled={(disableCondition && disableCondition(value)) } color={"success"} onClick={()=> {
                    let v = value;
                    onUpdate(v);
                    setValue(null);
                } }> {updateButtonText || "Update"}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default SingleFieldUpdateDialog;