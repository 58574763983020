import React from 'react';
import {Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import AppStoreSvg from "./SvgImages/AppStoreSvg";
import GooglePlayBadge from "./Results/GooglePlayBadge.png";
import {logActivity} from "./Trip/Utils";

function GetTheAppPanel({user}) {
    return (
            <>
                <Grid item xs={12} style={{paddingTop: 10}} textAlign={"center"}>
                    <Typography variant={"button"}> Download App for a better experience!</Typography>
                </Grid>
                <Grid textAlign={"center"} item xs={12}>
                    <AppStoreSvg onClick={() => {
                        window.open("https://apps.apple.com/us/app/splitpal/id6443640890", "_blank");
                        logActivity("DownloadIOSAppFromWebsite", null, user);
                    }
                    }/>
                </Grid>
                <Grid style={{ paddingTop: 0}} textAlign={"center"} item xs={12}>
                    <img onClick={() => {
                        window.open("https://play.google.com/store/apps/details?id=io.cordova.splitpal", "_blank");
                        logActivity("DownloadAndroidAppFromWebsite", null, user);
                    }} style={{width: 155}} src={GooglePlayBadge}/>
                </Grid>
            </>
    );
}

export default GetTheAppPanel;