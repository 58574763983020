import * as React from "react"

const SvgComponent = ({darkMode}) =>{
    let color1 = darkMode ? '#fff' : '#B3B4B6';
    let color2 = darkMode ? '#fff' : '#327DD9';
    return (
    <svg
        width={43}
        height={43}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"  
    >
        <mask id="unevenA" fill="#fff">
            <path d="M21.462 41.077a20.538 20.538 0 0 0 0-41.077v41.077Z" />
        </mask>
        <path
            d="M21.462 41.077a20.538 20.538 0 0 0 0-41.077v41.077Z"

            stroke={color1}
            strokeWidth={2.4}
            mask="url(#unevenA)"
        />
        <mask id="unevenB" fill="#fff">
            <path d="M19.855 0A19.856 19.856 0 0 0 0 19.855h19.855V0Z" />
        </mask>
        <path
            d="M19.855 0A19.856 19.856 0 0 0 0 19.855h19.855V0Z"

            stroke={color1}
            strokeWidth={2.4}
            mask="url(#unevenB)"
        />
        <path
            d="M7.338 14.085a.36.36 0 1 0 .72 0 2.384 2.384 0 0 1 2.381-2.382.36.36 0 1 0 0-.72c-1.71 0-3.1 1.392-3.1 3.102Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M7.338 15.455a.359.359 0 1 0 .72 0v-1.37a.36.36 0 1 0-.72 0v1.37ZM8.709 9.059c0 1.433 1.21 2.644 2.644 2.644 1.433 0 2.644-1.21 2.644-2.644 0-1.434-1.21-2.644-2.644-2.644-1.434 0-2.644 1.21-2.644 2.644Zm.718 0c0-1.044.883-1.926 1.926-1.926s1.925.882 1.925 1.926c0 1.043-.882 1.925-1.925 1.925-1.043 0-1.926-.882-1.926-1.925Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M14.649 14.085a.36.36 0 1 0 .718 0c0-1.71-1.39-3.101-3.1-3.101a.359.359 0 1 0 0 .719 2.384 2.384 0 0 1 2.382 2.382Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M14.649 15.455a.359.359 0 1 0 .718 0v-1.37a.36.36 0 1 0-.719 0v1.37Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M7.528 15.773a8.122 8.122 0 0 0 7.65 0 .36.36 0 0 0-.338-.635h-.001a7.395 7.395 0 0 1-6.972 0 .36.36 0 0 0-.338.635Zm7.626-.045a8.072 8.072 0 0 1-7.602 0h7.602Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <mask id="unevenC" fill="#fff">
            <path d="M0 21a20 20 0 0 0 20 20V21H0Z" />
        </mask>
        <path
            d="M0 21a20 20 0 0 0 20 20V21H0Z"

            stroke={color1}
            strokeWidth={2.4}
            mask="url(#unevenC)"
        />
        <path
            d="M7.338 31.21a.36.36 0 1 0 .72 0 2.385 2.385 0 0 1 2.381-2.382.359.359 0 1 0 0-.719c-1.71 0-3.1 1.391-3.1 3.101Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M7.338 32.58a.36.36 0 1 0 .72 0v-1.37a.36.36 0 1 0-.72 0v1.37ZM8.709 26.184c0 1.434 1.21 2.644 2.644 2.644 1.433 0 2.644-1.21 2.644-2.644 0-1.434-1.21-2.644-2.644-2.644-1.434 0-2.644 1.21-2.644 2.644Zm.718 0c0-1.043.883-1.925 1.926-1.925s1.925.882 1.925 1.925c0 1.043-.882 1.925-1.925 1.925-1.043 0-1.926-.882-1.926-1.925Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M14.649 31.21a.36.36 0 1 0 .718 0c0-1.71-1.39-3.1-3.1-3.1a.359.359 0 1 0 0 .718 2.385 2.385 0 0 1 2.382 2.382Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M14.649 32.58a.359.359 0 1 0 .718 0v-1.37a.36.36 0 1 0-.719 0v1.37Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M7.528 32.898a8.122 8.122 0 0 0 7.65 0 .36.36 0 0 0-.338-.635h-.001a7.395 7.395 0 0 1-6.972 0 .36.36 0 0 0-.338.635Zm7.626-.044a8.072 8.072 0 0 1-7.602 0h7.602ZM27.655 22.396a.36.36 0 1 0 .72 0 2.384 2.384 0 0 1 2.381-2.382.359.359 0 1 0 0-.718c-1.71 0-3.1 1.39-3.1 3.1Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M27.655 23.767a.36.36 0 1 0 .72 0v-1.371a.359.359 0 1 0-.72 0v1.37ZM29.026 17.37c0 1.434 1.21 2.644 2.644 2.644 1.433 0 2.644-1.21 2.644-2.644 0-1.434-1.21-2.644-2.644-2.644-1.434 0-2.644 1.21-2.644 2.644Zm.718 0c0-1.043.883-1.925 1.926-1.925s1.925.882 1.925 1.925c0 1.043-.882 1.925-1.925 1.925-1.043 0-1.926-.882-1.926-1.925Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M34.966 22.396a.36.36 0 1 0 .718 0c0-1.71-1.39-3.1-3.1-3.1a.36.36 0 1 0 0 .718 2.384 2.384 0 0 1 2.382 2.382Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M34.966 23.767a.359.359 0 1 0 .718 0v-1.371a.359.359 0 1 0-.718 0v1.37Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
        <path
            d="M27.845 24.084a8.122 8.122 0 0 0 7.65 0 .36.36 0 0 0-.338-.635h-.001a7.395 7.395 0 0 1-6.972 0 .36.36 0 0 0-.338.635Zm7.626-.044a8.072 8.072 0 0 1-7.602 0h7.602Z"
            fill={color2}
            stroke={color2}
            strokeWidth={0.5}
        />
    </svg>
) }

export default SvgComponent
