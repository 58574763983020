import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {goBack, logActivity} from "../Trip/Utils";
import BuyMeACoffee from "../SvgImages/BuyMeACoffee";

function LimitReached() {

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();





    let goBackUrl = '/';
    if (params.group) {
        goBackUrl += params.group + '/';
    }
    if (params.view) {
        goBackUrl += params.view;
    }

    const onClose = () => {
        goBack(location, navigate, goBackUrl);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Limit Reached</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item style={{ marginTop: 20}} xs={12}>
                        <Typography variant={"body2"} textAlign={"center"}>
                            You have reached the limit of expense entries possible in a given group.
                            Please resolve outstanding settlement (by marking everything "paid") and archiving the event.
                            Alternatively, you can create new group.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}> Okay</Button>
            </DialogActions>
        </Dialog>
    );
}

export default LimitReached;