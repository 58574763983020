import {bytesToBase64} from "../base64";
import {BinaryHeap} from "./BinaryHeap";
import {getCurrencyFraction} from "../Currencies";
import {BEGIN_ID_TIMESTAMP, getArrayFromBitmap, getBitmapFromArray, getSettingsString} from "../Utils";

class ArrayWriter {

    constructor(arrayBuffer) {
        this.index = 0;
        this.arrayBuffer = arrayBuffer;
        this.encoder = new TextEncoder();
        this.dataView = new DataView(arrayBuffer);

    }

    setUint8(value) {
        this.dataView.setUint8(this.index, value);
        this.index += 1;
    }

    setUint16(value) {
        this.dataView.setUint16(this.index, value);

        this.index += 2;
    }

    setUint32(value) {
        this.dataView.setUint32(this.index, value);
        this.index += 4;
    }

    setMonetaryValue(value) {
        // 10 bits for 3 digit decimal and 20 bits for whole number under 4194304
        // if decimal === 1001, means number is bigger than 4194304
        // this is unusual case, but that means number did not fit and extra 32 bit number is in the next memory slot.
        if (value > 1048576) {
            this.setUint32(2099249152) // 1001 << 21
            this.setFloat64(value);
        } else {
            this.setUint32((~~((value - ~~value) * 1000) << 21) | (~~value));
        }
    }

    setFloat64(value) {
        this.dataView.setFloat64(this.index, value);
        this.index += 8;
    }

    setString(value) {
        let uint8Array = this.encoder.encode(value);
        let len = Math.min(uint8Array.length, 64);
        this.setUint8(len);
        let view = new Uint8Array(this.arrayBuffer, this.index, len);
        for (let i = 0; i < len; i++) {
            view[i] = uint8Array[i];
        }
        this.index += len;
    }

    setCurrency(value) {
        this.setUint32((value.charCodeAt(0) << 16) | (value.charCodeAt(1) << 8) | value.charCodeAt(2));
    }

    setBitmapFromArray(array) {
        let bitmap = 0;
        for (let i = 0, l = array.length; i < l; i++) {
            bitmap |= 1 << array[i];
        }
        // 023:3 - participant bitmap
        this.setUint32(bitmap);
    }

    setDate(value) {
        let date = -1;
        if (value) {
            date =
                (value.charCodeAt(0) - 48 << 28) |
                (value.charCodeAt(1) - 48 << 24) |
                (value.charCodeAt(2) - 48 << 20) |
                (value.charCodeAt(3) - 48 << 16) |
                (value.charCodeAt(5) - 48 << 12) |
                (value.charCodeAt(6) - 48 << 8) |
                (value.charCodeAt(8) - 48 << 4) |
                (value.charCodeAt(9) - 48)
        }
        this.setUint32(date);
    }

    setStringArray(array) {
        this.setUint8(array.length);
        for (const str of array) {
            this.setString(str);
        }
    }

}

class ArrayReader {

    constructor(arrayBuffer) {
        this.index = 0;
        this.arrayBuffer = arrayBuffer;
        this.decoder = new TextDecoder('utf-8');
        this.dataView = new DataView(arrayBuffer);
    }

    getUint8() {
        this.index += 1;
        return this.dataView.getUint8(this.index - 1);
    }

    getUint16() {
        this.index += 2;
        return this.dataView.getUint16(this.index - 2);
    }

    getUint32() {
        this.index += 4;
        return this.dataView.getUint32(this.index - 4);
    }

    getMonetaryValue() {
        let value = this.getUint32();
        if ((value >> 21) < 1001) {
            return ((value << 11) >> 11) + (value >> 21) / 1000;
        } else {
            return this.getFloat64();
        }
    }

    getCurrency() {
        let currency = this.getUint32();
        return String.fromCharCode((currency >> 16) & 127, (currency >> 8) & 127, (currency) & 127)
    }

    getFloat64() {
        this.index += 8;
        return this.dataView.getFloat64(this.index - 8);
    }

    getString() {
        let strLen = this.getUint8();
        let view = new Uint8Array(this.arrayBuffer, this.index, strLen);
        this.index += strLen;
        let result = this.decoder.decode(view);
        return result;
    }

    getStringArray() {
        let arrayLen = this.getUint8();
        let array = [];
        for (let i = 0; i < arrayLen; i++) {
            array.push(this.getString());
        }
        return array;
    }

    getDate() {
        let date = this.getUint32();
        return (
            String.fromCharCode(((date >> 28) & 15) + 48, ((date >> 24) & 15) + 48, ((date >> 20) & 15) + 48, ((date >> 16) & 15) + 48)
            + '-' +
            String.fromCharCode(((date >> 12) & 15) + 48, ((date >> 8) & 15) + 48)
            + '-' +
            String.fromCharCode(((date >> 4) & 15) + 48, (date & 15) + 48));
    }

    getArrayFromBitmap() {
        let bitmap = this.getUint32();
        return getArrayFromBitmap(bitmap);
    }

}


const arrayBuffer = new ArrayBuffer(1024 * 100);
const writer = new ArrayWriter(arrayBuffer);
const reader = new ArrayReader(arrayBuffer);

function putExpenseInArray(expense) {
    writer.setString(expense.description);

    writer.setUint32(Number(expense.id));
    writer.setBitmapFromArray(expense.participants);
    writer.setBitmapFromArray(expense.payers);

    for (let i = 0, l = expense.payerAmounts.length; i < l; i++) {
        writer.setMonetaryValue(expense.payerAmounts[i]);
    }

    writer.setUint8(expense.type);
    writer.setDate(expense.date);
    writer.setCurrency(expense.currency);
    writer.setFloat64(expense.rate);

    if (expense.type === 2) {
        // 3:11-11+(num of participants) paid map
        let map = {};
        for (let i = 0, l = expense.items.length; i < l; i++) {
            map[expense.items[i].assignees[0].name] = expense.items[i];
        }
        for (let i = 0, l = expense.participants.length; i < l; i++) {
            writer.setMonetaryValue(map[expense.participants[i]]?.price || 0);
        }
    }
    if (expense.type === 3) {

        writer.setMonetaryValue(expense.tax);
        writer.setMonetaryValue(expense.discount);
        writer.setMonetaryValue(expense.tip);

        //2:11 - number of items
        writer.setUint8(expense.items.length);
        //2:12-[item]
        for (let i = 0, l = expense.items.length; i < l; i++) {
            writer.setString(expense.items[i].name);
            //item:0-2 id, quantity, price
            writer.setUint32(Number(expense.items[i].id));
            writer.setUint8(expense.items[i].quantity);
            writer.setMonetaryValue(expense.items[i].price);
            let assignees = expense.items[i].assignees || [];
            writer.setBitmapFromArray(assignees.map(a => a.name));

            //item:4-(num of assignees) quantity
            for (let i = 0, l = assignees.length; i < l; i++) {
                writer.setUint8(assignees[i].quantity);
            }
        }
    }
}

export const serializeGroup = (group) => {
    if (group.version > 1) {
        return {
            id: group.id,
            name: getBase64Expenses(group),
            participants: ['a'],
            settings: '!~sp|~!~sp|~!~sp|~!~sp|~2'
        }
    } else {
        let v1group = {
            currency: group.currency,
            id: group.id,
            name: group.name,
            expenseEntries: [],
            paidEntries: [],
            participants: group.participants,
            settings: getSettingsString(group)
        };
        for (let i = 0, l = group.expenseEntries.length; i < l; i++) {
            let expenseEntry = group.expenseEntries[i];
            let convertedExpenseEntry = {};
            convertedExpenseEntry.id = "" + (Number(expenseEntry.id) + BEGIN_ID_TIMESTAMP);
            if (expenseEntry.type === 4) {

                convertedExpenseEntry.payer = group.participants[expenseEntry.payers[0]];
                convertedExpenseEntry.comment = expenseEntry.description;
                let excludeSet = new Set(group.linkedParticipants[group.linkedParticipantMap[expenseEntry.payers[0]]]);
                for (let i = 0, l = expenseEntry.participants.length; i < l; i++) {
                    if (!excludeSet.has(expenseEntry.participants[i])) {
                        convertedExpenseEntry.payee = group.participants[expenseEntry.participants[i]];
                        break;
                    }
                }
                if (!convertedExpenseEntry.payee) {
                    convertedExpenseEntry.payee = convertedExpenseEntry.payer;
                }
                convertedExpenseEntry.amount = expenseEntry.payerAmounts[0];
                convertedExpenseEntry.description = "";
                v1group.paidEntries.push(convertedExpenseEntry);
            } else {
                convertedExpenseEntry.participants = expenseEntry.participants.map(p => group.participants[p]);
                convertedExpenseEntry.amount = expenseEntry.payerAmounts[0];
                convertedExpenseEntry.currency = expenseEntry.currency;
                convertedExpenseEntry.rate = expenseEntry.rate;
                convertedExpenseEntry.tax = expenseEntry.tax;
                convertedExpenseEntry.discount = expenseEntry.discount;
                convertedExpenseEntry.tip = expenseEntry.tip;
                convertedExpenseEntry.description = expenseEntry.description;

                convertedExpenseEntry.itemized = expenseEntry.type === 3;
                convertedExpenseEntry.payer = expenseEntry.payers.length > 0 ? group.participants[expenseEntry.payers[0]] : "";
                convertedExpenseEntry.date = expenseEntry.date;
                convertedExpenseEntry.items = [];
                if (expenseEntry.type !== 1) {
                    for (let i = 0, l = expenseEntry.items.length; i < l; i++) {
                        let item = expenseEntry.items[i];
                        convertedExpenseEntry.items.push({
                            id: item.id,
                            price: item.price,
                            name: item.name,
                            quantity: item.quantity,
                            assignees: (item.assignees || []).map(a => {
                                return {
                                    name: Number(a.name) === group.participants.length ? 'shared' : group.participants[a.name],
                                    quantity: a.quantity
                                }
                            })
                        })
                    }
                }

                v1group.expenseEntries.push(convertedExpenseEntry);
            }
        }
        return v1group;
    }

}

export const getBase64Expenses = (group) => {

    let participantMap = {};
    for (let i = 0, l = group.participants.length; i < l; i++) {
        participantMap[group.participants[i]] = i;
    }
    participantMap['shared'] = group.participants.length;

    writer.index = 0;

    writer.setString(group.name);
    writer.setUint32(group.lastId ? group.lastId + 1 : 1);
    writer.setCurrency(group.currency);
    writer.setStringArray(group.participants);
    writer.setUint8(group.pastSplits || 0);
    writer.setUint8(group.simplify || 0);
    writer.setUint8(group.version || 0);
    let lp = group.linkedParticipants.filter(l => l.length > 1);
    writer.setUint8(lp.length);
    for (let i = 0, l = lp.length; i < l; i++) {
        writer.setBitmapFromArray(lp[i]);
    }
    // 1. total number of entries
    writer.setUint16(group.expenseEntries.length);
    for (let i = 0, l = group.expenseEntries.length; i < l; i++) {
        putExpenseInArray(group.expenseEntries[i]);
    }

    let assignedUsers = group.assignedUsers || {};
    let totalAssignedUsers = 0;
    let participants = [];
    let usernames = [];

    for (let prop in assignedUsers) {
        participants.push(prop);
        usernames.push(assignedUsers[prop]);
        totalAssignedUsers++;
    }
    writer.setUint8(totalAssignedUsers);
    for (let i = 0; i < totalAssignedUsers; i++) {
        writer.setUint8(participants[i]);
        writer.setString(usernames[i]);
    }

    let uint8array = new Uint8Array(arrayBuffer, 0, writer.index);
    return bytesToBase64(uint8array)
}


export function explodeTypeOneWithItems(expenseEntry) {
    let price = (expenseEntry['payerAmounts'].reduce((a, b) => a + b, 0));
    expenseEntry.amount = price;
    for (let i = 0, l = expenseEntry.participants.length; i < l; i++) {
        expenseEntry['items'].push({
            price: price / expenseEntry.participants.length,
            name: 'portion',
            id: i,
            quantity: 1,
            assignees: [{
                name: expenseEntry.participants[i], // shared
                quantity: 1
            }]
        });
    }
}

export const deserializeGroup = (base64str, id) => {

    let binaryString = window.atob(base64str);
    let len = binaryString.length;

    let bytes = new Uint8Array(arrayBuffer);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    let result = {
        id,
        linkedParticipants: [],
        linkedParticipantMap: {},
        paidEntries: [],
        expenseEntries: []
    };


    reader.index = 0;

    result.name = reader.getString();
    result.lastId = reader.getUint32();
    result.currency = reader.getCurrency();
    result.participants = reader.getStringArray();
    result.pastSplits = reader.getUint8();
    result.simplify = reader.getUint8();
    result.version = reader.getUint8();

    let linkedParticipantLen = reader.getUint8();
    let used = new Set();
    let i = 0;
    let lpUsedMap = {};
    for (i; i < linkedParticipantLen; i++) {
        let array = reader.getArrayFromBitmap();
        array.forEach(p => used.add(p));
        array.forEach(p => result.linkedParticipantMap[p] = i);
        result.linkedParticipants.push(array);
    }

    for (let j = 0, l = result.participants.length; j < l; j++) {
        if (!used.has(j)) {
            let array = [j];
            result.linkedParticipantMap[j] = i++;
            result.linkedParticipants.push(array);
        }
    }

    let totalEntries = reader.getUint16();

    for (let i = 0; i < totalEntries; i++) {

        let expenseEntry = {items: []};
        result.expenseEntries.push(expenseEntry);

        expenseEntry['description'] = reader.getString();
        expenseEntry['id'] = reader.getUint32();
        expenseEntry['participants'] = reader.getArrayFromBitmap();
        expenseEntry['payers'] = reader.getArrayFromBitmap();
        expenseEntry['payerAmounts'] = [];
        for (let i = 0, l = expenseEntry['payers'].length; i < l; i++) {
            expenseEntry['payerAmounts'].push(reader.getMonetaryValue());
        }
        expenseEntry['type'] = reader.getUint8();
        expenseEntry['date'] = reader.getDate();
        expenseEntry['currency'] = reader.getCurrency();
        expenseEntry['rate'] = reader.getFloat64();
        if (expenseEntry.type === 4) {
            let price = expenseEntry['payerAmounts'][0];
            expenseEntry.amount = price;

            let set = new Set(expenseEntry.payers);
            let payees = expenseEntry.participants.filter(p => !set.has(p));
            if (payees.length > 1) {
                lpUsedMap[getBitmapFromArray(payees)] = true;
            }
            if (expenseEntry.payers.length > 1) {
                lpUsedMap[getBitmapFromArray(expenseEntry.payers)] = true;
            }
            for (let i = 0, l = payees.length; i < l; i++) {
                expenseEntry['items'].push({
                    price: i === 0 ? price : 0,
                    name: 'portion',
                    id: i,
                    quantity: 1,
                    assignees: [{
                        name: payees[i],
                        quantity: 1
                    }]
                });
            }
        }
        if (expenseEntry.type === 1) {
            explodeTypeOneWithItems(expenseEntry)

        }
        if (expenseEntry.type === 2) {
            // 3:11-11+(num of participants) paid map
            for (let i = 0, l = expenseEntry.participants.length; i < l; i++) {
                let price = reader.getMonetaryValue();

                expenseEntry['items'].push({
                    name: 'portion',
                    price,
                    id: i,
                    assignees: [{
                        name: expenseEntry.participants[i],
                        quantity: 1
                    }],
                    quantity: 1
                })
            }
            expenseEntry.amount = (expenseEntry['payerAmounts'].reduce((a, b) => a + b, 0));
        }
        if (expenseEntry.type === 3) {
            expenseEntry['tax'] = reader.getMonetaryValue();
            expenseEntry['discount'] = reader.getMonetaryValue();
            expenseEntry['tip'] = reader.getMonetaryValue();
            let amount = expenseEntry.tax - expenseEntry.discount + expenseEntry.tip;
            let numOfItems = reader.getUint8();
            for (let i = 0; i < numOfItems; i++) {
                let item = {
                    assignees: []
                };
                expenseEntry['items'].push(item);
                item.name = reader.getString();
                item.id = "" + reader.getUint32();
                item.quantity = reader.getUint8();
                item.price = reader.getMonetaryValue();
                amount += item.price;

                let assigneeBitmap = reader.getUint32();
                for (let i = 0; i < 32; i++) {
                    let v = assigneeBitmap & (1 << i);
                    if (v) {
                        item.assignees.push({
                            name: i,
                            quantity: reader.getUint8()
                        });
                    }
                }
            }
            expenseEntry.amount = Number(amount.toFixed(getCurrencyFraction(expenseEntry.currency)));

        }
    }
    result.assignedUsers = {};

    if (result.version >= 3) {
        let totalAssignedUsers = reader.getUint8();
        for (let i = 0; i < totalAssignedUsers; i++) {
            let participant = reader.getUint8();
            let username = reader.getString();
            result.assignedUsers[participant] = username;
        }
    }

    result.lpUsedMap = lpUsedMap;
    result.ownage = getOwnage(result);
    result.byteSize = reader.index;

    return result;
}


function makeMatrix(w, h) {
    let matrix = [];
    for (let i = 0; i < w; i++) {
        let row = [];
        for (let j = 0; j < h; j++) {
            row[j] = 0;
        }
        matrix[i] = row;
    }
    return matrix;

}

export function getParticipantPortionMap(expenseEntry, trip) {
    let expenseExtra = (expenseEntry.tax || 0) + (expenseEntry.tip || 0) - (expenseEntry.discount || 0);
    let itemsTotalCost = 0;
    for (const item of expenseEntry.items) {
        itemsTotalCost += item.price;
    }
    let participantPortionMap = {};
    for (const item of expenseEntry.items) {
        for (const assignee of item.assignees) {
            let amount = (assignee.quantity / item.quantity) * (item.price + (item.price / itemsTotalCost) * expenseExtra);
            if (assignee.name === trip.participants.length) {
                let l = expenseEntry.participants.length;
                for (let i = 0; i < l; i++) {
                    let p = expenseEntry.participants[i];
                    participantPortionMap[p] = participantPortionMap[p] || 0;
                    participantPortionMap[p] += amount / l;
                }
            } else {
                participantPortionMap[assignee.name] = participantPortionMap[assignee.name] || 0;
                participantPortionMap[assignee.name] += amount;
            }

        }
    }
    participantPortionMap[trip.participants.length] = participantPortionMap[trip.participants.length] || 0;
    return participantPortionMap;
}

export function getOwnage(group) {


    if (!group) {
        return {
            totalOwnageMatrix: [],
            entriesOwnageMap: {}
        }
    }

    const allParticipantLength = group.participants.length;
    const lpLen = group.linkedParticipants.length;
    let totalOwnageMatrix = makeMatrix(lpLen, lpLen);
    const entriesOwnageMap = {};

    for (let i = 0, l = group.expenseEntries.length; i < l; i++) {
        let expenseEntry = group.expenseEntries[i];
        let expenseParticipantLength = expenseEntry.participants.length;
        let expenseParticipantsMap = {};
        expenseEntry.participants.map(p => expenseParticipantsMap[p] = true);
        let expenseOwnageMatrix = makeMatrix(allParticipantLength, allParticipantLength);
        let expenseAmount = expenseEntry['payerAmounts'].reduce((a, b) => a + b, 0);
        let participantPortionMap = getParticipantPortionMap(expenseEntry, group);
        let payersMap = {};
        expenseEntry.payers.forEach((p, i) => payersMap[p] = expenseEntry.payerAmounts[i]);
        let totalPositiveCoefficient = 0;
        let coefficientMap = {};
        for (let j = 0; j < allParticipantLength; j++) {
            let coefficient = 0;
            if (expenseParticipantsMap[j]) {
                coefficient = ((payersMap[j] || 0) - ((participantPortionMap[j] || 0) + ((participantPortionMap[allParticipantLength] || 0) / expenseParticipantLength))) / expenseEntry.rate;
                if (coefficient > 0) {
                    totalPositiveCoefficient += coefficient;
                }
            }
            coefficientMap[j] = coefficient;
        }
        for (let j = 0; j < allParticipantLength; j++) {
            let coefficient = coefficientMap[j];
            for (let k = 0; k < allParticipantLength; k++) {

                expenseOwnageMatrix[j][k] = totalPositiveCoefficient ? (Math.max(coefficientMap[k], 0) / totalPositiveCoefficient) * (Math.abs(Math.min(coefficient, 0))) : 0;
                totalOwnageMatrix[group.linkedParticipantMap[j]][group.linkedParticipantMap[k]] +=
                    (group.linkedParticipantMap[j] === group.linkedParticipantMap[k]) ? 0 : expenseOwnageMatrix[j][k];
            }
        }

        entriesOwnageMap[expenseEntry.id] = {
            expenseEntry: expenseEntry,
            amount: expenseAmount,
            expenseOwnageMatrix
        };
    }
    let offset = 0;
    for (let i = 0; i < lpLen; i++) {
        for (let j = offset; j < lpLen; j++) {
            let v = Math.min(totalOwnageMatrix[i][j], totalOwnageMatrix[j][i]);
            totalOwnageMatrix[i][j] = Math.max(totalOwnageMatrix[i][j] - v, 0);
            totalOwnageMatrix[j][i] = Math.max(totalOwnageMatrix[j][i] - v, 0);
            if (totalOwnageMatrix[i][j] < 0.01) {
                totalOwnageMatrix[i][j] = 0;
            }
        }
        offset++;
    }

    if (group.simplify) {

        let data = {
            edges: []
        };

        for (let i = 0; i < lpLen; i++) {
            for (let j = 0; j < lpLen; j++) {
                if (i !== j && totalOwnageMatrix[i][j] > 0) {
                    data.edges.push({
                        from: i + 1,
                        to: j + 1,
                        label: totalOwnageMatrix[i][j]
                    })
                }
            }
        }

        try {

        } catch (e) {
            console.log('not able to simplify!');
        }

        let sz = lpLen;
        let vals = Array(sz).fill(0);
        for (let i = 0; i < data['edges'].length; i++) {
            let edge = data['edges'][i];
            vals[edge['to'] - 1] += edge['label'];
            vals[edge['from'] - 1] -= edge['label'];
        }

        const pos_heap = new BinaryHeap();
        const neg_heap = new BinaryHeap();

        for (let i = 0; i < sz; i++) {
            if (vals[i] > 0) {
                pos_heap.insert([vals[i], i]);
            } else if (vals[i] < 0) {
                neg_heap.insert(([-vals[i], i]));
                vals[i] *= -1;
            }
        }
        const newEdges = [];
        while (!pos_heap.empty() && !neg_heap.empty()) {
            const mx = pos_heap.extractMax();
            const mn = neg_heap.extractMax();

            const amt = Math.min(mx[0], mn[0]);
            const to = mx[1];
            const from = mn[1];

            newEdges.push({from: from + 1, to: to + 1, label: amt});

            vals[to] -= amt;
            vals[from] -= amt;

            if (mx[0] > mn[0]) {
                pos_heap.insert([vals[to], to]);
            } else if (mx[0] < mn[0]) {
                neg_heap.insert([vals[from], from]);
            }

        }
        totalOwnageMatrix = makeMatrix(lpLen, lpLen);
        newEdges.forEach(e => totalOwnageMatrix[e['from'] - 1][e['to'] - 1] = e.label);

    }

    let totalOwnage = 0;
    for (let i = 0; i < lpLen; i++) {
        for (let j = 0; j < lpLen; j++) {
            if (totalOwnageMatrix[i][j] < 0.01) {
                totalOwnageMatrix[i][j] = 0;
            }
            totalOwnage += totalOwnageMatrix[i][j];
        }
    }

    return {
        totalOwnageMatrix,
        entriesOwnageMap,
        totalOwnage
    }
}
