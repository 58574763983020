import React from 'react';
import {Button, Grid, Input} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Bolt} from "./Trip/CustomIcons";
import EvenlySvg from "./SvgImages/AddExpense/EvenlySvg";
import UnevenSvg from "./SvgImages/AddExpense/UnevenSvg";
import ItemizedExpenseSvg from "./SvgImages/ItemizedExpenseSvg";
import {useNavigate, useParams} from "react-router-dom";
import {_base64ToArrayBuffer, LIMIT_REACHED} from "./Trip/Utils";
import CardButton from "./Trip/CardButton";
import ConfirmationDialog from "./Dialogs/ConfirmationDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {useTheme} from "@mui/material/styles";

function AddExpensePanel({group, setReceiptImageData,addNewExpenseText, desktop}) {

    const theme = useTheme();

    const darkMode = theme.custom.mode === 'dark';
    const params = useParams();
    const [openCordovaCaptureDialog, setOpenCordovaCaptureDialog] = React.useState(false);
    const navigate = useNavigate();
    const handleCapture = (e, dataUrl)=> {
        setOpenCordovaCaptureDialog(false);
        setReceiptImageData({ e, dataUrl});
        navigate("/" + group.id + '/expenses/open');

    }

    const handleCordovaCapture = async(fromLibrary)=> {

        if(!window.interstitialLoaded && !localStorage.SPLITPAL_REMOVE_ADS){
            window.interstitial.load();
        }

        navigator.camera.getPicture((result)=>{
            handleCapture({target:  { files : [_base64ToArrayBuffer(result)] }  }, result);

        }, (e)=>{
            console.log(e);
        }, { quality: 50,
            sourceType : fromLibrary ? navigator.camera.PictureSourceType.PHOTOLIBRARY : navigator.camera.PictureSourceType.CAMERA,
            destinationType: navigator.camera.DestinationType.DATA_URL, correctOrientation: true  } )

    }

    return (
        <Grid container>
            <Grid item xs={12} style={{marginTop: 20}}>

                <Typography variant={"h6"} style={{fontSize: "16px", fontWeight: "500", lineHeight: "24px"}}
                            textAlign={"center"}>
                    { !addNewExpenseText && <Bolt style={{marginBottom: -7}}/>}
                    {
                        !addNewExpenseText ? "Split Expense" : "Add New Expense"
                    }
                    </Typography>
            </Grid>
            <Grid container spacing={2} style={{marginTop: 5 }}>
                { desktop && <Grid item xs={2}/> }
                <Grid item xs={4} textAlign={"center"}>
                    <CardButton icon={<EvenlySvg darkMode={darkMode}/>} label={"Even"}
                                onClick={(e) => {

                                    let view = params.view || "home";
                                    if(group.expenseEntries?.length > LIMIT_REACHED){
                                        navigate("/" + group.id + '/' + view + '/limit');
                                    } else {
                                        navigate("/" + group.id + '/' + view + '/quickeven');
                                    }

                                }}/>
                </Grid>
                <Grid item xs={4} textAlign={"center"}>
                    <CardButton icon={<UnevenSvg darkMode={darkMode}/>} label={"Uneven"}
                                onClick={(e) => {
                                    let view = params.view || "home";

                                    if(group.expenseEntries?.length > LIMIT_REACHED){
                                        navigate("/" + group.id + '/' + view + '/limit');
                                    } else {
                                        navigate("/" + group.id + '/' + view + '/quickuneven');
                                    }
                                }}/>
                </Grid>
                { desktop && <Grid item xs={2}/> }
                { desktop && <Grid item xs={4}/> }
                <Grid item xs={4} textAlign={"center"}>
                    {!!window.cordova ?
                        <CardButton icon={<ItemizedExpenseSvg darkMode={darkMode}/>} label={"Receipt"}
                                    onClick={()=> {
                                        let view = params.view || "home";

                                        if(group.expenseEntries?.length > LIMIT_REACHED){
                                            navigate("/" + group.id + '/' + view + '/limit');
                                        } else {
                                            setOpenCordovaCaptureDialog(true);
                                        }

                                    }}/> :

                        <label htmlFor="contained-button-file-from-home">
                            { group.expenseEntries?.length <= LIMIT_REACHED && <Input onChange={handleCapture} style={{display: 'none'}} accept="image/*"
                                   id="contained-button-file-from-home" type="file"/> }
                            <CardButton icon={<ItemizedExpenseSvg darkMode={darkMode}/>} label={"Receipt"} onClick={() => {
                                let view = params.view || "home";
                                if(group.expenseEntries?.length > LIMIT_REACHED){
                                    navigate("/" + group.id + '/' + view + '/limit');
                                }
                            }}/>
                        </label>


                    }
                </Grid>
                { desktop && <Grid item xs={4}/> }
            </Grid>
            <ConfirmationDialog title={"Add Receipt Expense"} open={openCordovaCaptureDialog} setOpen={setOpenCordovaCaptureDialog}
             confirmationText={"Cancel"}
                                content={
                                    <Grid textAlign={"center"} item xs={12}>
                                        <LoadingButton fullWidth={true} onClick={()=> { handleCordovaCapture() }}
                                                       color={"success"}
                                                       disabled={false} variant="outlined"
                                                       component="span"
                                                       startIcon={<PhotoCamera/>}>
                                            Capture Receipt
                                        </LoadingButton>
                                        <LoadingButton style={{ marginTop: 10}}  fullWidth={true} onClick={()=> { handleCordovaCapture(true) }}
                                                       color={"success"}
                                                       disabled={false} variant="outlined"
                                                       component="span"
                                                       startIcon={<PhotoLibraryIcon/>}>
                                            Camera Library
                                        </LoadingButton>

                                    </Grid>

                                }
            />
        </Grid>
    );
}

export default AddExpensePanel;